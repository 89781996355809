<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Orders</h1>
  </app-headline>

  <app-filter [columns]="['keywords', 'status', 'start_date', 'end_date']" resetControl="on">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Order Id, Email, Track Number</mat-label>
      <input matInput
             formControlName="keywords"
             placeholder="min 2 symbols">
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Start date</mat-label>
      <input matInput [matDatepicker]="startDate"
             formControlName="start_date"
             placeholder="DD.MM.YYYY">
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" filter-control>
      <mat-label>End date</mat-label>
      <input matInput [matDatepicker]="endDate"
             formControlName="end_date"
             placeholder="DD.MM.YYYY">
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" filter-control>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option></mat-option>
        <mat-option *ngFor="let status of statusKeys"
                    [value]="status">
          {{statusMapping[status]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="dataSource"
             matSort
             matSortActive="create_date"
             matSortDirection="asc"
             matSortDisableClear>
        <ng-container matColumnDef="id">
          <th mat-header-cell
              mat-sort-header
              *matHeaderCellDef>Order Id
          </th>
          <td mat-cell *matCellDef="let row">
            {{row.id}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
              *matHeaderCellDef>Status
          </th>
          <td mat-cell *matCellDef="let row">{{statusMapping[row.status]}}</td>
        </ng-container>

        <ng-container matColumnDef="create_date">
          <th mat-header-cell mat-sort-header
              *matHeaderCellDef>Created
          </th>
          <td mat-cell
              *matCellDef="let row">
            <zoned-time [time]="moment(row.create_date)"
                        [zone]="row.timezone"
                        preferred="guess"
                        format="lll"></zoned-time>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell
              *matHeaderCellDef>Email
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.user.email}}
          </td>
        </ng-container>

        <ng-container matColumnDef="tracking_number">
          <th mat-header-cell
              *matHeaderCellDef>Track Number
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.tracking_number ? row.tracking_number : '-'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
          <th mat-header-cell
              *matHeaderCellDef>Total Amount
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.total_amount}}</td>
        </ng-container>

        <ng-container matColumnDef="shipping_address">
          <th mat-header-cell
              *matHeaderCellDef>Shipping Address
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.shippingAddressString}}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell
              *matHeaderCellDef>
          </th>
          <td mat-cell
              *matCellDef="let row">
            <button mat-icon-button
                    [matMenuTriggerFor]="rowOptions"
                    [matMenuTriggerData]="{data: row}">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
    <mat-paginator></mat-paginator>

    <mat-menu #rowOptions="matMenu">
      <ng-template matMenuContent
                   let-order="data">
        <a mat-menu-item
           (click)="showOrderDetails(order)">
          <mat-icon>layers</mat-icon>
          Details
        </a>
        <ng-container *ngIf="profile$ | async as profile">
          <a mat-menu-item
             *ngIf="canApproveOrder(profile, order)"
             (click)="approveOrder(order)">
            <mat-icon>check</mat-icon>
            Approve
          </a>
          <a mat-menu-item
             *ngIf="canCancelOrder(profile, order)"
             (click)="cancelOrder(order)">
            <mat-icon>cancel</mat-icon>
            Cancel
          </a>
        </ng-container>
      </ng-template>
    </mat-menu>
  </ngrx-busy>
</mat-card>
