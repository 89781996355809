import {EqMap} from "./map";

export type EqualityComparer<T> = (first: T, second: T) => boolean;

export function groupBy<T, U>(array: T[], keySelector: (value: T) => U, comparer?: EqualityComparer<U>): Map<U, T[]> {
  return array.reduce(function (acc, item) {
    const key = keySelector(item);

    if (!acc.has(key)) acc.set(key, [item]);
    else acc.get(key).push(item);

    return acc;
  }, comparer ? new EqMap(comparer) : new Map());
}

export function distinctBy<T, U>(array: T[], keySelector: (value: T) => U, comparer?: EqualityComparer<U>): T[] {
  let grouped = groupBy(array, keySelector, comparer);

  let result = [];
  grouped.forEach((values, key, _) => result.push(values[0]))
  console.log('result', result);
  return result;
}
