import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {Filter, PagedResponse, Beacon, DevicesClient, GetBeacons, ObservedDataSource} from 'shared';
import {NgrxBusy} from 'ngrx-busy';

@Component({
  selector: 'app-beacons',
  templateUrl: './beacons.component.html',
  styleUrls: ['./beacons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeaconsComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  dataSource: FlashlightDataSource;
  columns = ['mac_address', 'serial', 'create_date'];

  constructor(private devicesClient: DevicesClient) {
    this.dataSource = new FlashlightDataSource(devicesClient);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
  }
}

class FlashlightDataSource extends ObservedDataSource<Beacon, GetBeacons> {
  constructor(private devicesClient: DevicesClient) {
    super(device => device.mac_address);
  }

  source(query: Partial<GetBeacons>): Observable<PagedResponse<Beacon>> {
    return this.devicesClient.getBeacons(new GetBeacons(query));
  }
}
