import {IStreamResult} from "@microsoft/signalr";
import {Observable} from "rxjs";

export function fromHubStream<T>(input: IStreamResult<T>): Observable<T> {
  return new Observable<T>(observer => {

    let subscription = input.subscribe({
      next: observer.next.bind(observer),
      error: observer.error.bind(observer),
      complete: observer.complete.bind(observer)
    });

    return {
      unsubscribe: subscription.dispose.bind(subscription)
    }
  })
}
