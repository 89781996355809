import {Directive, ElementRef, Input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {encode} from './filter';

declare type FilterSize = 'short' | 'medium' | 'long';

@Directive({
  selector: '[filter-control]',
  host: {class: 'averia-filter-control'}
})
export class FilterControlDirective {
  @Input()
  get size() {
    return this._size;
  }

  set size(value: FilterSize) {
    if (value !== this._size) {
      if (this._size && this._size !== 'short') {
        this.elementRef.nativeElement.classList.remove(`averia-filter-control-${this._size}`);
      }
      if (value !== 'short') {
        this.elementRef.nativeElement.classList.add(`averia-filter-control-${value}`);
      }

      this._size = value;
    }
  }

  private _size: FilterSize = 'short';

  constructor(private elementRef: ElementRef) {
  }
}

@Directive({
  selector: '[routerLink][filter]'
})
export class FilterRouterDirective {

  private params: object | undefined;
  private postfix: string | undefined;

  @Input('filter') set filter(value: object) {
    this.params = value
    this.updateLink();
  }

  @Input('filterLink') set filterLink(value: string) {
    this.postfix = value;
    this.updateLink();
  }

  constructor(private link: RouterLink) {
  }

  private updateLink() {
    if (!!this.params) {
      this.link.fragment = (!!this.postfix ? `filter_${this.postfix}=` : 'filter=') + encode(this.params);
      // @ts-ignore
      // this.link.updateTargetUrlAndHref()
    }
  }
}
