<div class="navigation">
  <img class="logo"
       src="assets/icons/logo.svg">

  <ng-container *ngFor="let group of navigation$ | async; index as i">
    <hr *ngIf="i !== 0">
    <ng-container *ngFor="let child of group.children"
                  [ngSwitch]="!!child.children">
      <a mat-icon-button
         [matTooltip]="child.title"
         matTooltipPosition="after"
         [routerLink]="child.url">
        <mat-icon [svgIcon]="child.svgIcon">{{child.icon}}</mat-icon>
      </a>
    </ng-container>
  </ng-container>

  <button *ngIf="profile$ | async as profile"
          mat-icon-button
          (click)="signOut()"
          [matTooltip]="'Sign out ' + profile.nickname"
          matTooltipPosition="after"
          class="sign-out">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</div>
