import {Injectable, TemplateRef} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';

import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';

export interface DialogOptions {
  confirm?: string | { title: string, color: any };
  cancel?: string | { title: string, color: any };

  [x: string]: any;
}

// tslint:disable-next-line:variable-name
export const DeleteConfirm: DialogOptions = {
  confirm: {title: 'Delete', color: 'warn'}
};

// tslint:disable-next-line:variable-name
export const WarningConfirm: DialogOptions = {
  confirm: {title: 'Confirm', color: 'accent'}
};

@Injectable()
export class SystemDialog {
  constructor(
    private readonly dialog: MatDialog
  ) {
  }

  async confirm(message: string | TemplateRef<any>, options?: DialogOptions): Promise<boolean> {
    const result = await this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        ...this.normalizeOptions(options)
      }
    }).beforeClosed().toPromise();
    return result || false;
  }

  async alert(message: string): Promise<void> {
    await this.dialog.open(AlertDialogComponent, {data: message}).beforeClosed().toPromise();
  }

  private normalizeOptions(options: DialogOptions | undefined): any {
    const defaultOptions = {
      confirm: {
        title: 'Confirm',
        color: 'info'
      },
      cancel: {
        title: 'Cancel',
        color: undefined
      }
    };

    if (!options) {
      return defaultOptions;
    }

    options.confirm = Object.assign(defaultOptions.confirm, typeof options.confirm === 'string' ? {title: options.confirm} : options.confirm);
    options.cancel = Object.assign(defaultOptions.cancel, typeof options.cancel === 'string' ? {title: options.cancel} : options.cancel);
    return options;
  }
}
