<ng-template #toolbar>
<!--  <mat-checkbox [(ngModel)]="showAllMarkers"-->
<!--                (ngModelChange)="showAllMarkersChanged()"-->
<!--                class="toolbar-option">-->
<!--    Show all markers-->
<!--  </mat-checkbox>-->

<!--  <mat-checkbox [formControl]="clustered"-->
<!--                class="toolbar-option">-->
<!--    Use cluster-->
<!--  </mat-checkbox>-->
</ng-template>

<ng-template #close>
  <a mat-flat-button
     class="mode-button close"
     [routerLink]="['../../']">
    <mat-icon>close</mat-icon>
  </a>
</ng-template>

<ngrx-busy class="info">
  <mat-card class="tabs-card">
    <mat-card-content class="outlined">
      <div class="battery">
        <ngx-charts-line-chart [results]="battery$ | async"
                               [xAxisTickFormatting]="dateTickFormatting"
                               [xAxis]="true"
                               [yAxis]="true">
          <ng-template #tooltipTemplate
                       let-model="model">
            <div class="battery-tooltip">
              {{model.series}}: {{model.value}}<br>
              {{model.timestamp | utc | moment}}
            </div>
          </ng-template>

          <ng-template #seriesTooltipTemplate
                       let-model="model">
            <ng-container *ngFor="let row of model">
              <ng-template ngFor let-row [ngForOf]="model"
                           [ngTemplateOutlet]="tooltipTemplate"
                           [ngTemplateOutletContext]="{model: row}"></ng-template>
            </ng-container>
          </ng-template>
        </ngx-charts-line-chart>
      </div>
    </mat-card-content>
  </mat-card>
</ngrx-busy>

