import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {fromEvent, Observable, switchMap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorBar} from './error-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private errorBar: ErrorBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status !== 401) {
        if (err.status === 500) {
          this.errorBar.open();
        } else if (err.status === 0) {
          this.errorBar.open('Server is not responding');
        } else if (err.error instanceof Blob) {
          const reader = new FileReader();
          reader.readAsText(err.error);
          return fromEvent(reader, 'loadend').pipe(
            switchMap(() => {
              const response = JSON.parse(reader.result as string);
              this.errorBar.open(response?.detail || response?.title);
              return throwError(response);
            }),
          );
        } else {
          this.errorBar.open();
        }
      }
      return throwError(err);
    }));
  }
}
