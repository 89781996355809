import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Walk} from 'shared';

@Component({
  selector: 'app-walk-card',
  templateUrl: './walk-card.component.html',
  styleUrls: ['./walk-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalkCardComponent {

  @Input() walk: Walk;
}
