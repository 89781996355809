<app-legend #legend
            style="display: none">
  <app-legend-item [key]="FirmwareStatus.Active"
                   color="green">Active
  </app-legend-item>
  <app-legend-item [key]="FirmwareStatus.Disabled"
                   color="red">Disabled
  </app-legend-item>
</app-legend>

<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Firmwares</h1>
    <app-headline-actions align="left">
      <button mat-icon-button
              (click)="openForm()">
        <mat-icon>add</mat-icon>
      </button>
    </app-headline-actions>
    <app-headline-actions>
      <a mat-flat-button
         color="primary"
         routerLink="types">
        Revisions
        <mat-icon>navigate_next</mat-icon>
      </a>
    </app-headline-actions>
  </app-headline>

  <app-filter [columns]="['keywords', 'status']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option></mat-option>
        <mat-option *ngFor="let status of Dic.firmware_statuses | keyvalue"
                    [value]="status.key">
          {{status.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="create_date"
        matSortDirection="desc"
        matSortDisableClear>
        <ng-container matColumnDef="revision">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Collar revision
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row">
            <button mat-icon-button
                    (click)="filter.apply({'keywords': row.revision})">
              <mat-icon>search</mat-icon>
            </button>
            <a mat-button
               color="primary"
               routerLink="/devices/types"
               [filter]="{keywords: row.revision}"
               filterLink="device-types">
              {{row.revision}}
            </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="version">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Version
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row">{{row.version}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell
            *matHeaderCellDef>Description
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row">
            <ngx-json-viewer [json]="row.description" expanded="true"></ngx-json-viewer>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="note">
          <mat-header-cell
            class="w-100"
            *matHeaderCellDef>Note
          </mat-header-cell>
          <mat-cell
            class="w-100"
            *matCellDef="let row"
            style="white-space: pre">{{row.note}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="reviews">
          <mat-header-cell
            *matHeaderCellDef>Reviews
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row">
            <p *ngFor="let reviewer of row.reviews"
               class="review"
               [class.reviewed]="reviewer.reviewed">
              {{reviewer.nickname}}
            </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="create_date">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Create date
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="min-width">{{row.create_date | utc | moment:'medium'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="update_date">
          <mat-header-cell
            *matHeaderCellDef>Update date
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="min-width">{{row.update_date | utc | moment:'medium'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
          <mat-header-cell
            *matHeaderCellDef>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row">
            <button mat-icon-button
                    [matMenuTriggerFor]="rowOptions"
                    [matMenuTriggerData]="{data: row}">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="['revision', 'version', 'note', 'description', 'reviews', 'create_date', 'update_date', 'options']"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: ['revision', 'version', 'note', 'description', 'reviews', 'create_date', 'update_date', 'options']"
          [legendRow]="legend"
          [legendRowValue]="row.status"></mat-row>
      </mat-table>
    </div>
    <mat-paginator></mat-paginator>
    <mat-menu #rowOptions="matMenu">
      <ng-template matMenuContent
                   let-firmware="data">
        <a mat-menu-item
           (click)="openForm(firmware)">
          <mat-icon>edit</mat-icon>
          Edit
        </a>
        <button mat-menu-item
                *ngIf="!activateAvailable(firmware)"
                color="warn"
                (click)="deactivate(firmware)">
          <mat-icon>block</mat-icon>
          Deactivate
        </button>
        <button mat-menu-item
                *ngIf="reviewAvailable(firmware)"
                (click)="review(firmware)">
          <mat-icon>check</mat-icon>
          Accept
        </button>
        <button mat-menu-item
                *ngIf="activateAvailable(firmware) && firmware.status.key !== 1"
                (click)="activate(firmware)">
          <mat-icon>done_all</mat-icon>
          Activate
        </button>
      </ng-template>
    </mat-menu>
  </ngrx-busy>
</mat-card>
