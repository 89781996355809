<form [formGroup]="form"
      (ngSubmit)="save()"
      class="side-form">

  <h3>{{mode === EditMode.Edit ? 'Update' : 'Create'}} role</h3>

  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput
           formControlName="name">
    <mat-error *ngIf="form.get('name').hasError('required')">
      Name is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Scopes</mat-label>
    <mat-select formControlName="scopes"
                multiple>
      <mat-option *ngFor="let scope of scopes"
                  [value]="scope">
        {{scope}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="side-form-actions">
    <button mat-raised-button
            type="submit"
            color="primary"
            [disabled]="form.invalid">
      {{mode === EditMode.Edit ? 'Update' : 'Create'}}
    </button>
  </div>

  <app-form-error></app-form-error>
</form>
