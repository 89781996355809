<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Reviews</h1>
  </app-headline>

  <app-filter [columns]="['keywords', 'status', 'start_date', 'end_date']" resetControl="on">
<!--    <mat-form-field appearance="outline"-->
<!--                    filter-control-->
<!--                    size="long">-->
<!--      <mat-label>Order Id, Email, Track Number</mat-label>-->
<!--      <input matInput-->
<!--             formControlName="keywords"-->
<!--             placeholder="min 2 symbols">-->
<!--    </mat-form-field>-->

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Start date</mat-label>
      <input matInput [matDatepicker]="startDate"
             formControlName="start_date"
             placeholder="DD.MM.YYYY">
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" filter-control>
      <mat-label>End date</mat-label>
      <input matInput [matDatepicker]="endDate"
             formControlName="end_date"
             placeholder="DD.MM.YYYY">
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" filter-control>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option></mat-option>
        <mat-option *ngFor="let status of statusKeys"
                    [value]="status">
          {{Status[status]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-filter>

  <mat-card-content class="outlined">
    <ngrx-busy>
      <div *ngFor="let review of reviews$ | async; let i = index">
        <mat-divider *ngIf="i != 0"></mat-divider>
        <div class="review">
          <div class="left-column">
            <img [src]="avatar(review.avatar) ?? '/assets/images/profile.jpg'"
                 class="avatar"
                 onerror="this.src='/assets/images/profile.jpg';"/>
            <div class="status">
              <button mat-button
                      [matMenuTriggerFor]="rowOptions"
                      [matMenuTriggerData]="{data: review}"
                      [disabled]="!(canChangeStatus$ | async)">
                {{Status[review.status]}}
              </button>
            </div>
          </div>
          <div style="flex-grow: 1; margin: 1rem; margin-left: 0;">
            <div class="review-head">
              <div>
                <div>{{review.user.email}} ({{review.user.name}} {{review.user.last_name}})</div>
                <div class="stars">
                  <mat-icon>{{review.star_count >= 1 ? 'star' : 'star_border'}}</mat-icon>
                  <mat-icon>{{review.star_count >= 2 ? 'star' : 'star_border'}}</mat-icon>
                  <mat-icon>{{review.star_count >= 3 ? 'star' : 'star_border'}}</mat-icon>
                  <mat-icon>{{review.star_count >= 4 ? 'star' : 'star_border'}}</mat-icon>
                  <mat-icon>{{review.star_count >= 5 ? 'star' : 'star_border'}}</mat-icon>
                  <zoned-time [time]="review.create_date"
                              [zone]="review.timezone"
                              preferred="guess"
                              format="lll"></zoned-time>
                </div>
              </div>
              <div>
                {{review.product.name}}
              </div>
            </div>
            <div style="margin-top: 0.3rem;">
              <span class="title">{{review.title}}</span>
              {{review.review_text}}
            </div>
          </div>
        </div>

      </div>

      <mat-paginator></mat-paginator>

      <mat-menu #rowOptions="matMenu">
        <ng-template matMenuContent
                     let-review="data">
          <button mat-menu-item
                  *ngIf="review.status !== 0"
                  (click)="changeStatus(review, 0)">
            Delete
          </button>
          <button mat-menu-item
                  *ngIf="review.status !== 1"
                  (click)="changeStatus(review, 1)">
            Move to draft
          </button>
          <button mat-menu-item
                  *ngIf="review.status !== 2"
                  (click)="changeStatus(review, 2)">
            Publish
          </button>
          <button mat-menu-item
                  *ngIf="review.status !== 3"
                  (click)="changeStatus(review, 3)">
            Move to spam
          </button>
        </ng-template>
      </mat-menu>
    </ngrx-busy>
  </mat-card-content>
</mat-card>
