import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {first, switchMap, takeUntil} from 'rxjs/operators';
import {
  ActivateFirmware,
  AuthManager,
  dic,
  Filter,
  FirmwareResponse,
  FirmwaresClient,
  FirmwareStatus,
  GetFirmwaresQuery,
  ProfileResponse,
  DeactivateFirmware,
  ReviewFirmwareCommand,
  SideNav,
  SystemDialog,
  TableDataSource
} from 'shared';
import {FirmwareFormComponent} from './firmware-form/firmware-form.component';
import {NgrxBusy, withBusy} from 'ngrx-busy';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirmwaresComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(Filter) filter: Filter;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();
  private refresh$ = new BehaviorSubject(true);
  private profile: ProfileResponse;

  dataSource = new TableDataSource<FirmwareResponse>();
  Dic = dic;

  FirmwareStatus = FirmwareStatus;

  constructor(private firmwaresClient: FirmwaresClient,
              private sidenav: SideNav,
              private authManager: AuthManager,
              private systemDialog: SystemDialog) {
    this.authManager.profile$.pipe(first()).toPromise().then(profile => this.profile = profile);
  }

  ngOnInit(): void {
    this.refresh$.pipe(
      switchMap(() => this.firmwaresClient.getFirmwares(new GetFirmwaresQuery())),
      withBusy(() => this.busy),
      takeUntil(this.unsubscribe$)
    ).subscribe(data => this.dataSource.data = data);
  }

  ngAfterViewInit(): void {
    this.dataSource.filter = this.filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const baseSorting = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'revision') {
        return data.revision.toLocaleLowerCase();
      }
      return baseSorting(data, sortHeaderId);
    };
    this.dataSource.filterPredicate = (data, key: any, filter: any) => {
      if (key === 'status') {
        return data.status == filter;
      }
      return (<string>filter).split(' ')
        .map(search => search.trim().toLocaleLowerCase())
        .every(search => data.id?.toLocaleLowerCase().indexOf(search) !== -1
          || data.id?.toLocaleLowerCase().indexOf(search) !== -1
          || data.revision?.toLocaleLowerCase().indexOf(search) !== -1
          || data.version?.toLocaleLowerCase().indexOf(search) !== -1
          || data.note?.toLocaleLowerCase().indexOf(search) !== -1);
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async openForm(firmware?: FirmwareResponse): Promise<void> {
    const result = await this.sidenav.open(FirmwareFormComponent, {data: firmware})
      .beforeClosed().toPromise();
    if (!result) return;
    this.refresh$.next(true);
  }

  async review(firmware: FirmwareResponse): Promise<void> {
    await this.firmwaresClient.reviewFirmware(new ReviewFirmwareCommand({id: firmware.id})).toPromise();
    this.refresh$.next(true);
  }

  async deactivate(firmware: FirmwareResponse): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to deactivate '${firmware.revision}' v. ${firmware.version} firmware?`, {
      confirm: {title: 'Deactivate', color: 'warn'}
    });
    if (!isOk) return;
    await this.firmwaresClient.deactivateFirmware(new DeactivateFirmware({revision: firmware.revision, version: firmware.version})).toPromise();
    this.refresh$.next(true);
  }

  reviewAvailable(firmware: FirmwareResponse): boolean {
    return !!firmware.reviews && firmware.reviews.some(review => review.id === this.profile.id && !review.reviewed);
  }

  activateAvailable(firmware: FirmwareResponse): boolean {
    return firmware.status !== FirmwareStatus.Active && (!firmware.reviews || firmware.reviews.every(review => review.reviewed));
  }

  async activate(firmware: FirmwareResponse): Promise<void> {
    await this.firmwaresClient.activateFirmware(new ActivateFirmware({revision: firmware.revision, version: firmware.version})).toPromise();
    this.refresh$.next(true);
  }
}
