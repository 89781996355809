import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Collar, DevicesClient, GetCollars} from "shared";
import {map, publishReplay, refCount, switchMap} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceDetailComponent {

  device$: Observable<Collar>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly devicesClient: DevicesClient,
  ) {
    this.device$ = this.route.params.pipe(
      switchMap(params => this.devicesClient.getCollars(new GetCollars({keywords: params['id']}))),
      map(paged => paged.items[0]),
      publishReplay(1),
      refCount()
    );
  }
}
