<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Beacons</h1>
  </app-headline>

  <app-filter [columns]="['keywords']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="dataSource"
             matSort
             matSortActive="create_date"
             matSortDirection="desc"
             matSortDisableClear>

        <ng-container matColumnDef="mac_address">
          <th mat-header-cell
              *matHeaderCellDef>Mac address
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{row.mac_address}}
          </td>
        </ng-container>

        <ng-container matColumnDef="serial">
          <th mat-header-cell
              *matHeaderCellDef>Serial number
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.serial}}</td>
        </ng-container>

        <ng-container matColumnDef="create_date">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Create date
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.created_at | utc | moment:'medium'}}</td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
    <mat-paginator></mat-paginator>
  </ngrx-busy>
</mat-card>
