import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {interval, Observable, Subject} from 'rxjs';
import {filter, first, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {NgrxBusy} from 'ngrx-busy';
import {SystemDialog} from 'shared';
import {PetDetailComponent} from '../pet-detail/pet-detail.component';
import {Rest, TimelinePublicClient} from '../public-clients';
import {LatLngTuple} from 'leaflet';

@Component({
  selector: 'app-trace-detail',
  templateUrl: './trace-detail.component.html',
  styleUrls: ['./trace-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraceDetailComponent implements OnInit, OnDestroy {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ViewChild('toolbar') toolbar: TemplateRef<any>;
  @ViewChild('close', {static: true}) close: TemplateRef<any>;

  Number = Number;

  trace$: Observable<Rest>;
  completed$: Observable<boolean>;
  battery$: Observable<any[]>;

  private readonly unsubscribe$ = new Subject();

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private timelineClient: TimelinePublicClient,
    private fb: UntypedFormBuilder,
    private systemDialog: SystemDialog,
    private parent: PetDetailComponent
  ) {
    const id$ = route.params.pipe(
      map(param => ({pet_id: route.parent.snapshot.params.id, id: param.trace_id}))
    );

    this.trace$ = id$.pipe(
      switchMap(({pet_id, id}) => this.timelineClient.getRest(pet_id, id)),
      shareReplay({refCount: true, bufferSize: 1})
    );

    interval(100).pipe(
      filter(() => !!this.parent.map),
      first(),
      switchMap(() => this.trace$),
      takeUntil(this.unsubscribe$)
    ).subscribe(trace => this.parent.map.fitBounds(trace.polygon as LatLngTuple[]));
  }

  ngOnInit(): void {
    this.parent.toolbar.push(this.close);
  }

  ngOnDestroy(): void {
    this.parent.toolbar.remove(this.close);
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
