import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {
  dic,
  GetRequests,
  IRequestPacket,
  RequestsClient,
  RequestSnapshot
} from 'shared';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestDetailComponent implements OnInit {
  Dic = dic;

  request$: Observable<RequestSnapshot>;
  requestHeaders$: Observable<object>;
  responseHeaders$: Observable<object>;
  parsedRequestPackets$: Observable<IRequestPacket[]>;
  parsedResponsePackets$: Observable<IRequestPacket[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RequestSnapshot,
    private readonly requestsClient: RequestsClient
  ) {
    this.request$ = this.requestsClient.getRequests(new GetRequests({device_id: data.device_id, id: data.id})).pipe(
      map(paged => paged.items[0]),
      publishReplay(1), refCount()
    );

    this.requestHeaders$ = this.request$.pipe(
      map(request => ({
        protocol: request.protocol,
        request_crc: request.request_crc,
        ...request.headers
      }))
    );

    this.responseHeaders$ = this.request$.pipe(
      map(request => ({
        elapsed: request.elapsed,
        response_crc: request.response_crc
      }))
    );
  }

  ngOnInit(): void {
    this.parsedRequestPackets$ = this.request$.pipe(map(request => request.request_packets));
    this.parsedResponsePackets$ = this.request$.pipe(map(request => request.response_packets));
  }
}
