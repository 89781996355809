<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Flashlights</h1>
  </app-headline>

  <app-filter [columns]="['keywords']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="dataSource"
             matSort
             matSortActive="create_date"
             matSortDirection="desc"
             matSortDisableClear>

        <ng-container matColumnDef="mac_address">
          <th mat-header-cell
              *matHeaderCellDef>Mac address
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{row.mac_address}}
          </td>
        </ng-container>

        <ng-container matColumnDef="serial">
          <th mat-header-cell
              *matHeaderCellDef>Serial number
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.serial}}</td>
        </ng-container>

        <ng-container matColumnDef="revision">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Revision
          </th>
          <td mat-cell
              *matCellDef="let row">
            <button mat-icon-button
                    (click)="filter.apply({'keywords': row.revision})">
              <mat-icon>search</mat-icon>
            </button>
            <a routerLink="types"
               mat-button
               color="primary"
               [filter]="{keywords: row.revision}"
               filterLink="device-types">
              {{row.revision}}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="firmware">
          <th mat-header-cell
              *matHeaderCellDef>Firmware
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.firmware}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
              *matHeaderCellDef>
          </th>
          <td mat-cell
              *matCellDef="let row">
            <a mat-icon-button
               [color]="row.linked_pet != null ? 'primary' : null"
               [routerLink]="row.linked_pet != null ? ['../customers', row.linked_pet.owner_id, 'pets', row.linked_pet.pet_id] : null">
              <mat-icon matTooltip="{{row.linked_pet != null ? 'Go to user/pet details' : 'There is no linked pet'}}">
                person
              </mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="create_date">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Create date
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.created_at | utc | moment:'medium'}}</td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
    <mat-paginator></mat-paginator>
  </ngrx-busy>
</mat-card>
