import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {HttpTransportType, HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {DeviceMode} from 'shared';
import {fromEventPattern, merge, timer} from 'rxjs';
import {throttleTime} from 'rxjs/operators';
import {deviceModeShade} from '../customers/utils';
import {API_BASE_URL_PUBLIC} from "../customers/public-clients";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnDestroy {

  // map: L.Map;
  // private hubConnection: HubConnection;

  constructor(@Inject(API_BASE_URL_PUBLIC) baseUrl: string) {
    // this.hubConnection = new HubConnectionBuilder()
    //   .withUrl(`${baseUrl}/hubs/map`, {
    //     transport: HttpTransportType.WebSockets,
    //     skipNegotiation: true
    //   })
    //   .withAutomaticReconnect()
    //   .build();
  }

  async ngOnInit(): Promise<void> {
    // await this.hubConnection.start();
    //
    // this.map = L.map('map', {
    //   zoom: 9,
    //   center: [51.505, -0.09]
    // }).locate({setView: true, maxZoom: 9});
    //
    // L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoieXVyeXNjaCIsImEiOiJjbDFlMnJscGowNnZrM2VydG95dDJucGkyIn0.gB-3c90Gp8o3scq_cWIj3g', {
    //   // maxZoom: 18,
    //   id: 'mapbox/streets-v11',
    //   tileSize: 512,
    //   zoomOffset: -1,
    //   accessToken: 'your.mapbox.access.token',
    //   maxNativeZoom: 19,
    //   maxZoom: 20
    // }).addTo(this.map);

    // L.geoJSON({
    //   type: 'FeatureCollection'
    // }).addTo(this.map);

    // this.map.addSource(vehicleSourceId, {
    //   type: 'geojson',
    //   data: {
    //     type: 'FeatureCollection',
    //     features: [],
    //   },
    // });

    // this.map.addLayer({
    //   id: vehicleLayerId,
    //   type: 'symbol',
    //   source: vehicleSourceId,
    //   minzoom: showMarkerLevel,
    //   layout: {
    //     'icon-image': ['get', 'icon'],
    //     'icon-size': ['interpolate', ['linear'], ['zoom'], 9, 0.05, 15, 0.4],
    //     'icon-allow-overlap': true,
    //     'icon-rotate': ['get', 'course'],
    //   },
    // });
    //
    // const petStates: PetStates = {};
  //   let layer: L.LayerGroup | null = null;
  //
  //   this.hubConnection.on('positions', (positions: PositionDto[]) => {
  //     const markers = positions.map((point, ix) => L.marker([point.location.lat, point.location.lng], {
  //       icon: L.divIcon({
  //         html: `<i class="mat-icon material-icons" style="; color: ${deviceModeShade(DeviceMode.Walk)}; font-size: 64px; height: 64px; width: 64px;">place</i>`,
  //         iconSize: [64, 64],
  //         className: 'place-icon'
  //       })}));
  //
  //     if (!!layer) { layer.remove(); }
  //     layer = L.layerGroup(markers).addTo(this.map);
  //   });
  //
  //   merge(
  //     timer(500),
  //     fromEventPattern(handler => this.map.on('zoomend', handler)),
  //     fromEventPattern(handler => this.map.on('move', handler))
  //   ).pipe(throttleTime(1000)).subscribe(() => {
  //     const bounds = this.map.getBounds();
  //     const sw = bounds.getSouthWest();
  //     const ne = bounds.getNorthEast();
  //     this.hubConnection.send('SetViewport', sw.lng, sw.lat, ne.lng, ne.lat);
  //   });
  }

  async ngOnDestroy(): Promise<void> {
    // await this.hubConnection.stop();
  }
}

interface PositionDto {
  pet_id: string;
  timestamp: string;
  location: {
    lng: number;
    lat: number;
  };
}

// interface PetState {
//   petId: string;
//   lng: number;
//   lat: number;
// }
//
// export type PetStates = { [petId: string]: PetState };
//
// function handlePositionsEvent(petStates: PetStates, positions: PositionDto[]) {
//   for (let position of positions) {
//     if (!petStates[position.pet_id]) {
//       petStates[position.pet_id] = createVehicleFromState(position);
//     }
//
//     updateVehicleFromEvent(petStates, position);
//   }
// }
//
// export const showMarkerLevel = 11;
//
// const vehicleSourceId = "vehicles";
// export const vehicleLayerId = "vehicle-layer";
//
// function createVehicleFromState(e: PositionDto): PetState {
//   return {
//     petId: e.pet_id,
//     lng: e.location.lng,
//     lat: e.location.lat
//   };
// }
//
// function updateVehicleFromEvent(
//   vehicleStates: PetStates,
//   positionDto: PositionDto
// ) {
//   const vehicleState = vehicleStates[positionDto.pet_id];
//   vehicleState.lng = positionDto.location.lng;
//   vehicleState.lat = positionDto.location.lat;
// }
//
// function updateVehicleLayers(map: L.Map, vehicleStates: PetStates) {
//   if (map.getZoom() < showMarkerLevel) {
//     return;
//   }
//
//   // expand viewport so we ingest things just outside the bounds also.
//   const biggerBounds = getBoundsWithMargin(map);
//
//   const data = mapVehiclesToGeoJson(vehicleStates, vehicle =>
//     biggerBounds.contains(vehicle)
//   );
//
//   trySetGeoJsonSource(map, vehicleSourceId, data);
// }
//
// export function getBoundsWithMargin(map: L.Map): L.LatLngBounds {
//
//   const bounds = map.getBounds();
//
//   // get bounds with 10% margin on each side (proportional to a viewbox)
//   const marginPercent = 0.1;
//
//   const sw = bounds.getSouthWest();
//   const ne = bounds.getNorthEast();
//
//   const lngMargin = Math.abs(sw.lng - ne.lng) * marginPercent;
//   const latMargin = Math.abs(sw.lat - ne.lat) * marginPercent;
//
//   return new L.LatLngBounds(
//     { lat: sw.lat - latMargin, lng: sw.lng - lngMargin },
//     { lat: ne.lat + latMargin, lng: ne.lng + lngMargin }
//   );
// }
//
// function mapVehiclesToGeoJson(
//   vehicleStates: PetStates,
//   predicate: (vehicleState: PetState) => boolean
// ): GeoJSONSourceData {
//   return {
//     type: 'FeatureCollection',
//     features: Object.values(vehicleStates)
//       .filter(predicate)
//       .map((vehicleState) => ({
//         type: 'Feature',
//         geometry: {
//           type: 'Point',
//           coordinates: [
//             vehicleState.lng,
//             vehicleState.lat,
//           ],
//         },
//         properties: {
//           petId: vehicleState.petId
//         },
//       })),
//   };
// }
//
// export type GeoJSONSourceData = GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry>;
//
// export const trySetGeoJsonSource = (map: L.Map, sourceId: string, data: GeoJSONSourceData) => {
//   const source = map.getSource(sourceId) as GeoJSONSource;
//
//   if (source) {
//     source.setData(data);
//   }
// }
