import {_isNumberValue} from '@angular/cdk/coercion';
import {DataSource} from '@angular/cdk/table';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of as observableOf,
  Subscription,
  Subject,
} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {Filter, FilterState} from './filter';

const MAX_SAFE_INTEGER = 9007199254740991;

export class TableDataSource<T> extends DataSource<T> {
  private readonly _data: BehaviorSubject<T[]>;
  private readonly _renderData = new BehaviorSubject<T[]>([]);
  private readonly _internalPageChanges = new Subject<void>();

  _renderChangesSubscription = Subscription.EMPTY;

  filteredData: T[];

  get data() {
    return this._data.value;
  }

  set data(data: T[]) {
    this._data.next(data);
  }

  get filter(): Filter {
    return this._filter;
  }

  set filter(filter: Filter) {
    this._filter = filter;
    this._updateChangeSubscription();
  }

  private _filter: Filter | null;

  get sort(): MatSort | null {
    return this._sort;
  }

  set sort(sort: MatSort | null) {
    this._sort = sort;
    this._updateChangeSubscription();
  }

  private _sort: MatSort | null;

  get paginator(): MatPaginator | null {
    return this._paginator;
  }

  set paginator(paginator: MatPaginator | null) {
    this._paginator = paginator;
    this._updateChangeSubscription();
  }

  private _paginator: MatPaginator | null;

  sortingDataAccessor: ((data: T, sortHeaderId: string) => string | number) =
    (data: T, sortHeaderId: string): string | number => {
      const value = (data as { [key: string]: any })[sortHeaderId];
      if (_isNumberValue(value)) {
        const numberValue = Number(value);
        return numberValue < MAX_SAFE_INTEGER ? numberValue : value;
      }
      if (typeof value === 'string') {
        return value.trim().toLocaleLowerCase();
      }
      return value;
    }

  filterPredicate: ((data: T, key: string, filter: any) => boolean) = (data: T, key: string, filter: any): boolean => {
    if (Object.keys(data).indexOf(key) === -1) {
      return true;
    }
    let value = data[key];
    if (typeof filter === 'string' && typeof value === 'string') {
      return value.trim().toLowerCase().indexOf(filter.trim().toLowerCase()) != -1;
    }
    return value == filter;
  }

  filterData: ((data: T[], filter: FilterState) => T[]) = (data: T[], filter: FilterState): T[] => {
    if (!this.filter) {
      return data;
    }
    ;
    const keys = Object.getOwnPropertyNames(filter);
    return data.filter(obj => keys.every(key => this.filterPredicate(obj, key, filter[key])));
  }

  sortData: ((data: T[], sort: MatSort) => T[]) = (data: T[], sort: MatSort): T[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let valueA = this.sortingDataAccessor(a, active);
      let valueB = this.sortingDataAccessor(b, active);
      const valueAType = typeof valueA;
      const valueBType = typeof valueB;
      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }
      }
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }
      return comparatorResult * (direction == 'asc' ? 1 : -1);
    });
  }

  constructor(initialData: T[] = []) {
    super();
    this._data = new BehaviorSubject<T[]>(initialData);
    this._updateChangeSubscription();
  }

  _updateChangeSubscription() {
    const sortChange: Observable<Sort | void> = this._sort ?
      merge(this._sort.sortChange, this._sort.initialized) as Observable<Sort | void> :
      observableOf(null);
    const filterChange = this._filter ? this._filter.filterChange : observableOf(null);
    const pageChange: Observable<PageEvent | void> = this._paginator ?
      merge(
        this._paginator.page,
        this._internalPageChanges,
        this._paginator.initialized
      ) as Observable<PageEvent | void> :
      observableOf(null);
    const dataStream = this._data;
    const filteredData = combineLatest([dataStream, filterChange])
      .pipe(map(([data]) => this._filterData(data)));
    const orderedData = combineLatest([filteredData, sortChange])
      .pipe(map(([data]) => this._orderData(data)));
    const paginatedData = combineLatest([orderedData, pageChange])
      .pipe(map(([data]) => this._pageData(data)));
    this._renderChangesSubscription.unsubscribe();
    this._renderChangesSubscription = paginatedData.subscribe(data => this._renderData.next(data));
  }

  _filterData(data: T[]) {
    if (!this.filter) {
      return data;
    }
    this.filteredData = this.filterData(data.slice(), this.filter.snapshot);
    if (this.paginator) {
      this._updatePaginator(this.filteredData.length);
    }
    return this.filteredData;
  }

  _orderData(data: T[]): T[] {
    if (!this.sort) {
      return data;
    }
    return this.sortData(data.slice(), this.sort);
  }

  _pageData(data: T[]): T[] {
    if (!this.paginator) {
      return data;
    }
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.slice(startIndex, startIndex + this.paginator.pageSize);
  }

  _updatePaginator(filteredDataLength: number) {
    Promise.resolve().then(() => {
      const paginator = this.paginator;
      if (!paginator) {
        return;
      }
      paginator.length = filteredDataLength;
      if (paginator.pageIndex > 0) {
        const lastPageIndex = Math.ceil(paginator.length / paginator.pageSize) - 1 || 0;
        const newPageIndex = Math.min(paginator.pageIndex, lastPageIndex);
        if (newPageIndex !== paginator.pageIndex) {
          paginator.pageIndex = newPageIndex;
          this._internalPageChanges.next();
        }
      }
    });
  }

  connect() {
    return this._renderData;
  }

  disconnect() {
  }
}
