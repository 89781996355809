import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private formGroupDirective: FormGroupDirective,
              private cdr: ChangeDetectorRef) {
  }

  get errors(): string[] | null {
    const form = this.formGroupDirective.form;
    if (!form || !form.invalid) return null;

    return (form.getError('detail') ? [form.getError('detail')] : null)
      || this.validation(form.getError('errors'))
      || (form.getError('status') == 403 ? ['You don\'t have permission to access this page'] : null)
      || (form.getError('title') ? [form.getError('title')] : null)
      || (form.hasError('status') ? ['An unexpected server error occurred'] : null);
  }

  validation(errors) {
    if (!errors) return null;

    return errors.map(e => e.value);
  }

  ngOnInit(): void {
    this.subscription = this.formGroupDirective.form.statusChanges
      .subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
