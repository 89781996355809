<form [formGroup]="form"
      (ngSubmit)="save()"
      class="side-form">

  <h3>{{mode === EditMode.Edit ? 'Update' : 'Create'}} breed</h3>

  <mat-form-field appearance="fill">
    <mat-label>Name [en]</mat-label>
    <textarea matInput
              formControlName="en"
              cdkTextareaAutosize
              required></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Name [ru]</mat-label>
    <textarea matInput
              formControlName="ru"
              cdkTextareaAutosize></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Height</mat-label>
    <input matInput
           formControlName="height"
           required
           [imask]="{mask: Number, min: 1, max: 150}">
  </mat-form-field>

  <div class="side-form-actions">
    <button mat-raised-button
            type="submit"
            color="primary"
            [disabled]="form.invalid">
      {{mode === EditMode.Edit ? 'Update' : 'Create'}}
    </button>
  </div>

  <app-form-error></app-form-error>
</form>
