<button *ngIf="mode === 'auto'"
        mat-icon-button
        matTooltip="Refresh every 5 seconds"
        matTooltipPosition="below"
        [color]="refreshSubscription ? 'primary' : null"
        (click)="switchAutoRefresh()">
  <mat-icon>autorenew</mat-icon>
</button>

<button *ngIf="mode === 'manual'"
        mat-icon-button
        (click)="refresh()">
  <mat-icon>refresh</mat-icon>
</button>
