<img [src]="(customer.avatar || '/assets/images/profile.jpg') + '?size=small&scale=2'"
     class="avatar"
     [matTooltip]="customer.status === UserStatus.Blocked ? 'Blocked' : undefined"
     onerror="this.src='/assets/images/profile.jpg';"/>

<div class="profile">
  <span class="name">{{customer.full_name}}</span>

  <div class="email">
    <button mat-icon-button
            class="verified"
            [disabled]="customer.email_confirmed"
            [matMenuTriggerFor]="emailOptions">
      <mat-icon [color]="customer.email_confirmed ? 'primary' : 'warn'"
                [matTooltip]="customer.email_confirmed ? 'Email is confirmed' : 'Email is not confirmed'"
                matTooltipPosition="below">
        {{customer.email_confirmed ? 'check' : 'close'}}
      </mat-icon>
    </button>
    {{customer.email}}
  </div>

  <span *ngIf="customer.total_walks != undefined"
        class="walks">{{customer.total_walks}} walks</span>
</div>

<mat-menu #emailOptions="matMenu">
  <button mat-menu-item
          (click)="confirmEmail()">
    <mat-icon>check</mat-icon>
    Confirm email
  </button>
</mat-menu>
