<img class="avatar"
     [src]="(pet.avatar || '/assets/images/pet.png') + '?size=small&scale=2'"
     onerror="this.src='/assets/images/pet.png';"/>
<div class="info">
  <mat-icon *ngIf="fullPet"
            class="sex"
            [matTooltip]="fullPet.sex === Sex.Male ? 'Male' : 'Female'"
            matTooltipPosition="below"
            [ngClass]="{'male': fullPet.sex === Sex.Male, 'female': fullPet.sex === Sex.Female}">
    {{fullPet.sex === Sex.Male ? 'male' : 'female'}}
  </mat-icon>
  <span class="alias">
    {{pet.alias}}
  </span>
  <span class="invited">Invited as {{pet.level | humanize:'share_levels'}}</span>
  <div *ngIf="fullPet" class="pet-info">
    <mat-icon class="device"
              [matTooltip]="fullPet.collar ? 'Device is linked' : 'Device is not linked'"
              matTooltipPosition="below">{{pet.collar ? 'link' : 'link_off'}}</mat-icon>

    <span class="level">{{fullPet.level | humanize:'share_levels'}}</span>
  </div>
</div>
