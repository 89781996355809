import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ObservableInput} from 'ngx-observable-input';
import {Observable} from 'rxjs';
import {Collar, Pet} from '../public-clients';
import {Subscription} from "../../../shared";
import * as moment from "moment/moment";

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityCardComponent {
  @ObservableInput() @Input('collar') collar$: Observable<Collar>;
  @ObservableInput() @Input('pet') pet$: Observable<Pet>;

  completion(distance: number, goal: number | null): number {
    if (!goal) return 0;
    return Math.max(1, distance * 100 / goal);
  }
}
