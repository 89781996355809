import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import * as moment from 'moment';
import {Session} from 'shared';

@Component({
  selector: 'app-session-card',
  templateUrl: './session-card.component.html',
  styleUrls: ['./session-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionCardComponent {
  @Input() session: Session;

  warn(): boolean {
    if (!this.session) return false;
    const diff = moment().unix() - this.session.updated_at.unix();
    return diff > 604800;
  }
}
