import {NgModule} from '@angular/core';

import {HeadlineComponent} from './headline.component';
import {HeadlineTitleDirective, HeadlineActionsDirective} from './headline-directives';

@NgModule({
  declarations: [
    HeadlineComponent,
    HeadlineTitleDirective,
    HeadlineActionsDirective
  ],
  exports: [
    HeadlineComponent,
    HeadlineTitleDirective,
    HeadlineActionsDirective
  ]
})
export class HeadlineModule {
}
