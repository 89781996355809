import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';

import {SideNav} from './sidenav';
import {SideNavComponent} from './sidenav.component';
import {SideNavService} from './sidenav.service';

@NgModule({
  imports: [
    MatSidenavModule
  ],
  declarations: [
    SideNavComponent
  ],
  exports: [
    MatSidenavModule,
    SideNavComponent
  ],
  providers: [
    SideNav
  ]
})
export class SideNavModule {
  static forRoot(): ModuleWithProviders<SideNavModule> {
    return {
      ngModule: SideNavModule,
      providers: [SideNavService]
    };
  }
}
