import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ErrorBar {
  constructor(private snackBar: MatSnackBar) {
  }

  open(error?: any): void {
    if (!error) {
      error = 'An unexpected server error occurred';
    }
    if (typeof error === 'object') {
      if (error.detail) {
        error = error.detail;
      } else if (error.status == 403) {
        error = 'You don\'t have permission to access this page';
      }
    }
    this.snackBar.open(error, null, {
      duration: 7000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'snack-bar-error'
    });
  }
}
