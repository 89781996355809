import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared';
import {RequestsComponent} from './requests.component';
import {RequestDetailComponent} from './request-detail/request-detail.component';
import {PacketsComponent} from './packets/packets.component';

@NgModule({
  declarations: [
    RequestsComponent,
    RequestDetailComponent,
    PacketsComponent
  ],
  exports: [
    RequestsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    // RouterModule.forChild([
    //     {
    //         path: '',
    //         component: RequestsComponent
    //     },
    //     {
    //         path: ':id',
    //         component: RequestDetailComponent
    //     },
    //     {
    //         path: 'device/:id',
    //         component: RequestDeviceLogComponent
    //     }
    // ])
  ]
})
export class RequestsModule {
}
