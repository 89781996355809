import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
// import humanizeString from 'humanize-string';
import {IDictionaryResponse} from './clients';
import {dic, humanize} from './server-dictionary';

@Pipe({name: 'humanize', pure: false})
export class HumanizePipe implements PipeTransform {
  private val: any = '';
  private originalValue: number;

  constructor(private ref: ChangeDetectorRef) {
  }

  transform(value: number, key?: keyof Omit<IDictionaryResponse, 'error_codes'> | object, defaultValue?: string): any {
    if (value === this.originalValue) {
      return this.val;
    } else {
      // DeviceUserMode
      if (key && (typeof key === 'string' || key instanceof String)) {
        this.val = defaultValue;
        this.val = humanize(value, key as any);
        this.ref.markForCheck();
      }
      else if (key) {
        this.val = defaultValue;
        this.val = key[value] == null ? this.val : key[value];
        this.ref.markForCheck();
      } else {
        this.val = value;
      }
    }

    this.originalValue = value;

    return this.val;
  }
}
