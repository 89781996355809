import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {GetRequests, RequestsClient} from 'shared';
import {PetDetailComponent} from '../pet-detail/pet-detail.component';
import {LatLngTuple} from 'leaflet';
import * as L from "leaflet";
import {hexToHsl, hslToHex} from "../utils";
import * as moment from 'moment-timezone'
import {distinctBy, groupBy} from 'extensions/array';

@Component({
  selector: 'app-flashlights',
  templateUrl: './flashlights.component.html',
  styleUrls: ['./flashlights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlashlightsComponent implements OnInit, OnDestroy {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ViewChild('toolbar') toolbar: TemplateRef<any>;
  @ViewChild('close', {static: true}) close: TemplateRef<any>;

  Number = Number;

  requests$: Observable<{ [key: string]: any; }[]>;
  battery$: Observable<any[]>
  // completed$: Observable<boolean>;
  // battery$: Observable<any[]>;

  private readonly unsubscribe$ = new Subject();

  constructor(
    route: ActivatedRoute,
    private requestsClient: RequestsClient,
    private parent: PetDetailComponent
  ) {
    const collarId$ = route.params.pipe(
      map(param => param.collar_id)
    );

    this.requests$ = collarId$.pipe(
      switchMap(collarId => this.requestsClient.getRequests(new GetRequests({
        device_id: collarId,
        packet_types: [80],
        distinct_packets: true,
        start_date_packet: moment().add(-2, 'M'),
        page: 1,
        page_size: 1000,
        sort_by: 'created_at',
        sort_order: 1,
        sort_packets_by: 'position',
        sort_packets_order:0
      }))),
      map(paged => paged.items
        .flatMap(r => r.request_packets)
        .map(r => r.parsed)
        .sort((a, b) =>  (typeof a.timestamp === 'number' ? a.timestamp : moment(a.timestamp).unix()) - (typeof b.timestamp === 'number' ? b.timestamp : moment(b.timestamp).unix()))),
      withBusy(() => this.parent.busy),
      shareReplay({refCount: true, bufferSize: 1})
    );

    this.battery$ = this.requests$.pipe(map(requests => {
      let result = []
      groupBy(requests, parsed => parsed.mac_address).forEach((value, key, _) => {
        // console.log(key, value);
        result.push({
          name: key + ' charge',
          color: 'green',
          series: distinctBy(value, x => x.charge).map(parsed => ({
            // lat: parsed.location.lat,
            // lng: parsed.location.lng,
            name: (typeof parsed.timestamp === 'number' ? moment.unix(parsed.timestamp) : moment(parsed.timestamp)).toDate(),
            value: parsed.charge
          })),
        })
      })
      return result;
    }));
  }

  ngOnInit(): void {
    this.parent.toolbar.push(this.close);
    this.parent.registerMode('filtered', 'Flashlight log', this.filtered.bind(this), true);
  }

  ngOnDestroy(): void {
    this.parent.toolbar.remove(this.close);
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  private filtered(onDeactivate: Observable<any>): void {
    let layer: L.LayerGroup | null = null;

    // const legend = this.createTrackColorsLegend();

    const clear = () => {
      if (layer) { layer.remove(); }
    };

    this.requests$.pipe(takeUntil(onDeactivate)).subscribe(requests => {
      clear();
      if (requests.length == 0) { return; }

      let polylines = [];
      let markers = [];

      groupBy(requests, parsed => parsed.mac_address).forEach((value, key, _) => {
        let hslColor = hexToHsl('#' + key.replace(/:/g, '').slice(0, 6));
        let color = hslToHex(hslColor.h, hslColor.s, 30);
        polylines.push(L.polyline(value.map(r => [r.location.lat, r.location.lng] as LatLngTuple), {
          color: color,
          weight: 1
        }));

        value.forEach(parsed => {
          markers.push(L.circleMarker([parsed.location.lat, parsed.location.lng], {
            radius: 6,
            fillColor: color,
            color: '#ffffff',
            opacity: 1,
            fillOpacity: 1,
            weight: 0.5
          })
            .bindTooltip((typeof parsed.timestamp === 'number' ? moment.unix(parsed.timestamp) : moment(parsed.timestamp)).utc().format('YYYY-MM-DD HH:mm:ss')));
        })
      })


      // let points = requests.map(r => [r.location.lat, r.location.lng] as LatLngTuple);

      // const polyline =

      // const markers = requests.map((parsed, ix) => L.circleMarker([parsed.location.lat, parsed.location.lng], {
      //   radius: 6,
      //   fillColor: 'red',
      //   color: '#ffffff',
      //   opacity: 1,
      //   fillOpacity: 1,
      //   weight: 0.5
      // })
      //   .bindTooltip(moment(parsed.timestamp).utc().format('YYYY-MM-DD HH:mm:ss')));
      //   // .bindPopup(createEmbeddedView(this.popup, {point: parsed}).rootNodes[0]));

      layer = L.layerGroup([...polylines, ...markers]).addTo(this.parent.map);

      this.parent.map.fitBounds(requests.map(r => [r.location.lat, r.location.lng] as LatLngTuple));
    }, () => {}, () => {
      clear();
      // legend.remove();
    });
  }

  dateTickFormatting(val: any): string {
    if (!(val instanceof Date)) { return; }
    return new Intl.DateTimeFormat('en-US', {
      year: "2-digit",
      month: "short",
      day: "numeric",
      // hour: '2-digit',
      // minute: '2-digit',
      // hour12: false,
      timeZone: 'utc'
    }).format(val);
  }
}

function hex2dec(hex) {
  return hex.replace('#', '').match(/.{2}/g).map(n => parseInt(n, 16));
}

function rgb2hex(r, g, b) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  r = Math.min(r, 255);
  g = Math.min(g, 255);
  b = Math.min(b, 255);
  return '#' + [r, g, b].map(c => c.toString(16).padStart(2, '0')).join('');
}

function rgb2cmyk(r, g, b) {
  let c = 1 - (r / 255);
  let m = 1 - (g / 255);
  let y = 1 - (b / 255);
  let k = Math.min(c, m, y);
  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);
  return [c, m, y, k];
}

function cmyk2rgb(c, m, y, k) {
  let r = c * (1 - k) + k;
  let g = m * (1 - k) + k;
  let b = y * (1 - k) + k;
  r = (1 - r) * 255 + .5;
  g = (1 - g) * 255 + .5;
  b = (1 - b) * 255 + .5;
  return [r, g, b];
}


function mix_cmyks(...cmyks) {
  let c = cmyks.map(cmyk => cmyk[0]).reduce((a, b) => a + b, 0) / cmyks.length;
  let m = cmyks.map(cmyk => cmyk[1]).reduce((a, b) => a + b, 0) / cmyks.length;
  let y = cmyks.map(cmyk => cmyk[2]).reduce((a, b) => a + b, 0) / cmyks.length;
  let k = cmyks.map(cmyk => cmyk[3]).reduce((a, b) => a + b, 0) / cmyks.length;
  return [c, m, y, k];
}

function mix_hexes(...hexes) {
  let rgbs = hexes.map(hex => hex2dec(hex));
  // @ts-ignore
  let cmyks = rgbs.map(rgb => rgb2cmyk(...rgb));
  let mixture_cmyk = mix_cmyks(...cmyks);
  // @ts-ignore
  let mixture_rgb = cmyk2rgb(...mixture_cmyk);
  // @ts-ignore
  let mixture_hex = rgb2hex(...mixture_rgb);
  return mixture_hex;
}
