import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {IRequestPacket, humanize, dic} from 'shared';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-packets',
  templateUrl: './packets.component.html',
  styleUrls: ['./packets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PacketsComponent {

  @Input()
  set data(packets: IRequestPacket[]) {
    if (packets) packets.forEach(packet => packet.parsed = Object.entries(packet.parsed).sort().reduce((o, [k, v]) => (o[k] = v, o), {}));
    this._data.next(packets || []);
  }

  @Input() slice = 0;

  @Input() excludeTimestamp = false;

  private readonly _data = new BehaviorSubject<IRequestPacket[]>([]);

  data$ = this._data.pipe(
    map(packets => packets.map(packet => this.humanizePacket(packet))),
    map(packets => {
      return <IPacketsModel>({
        columns: this.getColumns(packets),
        left_packets: this.slice > 0 ? packets.slice(this.slice) : [],
        packets: this.slice > 0 ? packets.slice(0, this.slice) : packets
      });
    })
  )

  private getColumns(packets: IRequestPacket[]) {
    const types = new Set(packets.map(p => p.packet_type));
    const mode = types.size === 1 ? types.values().next().value : undefined;
    const timestamp = this.excludeTimestamp ? [] : ['timestamp'];
    return mode === 7 // sensor
      ? [...timestamp, 'device_mode', 'previous_device_mode', 'bat_capacity', 'bat_voltage', 'humidity', 'rx_level', 'bat_charge', 'flashlight_status', 'ext_temp', 'sensor_activity']
      : mode === 2 // navigation
      ? [...timestamp, 'device_mode', 'previous_device_mode', 'latitude', 'longitude', 'height', 'pos_accu', 'speed', 'fix_type', 'snr', 'sv_in_view']
      : ['type', ...timestamp, 'parsed'];
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
  }

  parsedAccessor(packet: IRequestPacket, name: string) {
    return packet.parsed[name];
  }

  loadPackets(model: IPacketsModel, count: number) {
    model.packets = [...model.packets, ...model.left_packets.slice(0, count)];
    model.left_packets = model.left_packets.slice(count);
    this.cdr.markForCheck();
  }

  private humanizePacket(packet: IRequestPacket): IRequestPacket {
    // const toHex = (val: number): string => {
    //   return val !== undefined ? '0x' + Number(val).toString(16).padStart(2, '0').toUpperCase() : undefined;
    // }

    let mapped = {...packet};

    let result = ({
      ...mapped,
      parsed: <{ [key: string]: any; }>({
        ...mapped.parsed,
        device_mode: humanize(mapped.parsed.device_mode, 'device_modes'),
        previous_device_mode: humanize(mapped.parsed.previous_device_mode, 'device_modes'),
        mode: humanize(mapped.parsed.mode, 'device_modes'),
        prev_mode: humanize(mapped.parsed.prev_mode, 'device_modes'),
        activity: humanize(mapped.parsed.activity, 'activities'),
        type_activity: humanize(mapped.parsed.type_activity, 'activities'),
        module_id: humanize(mapped.parsed.module_id, 'error_modules'),
        error_code: !!dic.error_codes[mapped.parsed.module_id] && !!dic.error_codes[mapped.parsed.module_id][mapped.parsed.error_code]
          ? dic.error_codes[mapped.parsed.module_id][mapped.parsed.error_code]
          : mapped.parsed.error_code,
        parameters: mapped.parsed.parameters,
        type: mapped.packet_type == 0x24 ? humanize(mapped.parsed.type, 'transports') : mapped.parsed.type,
        region_type: mapped.packet_type == 0x0A ? humanize(mapped.parsed.region_type, 'safe_zone_types') : mapped.parsed.region_type,
        fix_type: humanize(mapped.parsed.fix_type, 'fix_types'),
      })
    });

    Object.keys(result.parsed).sort((a, b) => {
      if (packet.packet_type === 0x06) {
        if (a === 'module_id') return -100;
      }
      return a < b ? -1 : a == b ? 0 : 1
    }).forEach((k) => {
      const v = result.parsed[k]
      delete result.parsed[k]
      result.parsed[k] = v

      if (typeof result.parsed[k] === 'object') {
        Object.keys(result.parsed[k]).sort((aa, bb) => {
          // if (aa === 'alt' && (bb === 'lng' || bb == 'lat')) return 1;
          if (aa === 'lng' && bb === 'lat') return 1;
          if (aa === 'lat' || aa === 'lng') return -1;
          if (bb === 'lat' || bb === 'lng') return 1;

          return aa < bb ? -1 : aa == bb ? 0 : 1
        }).forEach((kk) => {
          const v = result.parsed[k][kk]
          delete result.parsed[k][kk]
          result.parsed[k][kk] = v
        });
      }

      if (Array.isArray(result.parsed[k])) {
        for (let i = 0; i < result.parsed[k].length; i++) {
          Object.keys(result.parsed[k][i]).sort().forEach((kk) => {
            const v = result.parsed[k][i][kk]
            delete result.parsed[k][i][kk]
            result.parsed[k][i][kk] = v
          });
        }
      }
    })

    return result;
  }
}

interface IPacketsModel {
  columns: string[];
  packets: IRequestPacket[];
  left_packets: IRequestPacket[];
}
