import { Component, OnInit } from '@angular/core';
import {Filter} from './filter';

@Component({
  selector: 'app-filter-reset',
  templateUrl: './reset.html',
  styleUrls: ['./reset.scss']
})
export class FilterResetComponent implements OnInit {

  constructor(public readonly filter: Filter) { }

  ngOnInit(): void {
  }
}
