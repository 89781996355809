import {
  AfterViewInit, ChangeDetectionStrategy,
  Component, ComponentRef, ElementRef,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Optional, Renderer2,
  Self, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS
} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, map, pairwise, startWith, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {serialize, deserialize} from 'bson';
import {Buffer} from 'buffer';
import {isMoment} from 'moment';
import * as moment from 'moment';
import {encode as encodeUrl, decode as decodeUrl, trim as trimBase64} from 'url-safe-base64';

export const formDirectiveProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => Filter)
};

export declare interface FilterState {
  [key: string]: any;
}

function normalize(obj: object): object {
  const newObj = {};
  Object.getOwnPropertyNames(obj).forEach(key => {
    const value = obj[key];
    // tslint:disable-next-line:no-null-keyword
    if (obj.hasOwnProperty(key) && value != null) {
      if (Array.isArray(value) && value.length === 0) {
        return;
      }
      if (typeof value === 'string' && value.trim().length === 0) {
        return;
      }
      if (isMoment(value)) {
        newObj[key] = value.clone();
      } else {
        newObj[key] = value;
      }
    }
  });
  return newObj;
}

export function encode(obj: object): string {
  const newObj = {};
  Object.getOwnPropertyNames(obj).forEach(key => {
    const value = obj[key];
    if (isMoment(value)) {
      newObj[key] = value.toDate();
    } else {
      newObj[key] = value;
    }
  });
  const base64 = serialize(newObj).toString('base64');
  const base64Url = encodeUrl(base64);
  return trimBase64(base64Url);
}

function decode(base64: string): object {
  const obj = deserialize(new Buffer(decodeUrl(base64), 'base64'));
  Object.getOwnPropertyNames(obj).forEach(key => {
    const value = obj[key];
    if (value instanceof Date) {
      obj[key] = moment(value).utc();
    }
  });
  return obj;
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.html',
  styleUrls: ['./filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [formDirectiveProvider],
  encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class Filter<TParams = FilterState> extends FormGroupDirective implements AfterViewInit, OnDestroy {

  private readonly unsubscribe$ = new Subject();
  private readonly params$: BehaviorSubject<Partial<TParams>>;
  private readonly predefinedSnapshot: Partial<TParams>;
  private readonly predefinedStringifiedSnapshot: string;
  // tslint:disable-next-line:variable-name
  private _snapshot: Partial<TParams>;

  applied = false;

  readonly resetOnChange = new Map<string, string>();
  readonly fragmentName: string;

  @Input()
  set columns(names: string[]) {
    names.forEach(name => {
      if (!this.form.contains(name)) {
        const control = new UntypedFormControl(this.params$.value[name]);
        control.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
          const params = {} as any;
          params[name] = value;
          this.use({...this.params$.value, ...params});
        });
        this.form.addControl(name, control);
      }
    });
  }

  @Input() refreshControl: 'off' | 'on' | 'auto' = 'on';

  @Input() resetControl: 'off' | 'on' = 'off';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly leaf: ViewContainerRef,
    // private _element:ComponentRef<Filter>,
    private _element2: Renderer2,
    @Optional() @Self() @Inject(NG_VALIDATORS) validators: any[],
    @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[]
  ) {
    super(validators, asyncValidators);
    this.form = new UntypedFormGroup({});

    let parentName = (this.leaf as any)._hostLView[0].tagName.toLowerCase();
    if (parentName.startsWith('app-')) parentName = parentName.substring(4);
    this.fragmentName = 'filter_' + parentName;
    const params = new URLSearchParams(this.route.snapshot.fragment);
    if (params.has(this.fragmentName)) {
      try {
        this.predefinedSnapshot = decode(params.get(this.fragmentName)) as Partial<TParams>;
      } catch {
      }
    }
    if (!this.predefinedSnapshot) {
      this.predefinedSnapshot = {};
    }
    this.params$ = new BehaviorSubject(this.predefinedSnapshot);
    this._snapshot = {...this.predefinedSnapshot};
    this.predefinedStringifiedSnapshot = JSON.stringify(normalize(this.predefinedSnapshot)).toLowerCase();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.pipe(
      startWith(this.form.value),
      pairwise(),
      map(([prev, cur]) => Object.keys(cur).filter(key => prev[key] !== cur[key])),
      takeUntil(this.unsubscribe$),
    ).subscribe(keys => {
      keys = keys.filter(key => this.resetOnChange.has(key));
      if (!keys.length) { return; }
      const params = {} as any;
      keys.forEach(key => params[this.resetOnChange.get(key)] = undefined);
      this.use({...this.params$.value, ...params});
    });
  }

  get filterChange(): Observable<Partial<TParams>> {
    return this.params$.pipe(
      map(params => ({...params})),
      debounceTime(100)
    );
  }

  get snapshot(): Partial<TParams> {
    return this._snapshot;
  }

  resetColumns(): void {
    this.set({});
    this.form.reset();
  }

  reset(): void {
    this.use({...this.predefinedSnapshot});
    this.form.patchValue(this._snapshot, {emitEvent: false});
  }

  refresh(): void {
    this.params$.next(this._snapshot);
  }

  private set(params: Partial<TParams>): void {
    this.use(params);
    this.form.patchValue(this.snapshot, {emitEvent: false});
  }

  apply(params: Partial<TParams>): void {
    this.use({...this.params$.value, ...params});
    this.form.patchValue(this.snapshot, {emitEvent: false});
  }

  // resetAndApply(paramsAccessor: (params: Partial<TParams>) => void): void {
  //   const params = {...this.predefinedSnapshot};
  //   paramsAccessor(params);
  //   this.applyParams(params);
  // }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private use(params: Partial<TParams>): void {
    const normalized = normalize(params);
    const stringified = JSON.stringify(normalized).toLowerCase();
    this.applied = this.predefinedStringifiedSnapshot !== stringified;
    if (JSON.stringify(this._snapshot).toLowerCase() === stringified) { return; }
    this._snapshot = normalized;

    const fragment = new URLSearchParams(this.route.snapshot.fragment || '');
    if (Object.keys(normalized).length) {
      fragment.set(this.fragmentName, encode(normalized));
    } else {
      fragment.delete(this.fragmentName);
    }
    void this.router.navigate([], {
      queryParams: this.route.snapshot.queryParams,
      fragment: fragment.toString(),
      replaceUrl: true
    });

    this.params$.next(normalized);
  }
}
