import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeComponent implements AfterViewInit{

  @ViewChild('container') containerEl: ElementRef;
  @ViewChild('tooltip') tooltip: MatTooltip;

  @Input() appearance: 'button' | 'text' = 'button';

  constructor() {
  }

  copy(): void {
    this.select();
    document.execCommand('copy');
  }

  private select(): void {
    const range = new Range();
    if (!this.containerEl) { return; }
    range.setStart(this.containerEl.nativeElement, 0);
    range.setEnd(this.containerEl.nativeElement, 1);
    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(range);
  }

  ngAfterViewInit(): void {
    if (!this.tooltip) { return; }
    this.tooltip.message = this.containerEl.nativeElement.innerText;
  }
}
