import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {SIDE_NAV_DATA, SideNavRef} from 'shared/sidenav';
import {DevicesClient, Collar, ChangeCollarServiceSettings} from 'shared';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceFormComponent {
  Number = Number;

  constructor(
    private readonly sideNavRef: SideNavRef<ServiceFormComponent>,
    private devicesClient: DevicesClient,
    @Inject(SIDE_NAV_DATA) public collar?: Collar
  ) {
  }

  async save(target: 'gsm_data' | 'gsm_update', on: boolean): Promise<void> {
    await firstValueFrom(this.devicesClient.changeCollarServiceSettings(new ChangeCollarServiceSettings({
      collar_id: this.collar.id,
      gsm_data: target == 'gsm_data' ? on : null,
      gsm_update: target == 'gsm_update' ? on : null
    })))
  }
}
