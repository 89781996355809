import {ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {SIDE_NAV_DATA, SideNavRef} from 'shared/sidenav';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  DevicesClient,
  CollarRevision,
  EditMode,
  FirmwareResponse,
  FirmwaresClient,
  GetCollarRevisions,
  UpdateFirmware
} from 'shared';
import {firstValueFrom, Observable} from 'rxjs';
import {NgrxBusy, withBusy} from "ngrx-busy";

@Component({
  selector: 'app-firmware-form',
  templateUrl: './firmware-form.component.html',
  styleUrls: ['./firmware-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirmwareFormComponent {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  EditMode = EditMode;
  Number = Number;

  mode: EditMode;
  form: UntypedFormGroup;
  selectedFile = '';

  types$: Observable<CollarRevision[]>;

  constructor(
    private readonly sideNavRef: SideNavRef<FirmwareFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private firmwaresClient: FirmwaresClient,
    private devicesClient: DevicesClient,
    @Inject(SIDE_NAV_DATA) public firmware?: FirmwareResponse
  ) {
    this.mode = firmware ? EditMode.Edit : EditMode.Create;

    this.form = this.mode === EditMode.Create
      ? this.formBuilder.group({
        file: [null, Validators.required],
        type: [null, Validators.required]
      })
      : this.formBuilder.group({
        note: [firmware.note],
        description_en: [firmware.description ? firmware.description['en'] : ''],
        description_ru: [firmware.description ? firmware.description['ru'] : ''],
        percent_for_testing: [firmware.percent_for_testing.toString()]
      });

    this.types$ = this.devicesClient.getCollarRevisions(new GetCollarRevisions());
  }

  setFile(file: File) {
    this.selectedFile = file.name;
    this.form.get('file').setValue(file);
  }

  async save(): Promise<void> {
    if (!this.form.valid) return;
    try {
      if (this.mode == EditMode.Create || !this.firmware) {
        const file = (this.form.get('file')!.value as File);
        await firstValueFrom(this.firmwaresClient.addFirmware(this.form.get('type')!.value, {
          data: file,
          fileName: file.name
        }, null).pipe(withBusy(() => this.busy)));
      } else {
        const command = this.form.getRawValue();
        if (command.description_en || command.description_ru) {
          command.description = {};
          if (command.description_en) command.description['en'] = command.description_en;
          if (command.description_ru) command.description['ru'] = command.description_ru;
        }
        await firstValueFrom(this.firmwaresClient.updateFirmware(new UpdateFirmware({
          revision: this.firmware.revision,
          version: this.firmware.version,
          ...command
        })).pipe(withBusy(() => this.busy)));
      }
      this.sideNavRef.close(true);
    } catch (error) {
      console.error(error)
      this.form.setErrors(error);
    }
  }
}
