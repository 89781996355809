<app-legend #legend
            style="display: none">
  <app-legend-item [key]="UserStatus.Active"
                   color="green">Active
  </app-legend-item>
  <app-legend-item [key]="UserStatus.NotActive">Inactive
  </app-legend-item>
  <app-legend-item [key]="UserStatus.Blocked"
                   color="red">Blocked
  </app-legend-item>
</app-legend>

<mat-card class="search"
          cdkTrapFocus
          [cdkTrapFocusAutoCapture]="true">
  <app-headline>
    <h1 app-headline-title
        color="primary">Customers</h1>
    <app-headline-actions align="right">
      Showed first {{paginator.pageSize}} of {{paginator.length}}
    </app-headline-actions>
  </app-headline>

  <app-filter [columns]="['keywords', 'status']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords"
             cdkFocusInitial>
    </mat-form-field>
  </app-filter>

</mat-card>

<ngrx-busy>
  <div class="grid">
    <a *ngFor="let customer of customers$ | async"
       [routerLink]="customer.id">
<!--      <mat-card class="profile"-->
<!--                [ngClass]="{'active': customer.status === UserStatus.Active, 'blocked': customer.status === UserStatus.Blocked }">-->
          <app-customer-card [customer]="customer"></app-customer-card>
<!--          <div>-->
<!--            <p>-->
<!--              Registered on {{customer.create_date | moment:'ll'}}-->
<!--            </p>-->
<!--          </div>-->
<!--      </mat-card>-->
    </a>
  </div>
  <mat-paginator #paginator
                 style="display: none;"
                 pageSize="63"></mat-paginator>
</ngrx-busy>
