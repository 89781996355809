import {ChangeDetectionStrategy, Component, HostBinding, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatestWith, Observable, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {CustomerPartial} from 'shared';
import {groupBy} from 'extensions/array';
import {CustomerStore} from '../customer.store';
import {NgrxBusy} from 'ngrx-busy';
import {IPet, Profile} from '../public-clients';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersDashboardComponent implements OnDestroy {
  @HostBinding('class.active') get active() {
    return this.route.snapshot.children.some(child => child.url.length > 0);
  }

  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();

  customer$: Observable<Profile>;
  pets$: Observable<Map<CustomerPartial, PetShare[]>>
  format = '1.0-2';

  constructor(
    private readonly route: ActivatedRoute,
    private store: CustomerStore
  ) {
    this.route.params.pipe(
      map(params => params['id']),
      takeUntil(this.unsubscribe$)
    ).subscribe(id => store.authenticate(id));

    this.customer$ = store.profile$.pipe(filter(p => !!p));

    this.pets$ = store.shares$.pipe(
      combineLatestWith(store.profile$),
      map(([shares, profile]) => [
        ...shares.map(share => <PetShare>{...share, accepted: true}),
        ...profile.invites.map(i => <PetShare>{...i.pet, owner: i.referrer, level: i.level, accepted: false}),
      ]),
      map(pets => groupBy(pets, pet => pet.owner, (first, second) => first.id == second.id)),
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    void this.store.authenticate(null);
  }
}

export interface PetShare extends IPet {
  accepted: boolean
}
