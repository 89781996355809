import {ComponentType} from '@angular/cdk/portal';
import {
  ComponentFactoryResolver,
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
  ComponentRef
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {take, filter} from 'rxjs/operators';

import {SideNavConfig} from './sidenav-config';
import {SideNavRef} from './sidenav-ref';
import {SideNavService} from './sidenav.service';
import {SideNavComponent} from './sidenav.component';

export const SIDE_NAV_DATA = new InjectionToken<any>('SideNavData');

@Injectable()
export class SideNav {
  componentRefMap: Map<any, ComponentRef<any>> = new Map<any, ComponentRef<any>>();

  private get instance(): SideNavComponent {
    if (this.service.instance === undefined) {
      throw new Error('Can not find sidenav container.');
    }
    return this.service.instance;
  }

  constructor(
    private readonly service: SideNavService,
    private readonly resolver: ComponentFactoryResolver,
    private readonly router: Router
  ) {
  }

  open<T, D = any, R = any>(component: ComponentType<T>, config?: SideNavConfig<D>): SideNavRef<T, R> {
    const sideNavRef = new SideNavRef(this.instance);
    this.detachView();

    const existingComponent = this.componentRefMap.get(component);
    if (existingComponent) {
      if (config && config.preserveContent !== true) {
        existingComponent.destroy();
        this.componentRefMap.delete(component);
        this.createNewComponent(sideNavRef, component, this.componentRefMap, config);
      } else {
        this.instance.container.insert(existingComponent.hostView);
      }
    } else {
      this.createNewComponent(sideNavRef, component, this.componentRefMap, config);
    }

    this.router.events.pipe(
      take(1),
      filter(evt => evt instanceof NavigationStart)
    ).subscribe(() => {
      this.destroyAllViews();
      sideNavRef.close();
      this.clear();
    });

    void this.instance.side.open();
    this.instance.cdr.markForCheck();
    return sideNavRef;
  }

  clear(): void {
    this.instance.container.clear();
    this.destroyAllViews();
  }

  private detachView(): void {
    if (this.instance.container.length > 0) {
      this.instance.container.detach();
    }
  }

  private destroyAllViews(): void {
    this.componentRefMap.forEach(c => c.destroy());
    this.componentRefMap.clear();
  }

  private createNewComponent<T, D = any, R = any>(sideNavRef: SideNavRef<T, R>, component: ComponentType<T>,
                                                  componentRefMap: Map<any, ComponentRef<T>>, config?: SideNavConfig<D>): void {
    const factory = this.resolver.resolveComponentFactory(component);
    const provides = [{provide: SideNavRef, useValue: sideNavRef}] as StaticProvider[];
    if (config !== undefined) {
      provides.push({provide: SIDE_NAV_DATA, useValue: config.data});
    }
    this.instance.hasBackdrop = config !== undefined && config.hasBackdrop !== undefined ? config.hasBackdrop : true;
    const injector = Injector.create({providers: provides});
    const view = this.instance.container.createComponent(factory, undefined, injector);
    componentRefMap.set(component, view);
    view.changeDetectorRef.detectChanges();
  }
}
