import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {delay, retryWhen, shareReplay, take, map} from 'rxjs/operators';
import {
  DictionariesClient, DictionaryResponse,
  GetDictionariesQuery,
  IDictionaryResponse
} from 'shared/clients';

type DictionaryRecords = ReadonlyMap<number, string>;

type RecordsSet = {
  [key in keyof DictionaryResponse]: DictionaryRecords;
}

// @ts-ignore
export readonly const dic: RecordsSet = {};

export const humanize = (val: number, key: keyof Omit<IDictionaryResponse, 'error_codes'>): string | number | undefined => {
  return val !== undefined && dic[key] == null
    ? val
    : val !== undefined
      ? dic[key][val] ?? val
      : undefined;
};

export const initDic = (response: IDictionaryResponse) => {
  Object.keys(response).forEach(key => dic[key] = response[key]);
};

// @Injectable({
//   providedIn: 'root'
// })
// export class ServerDictionary {
//   set$: Observable<RecordsSet>;
//
//   constructor(dictionariesClient: DictionariesClient) {
//     this.set$ = dictionariesClient.getDictionaries(new GetDictionariesQuery()).pipe(
//       retryWhen(errors => errors.pipe(delay(1000), take(10))),
//       shareReplay(1)
//     ) as Observable<RecordsSet>;
//   }
//
//   get(key: keyof Omit<IDictionaryResponse, 'error_codes'>): Observable<DictionaryRecords> {
//     return this.set$.pipe(map(set => set[key]));
//   }
// }
