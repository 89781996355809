<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Revisions</h1>
    <app-headline-actions align="left">
      <button mat-icon-button
              (click)="openForm()">
        <mat-icon>add</mat-icon>
      </button>
    </app-headline-actions>
    <app-headline-actions>
      <a mat-flat-button
         color="primary"
         routerLink="..">
        Back to devices
        <mat-icon>navigate_before</mat-icon>
      </a>
    </app-headline-actions>
  </app-headline>

  <app-filter [columns]="['keywords']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="dataSource"
             matSort
             matSortActive="create_date"
             matSortDirection="desc"
             matSortDisableClear>
        <ng-container matColumnDef="id">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Collar revision id
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{row.id}}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell
              *matHeaderCellDef>Description
          </th>
          <td mat-cell
              *matCellDef="let row"
              style="white-space:pre">{{row.description}}</td>
        </ng-container>
        <ng-container matColumnDef="reviewers">
          <th mat-header-cell
              *matHeaderCellDef>Reviewers
          </th>
          <td mat-cell
              *matCellDef="let row"
              style="white-space:pre">{{row.reviewers | pluck:'nickname'}}</td>
        </ng-container>
        <ng-container matColumnDef="create_date">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Create date
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.create_date | utc | moment:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell
              *matHeaderCellDef></th>
          <td mat-cell
              *matCellDef="let row">
            <button mat-icon-button
                    (click)="openForm(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="['id', 'description', 'reviewers', 'create_date', 'options']"></tr>
        <tr mat-row
            *matRowDef="let row; columns: ['id', 'description', 'reviewers', 'create_date', 'options']"></tr>
      </table>
    </div>
  </ngrx-busy>
</mat-card>
