import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargeComponent {

  private charge: number;

  @Input() set value(val: number) {
    this.charge = Math.min(val, 100);
  }

  get value() {
    return this.charge;
  }
}
