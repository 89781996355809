import {BehaviorSubject, combineLatestWith, firstValueFrom, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {
  AuthenticationClient, Customer,
  CustomersClient,
  fromHubStream,
  GetCustomers,
  Impersonate
} from '../../shared';
import {HttpTransportType, HubConnection, HubConnectionBuilder, ISubscription} from '@microsoft/signalr';
import {Inject, Injectable} from '@angular/core';
import {
  API_BASE_URL_PUBLIC,
  Collar,
  CustomerPartial,
  Flashlight,
  Pet,
  Profile,
  SafeZone,
  TrailPoint
} from './public-clients';

@Injectable()
export class CustomerStore {
  private petsConnection: HubConnection;
  private token$ = new BehaviorSubject<string | null>(null);
  private currentPet$ = new BehaviorSubject<string | null>(null);

  profile$ = new BehaviorSubject<Profile | null>(null);
  private$ : Observable<Customer>;
  shares$ = new BehaviorSubject<PetWithCollar[]>([]);
  flashlights$ = new BehaviorSubject<Flashlight[]>([]);
  collars$ = new BehaviorSubject<Collar[]>([]);
  safeZones$ = new BehaviorSubject<SafeZone[]>([]);
  pet$: Observable<PetWithCollar>;
  trails$ = new BehaviorSubject<TrailPoint[]>([]);
  walkers$ = new BehaviorSubject<CustomerPartial[]>([]);

  private profileSubscription?: ISubscription<Profile>;
  private sharesSubscription?: Subscription;
  private flashlightsSubscription?: Subscription;
  private collarsSubscription?: ISubscription<Pet[]>;
  private safeZonesSubscription?: ISubscription<SafeZone[]>;
  private trailSubscription?: Subscription;
  private walkersSubscription?: Subscription;

  async accessToken(): Promise<string> {
    return firstValueFrom(this.token$.pipe(filter(t => !!t)));
  }

  constructor(@Inject(API_BASE_URL_PUBLIC) private baseUrl: string,
              private readonly authenticationClient: AuthenticationClient,
              private readonly customersClient: CustomersClient) {

    const retryTimes = [0, 3000, 10000, 30000];
    this.petsConnection = new HubConnectionBuilder()
      .withUrl(`${this.baseUrl}/hubs/pets`, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => this.token$.value
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: context => {
          const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
          return retryTimes[index];
        }
      })
      .build();
    this.petsConnection.keepAliveIntervalInMilliseconds = 5000;
    this.petsConnection.serverTimeoutInMilliseconds = 10000;

    this.pet$ = this.currentPet$.pipe(
      switchMap(petId => this.shares$.pipe(
        combineLatestWith(this.collars$),
        map(([pets, collars]) => {
          let pet = pets.find(p => p.id == petId) as PetWithCollar;
          if (pet != null && !!pet.collar_id) pet.collar = collars.find(c => c.id == pet.collar_id);
          return pet;
        }))),
      filter(pet => !!pet)
    );

    this.private$ = this.profile$.pipe(
      map(profile => !!profile ? profile.id : null),
      distinctUntilChanged(),
      switchMap(id => !!id
        ? this.customersClient.getCustomers(new GetCustomers({keywords: id}))
          .pipe(map(paged => !!paged.items ? paged.items[0] : null))
        : of(null))
    )
  }

  // private token = new BehaviorSubject<string | null>(null);

  // token$ = this.token.pipe(distinctUntilChanged());

  // accessToken(): Promise<string> {
  //   return this.token$.pipe(filter(token => !!token), first()).toPromise();
  // }

  async authenticate(id?: string): Promise<void> {
    if (!!this.profileSubscription) this.profileSubscription.dispose();
    if (!!this.sharesSubscription) this.sharesSubscription.unsubscribe();
    if (!!this.flashlightsSubscription) this.flashlightsSubscription.unsubscribe();
    if (!!this.collarsSubscription) this.collarsSubscription.dispose();
    if (!!this.safeZonesSubscription) this.safeZonesSubscription.dispose();
    if (!!this.trailSubscription) this.trailSubscription.unsubscribe();
    if (!!this.walkersSubscription) this.walkersSubscription.unsubscribe();

    await this.petsConnection.stop();
    if (!id) {
      this.token$.next(null);
      this.profile$.next(null);
      this.shares$.next([]);
      this.collars$.next([]);
      this.trails$.next([]);
      this.walkers$.next([]);
      return;
    }
    const token = await firstValueFrom(this.authenticationClient.impersonate(new Impersonate({customer_id: id})));
    this.token$.next(token);
    await this.petsConnection.start();
    let reconnected = false;
    this.petsConnection.onreconnected(() => {
      if (!reconnected) {
        reconnected = true;
        this.authenticate(id);
      }
    });

    this.profileSubscription = this.petsConnection.stream<Profile>('profile').subscribe({
      next: x => {
        this.profile$.next(x);
        console.log('profile', x);
      },
      error: () => {},
      complete: () => {}
    });

    this.sharesSubscription = fromHubStream(this.petsConnection.stream<Pet[]>('shares')).pipe(
      tap(x => console.log('shares', structuredClone(x))),
      combineLatestWith(fromHubStream(this.petsConnection.stream<Collar[]>('collars'))),
      map(([pets, collars]) => {
        return pets.map(pet => {
          if (!!pet.collar_id) (<PetWithCollar>pet).collar = collars.find(c => c.id == pet.collar_id);
          return pet as PetWithCollar;
        });
      })
    ).subscribe({
      next: x => this.shares$.next(x),
      error: () => {},
      complete: () => {}
    });

    this.collarsSubscription = this.petsConnection.stream<Collar[]>('collars').subscribe({
      next: x => {
        this.collars$.next(x);
        console.log('collars', x);
      },
      error: () => {},
      complete: () => {}
    });

    this.safeZonesSubscription = this.petsConnection.stream<SafeZone[]>('safe-zones').subscribe({
      next: x => {
        this.safeZones$.next(x);
        console.log('safe-zones', x);
      },
      error: () => {},
      complete: () => {}
    });

    this.trailSubscription = this.currentPet$.pipe(
      switchMap(petId => petId ? fromHubStream(this.petsConnection.stream<TrailPoint[]>('trail', petId, 50)) : of([]))
    ).subscribe(x => {
      this.trails$.next(x);
      console.log('trails', x);
    });

    this.walkersSubscription = this.currentPet$.pipe(
      switchMap(petId => petId ? fromHubStream(this.petsConnection.stream<CustomerPartial[]>('walkers', petId)) : of([]))
    ).subscribe(x => {
      this.walkers$.next(x);
      console.log('walkers', x)
    });

    this.flashlightsSubscription = fromHubStream(this.petsConnection.stream<Flashlight[]>('flashlights'))
    .subscribe({
      next: x => {
        this.flashlights$.next(x);
        console.log('flashlights', x);
      },
      error: () => {},
      complete: () => {}
    });
  }

  setPet(id?: string): void {
    if (this.currentPet$.value == id) return;
    this.currentPet$.next(id);
  }
}

export interface PetWithCollar extends Pet {
  collar: Collar
}
