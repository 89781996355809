import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
    EditMode,
    SIDE_NAV_DATA,
    SideNavRef,
    AddUserRoleCommand,
    UpdateUserRoleCommand,
    UserRoleResponse,
    UsersClient
} from 'shared';
import { AuthScopes } from 'shared/auth-scopes';

@Component({
    selector: 'app-admin-role-form',
    templateUrl: './admin-role-form.component.html',
    styleUrls: ['./admin-role-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminRoleFormComponent {

    EditMode = EditMode;

    mode: EditMode;
    form: UntypedFormGroup;

    scopes = Object.keys(AuthScopes).map(key => AuthScopes[key]);

    constructor(
        private readonly sideNavRef: SideNavRef<AdminRoleFormComponent>,
        private formBuilder: UntypedFormBuilder,
        private usersClient: UsersClient,
        @Inject(SIDE_NAV_DATA) public role?: UserRoleResponse
    ) {
        this.mode = role ? EditMode.Edit : EditMode.Create;
        this.form = this.formBuilder.group({
            name: [role ? role.name : '', Validators.required],
            scopes: [role ? role.scopes : []]
        });
    }

    async save(): Promise<void> {
        if (!this.form.valid) return;

        const command = this.form.getRawValue();
        try {
            this.mode == EditMode.Create
                ? await this.usersClient.addUserRole(new AddUserRoleCommand(command)).toPromise()
                : await this.usersClient.updateUserRole(new UpdateUserRoleCommand({id: this.role.id, ...command})).toPromise();
            this.sideNavRef.close(true);
        } catch (error) {
            this.form.setErrors(error);
        }
    }
}
