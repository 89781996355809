<ng-container *ngIf="collar$ | async as collar">
  <ng-container *ngIf="pet$ | async as pet">
    <div class="head">
      Today
      <span *ngIf="pet.goal"
            class="goal">
        {{(collar.activity.distance * 100 / pet.goal) | number:'1.0-0':'en'}}% of daily goal
      </span>
    </div>

    <div class="progressbar">
      <div class="progress"
           [ngStyle]="{'width.%': completion(collar.activity.distance, pet.goal)}"></div>
    </div>

    <div class="cards">
      <div class="card distance">
        {{(collar.activity.distance / 1000) | number:'1.0-1':'en'}} km
        <span class="desc">Distance</span>
      </div>
      <div class="card rest">
        {{collar.activity.rest | duration | moment:'\xa0HH:mm:ss'}}
        <span class="desc">Rest</span>
      </div>

      <div class="card steps">
        {{collar.activity.steps | number:'1.0-0':'fr-FR'}}
        <span class="desc">Steps</span>
      </div>

<!--      <span>{{collar.activity.duration | duration | moment:'HH:mm:ss'}},&nbsp;</span>-->

    </div>
  </ng-container>
</ng-container>
