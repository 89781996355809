import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Feed} from '../public-clients';

@Component({
  selector: 'app-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedCardComponent {

  private feedCasted: FeedCasted;

  @Input() set feed(val: Feed) {
    this.feedCasted = Object.assign({}, val);
    // this.feedCasted = val;
    this.feedCasted.message = !!this.feedCasted.message ? this.sanitizer.bypassSecurityTrustHtml(this.feedCasted.message
        .replace(/{{ (.*?) }}/g, '<strong style="font-weight: normal; color: #82C814;">$1</strong>')) as string
      : '';
  }

  @Input() tz: string;

  get feed(): FeedCasted {
    return this.feedCasted;
  }

  constructor(private sanitizer: DomSanitizer) {
  }
}

interface FeedExtends {
  icon?: string;
  svgIcon?: string;
  color?: string;
  preview?: string;
}

interface FeedCasted extends Feed, FeedExtends {
}
