import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject, combineLatest} from 'rxjs';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {DeviceMode} from 'shared';
import {UntypedFormControl} from '@angular/forms';
import {NgrxBusy} from 'ngrx-busy';
import * as L from 'leaflet';
import {LatLngTuple} from 'leaflet';
import {CustomerStore, PetWithCollar} from '../customer.store';
import {deviceModeShade} from '../utils';
import {Collar, CustomerPartial} from '../public-clients';

@Component({
  selector: 'app-pet-detail',
  templateUrl: './pet-detail.component.html',
  styleUrls: ['./pet-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PetDetailComponent implements OnInit, OnDestroy {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ViewChild('petMarker', {static: true}) petMarker: TemplateRef<any>;

  private readonly defaultModes: number;
  private unsubscribe$: Subject<any> = new Subject();

  pet$: Observable<PetWithCollar>;
  walkers$: Observable<CustomerPartial[]>;
  collar$: Observable<Collar>;
  modes: MapMode[] = [];
  mode = new UntypedFormControl('navigation');

  toolbar: {
    items: TemplateRef<any>[],
    push: (template: TemplateRef<any>) => void,
    remove: (template: TemplateRef<any>) => void,
  };

  map: L.Map;

  constructor(route: ActivatedRoute,
              private store: CustomerStore) {
    this.registerMode('navigation', 'Dashboard', this.navigation.bind(this));
    // this.registerMode('track', 'Current track');
    this.defaultModes = this.modes.length;

    route.params
      .pipe(takeUntil(this.unsubscribe$.pipe(filter(u => u !== 'mode'))))
      .subscribe(params => this.store.setPet(params.id));

    this.pet$ = this.store.pet$;
    this.collar$ = this.pet$.pipe(map(pet => pet.collar));

    const toolbarItems = [];
    this.toolbar = {
      items: toolbarItems,
      push(template): void {
        toolbarItems.unshift(template);
      },
      remove(template): void {
        toolbarItems.splice(toolbarItems.indexOf(template), 1);
      }
    };
  }

  async ngOnInit(): Promise<void> {
    this.map = L.map('map', {
      zoom: 13,
      center: [40.765560, -73.979110]
    });

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      id: 'yurysch/clghbzbig005101qud4lv91a7',
      // id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoieXVyeXNjaCIsImEiOiJjbDFlMnJscGowNnZrM2VydG95dDJucGkyIn0.gB-3c90Gp8o3scq_cWIj3g',
      maxNativeZoom: 19,
      maxZoom: 20
    }).addTo(this.map);

    this.safeZones();

    this.mode.valueChanges.pipe(startWith(this.mode.value)).subscribe(mode => {
      this.unsubscribe$.next('mode');
      const options = this.modes.find(m => m.key === mode);
      if (!options || !options.activate) return;
      options.activate(this.unsubscribe$);
    });
  }

  private navigation(onDeactivate: Observable<any>): void {

    let layer: L.LayerGroup | null = null;
    let marker: L.Marker | null = null;

    const clearTrail = () => {
      if (layer) layer.remove();
    };

    const clearMarker = () => {
      if (marker) marker.remove();
    };

    combineLatest([this.pet$, this.store.trails$])
      .pipe(takeUntil(onDeactivate)).subscribe({
      next: ([pet, trail]) => {
        clearMarker();
        if ((!pet.collar || !pet.collar.location) && trail.length == 0) return;

        const location = trail.length > 0
          ? new L.LatLng(trail[trail.length - 1].location.lat, trail[trail.length - 1].location.lng)
          : new L.LatLng(pet.collar.location.lat, pet.collar.location.lng);
        const modeColor = deviceModeShade(!!pet.collar ? pet.collar.mode : DeviceMode.NotSet);

        this.map.panTo(location);
        this.map.setZoom(17);

        const view = this.petMarker.createEmbeddedView({pet: pet, color: modeColor});
        view.detectChanges();

        marker = L.marker(location, {
          icon: L.divIcon({
            html: view.rootNodes[0],
            iconSize: [62, 69],
            iconAnchor: [31, 69],
            className: 'place-icon'
          })
        }).addTo(this.map);
      },
      complete: () => clearMarker()
    });

    this.store.trails$.pipe(
      takeUntil(onDeactivate)
    ).subscribe({
      next: points => {
        clearTrail();
        if (!points.length) return;

        layer = L.layerGroup(points.map((point, ix) => L.circleMarker([point.location.lat, point.location.lng], {
          radius: 6,
          fillColor: deviceModeShade(point.mode, points.length - ix, 50),
          color: '#ffffff',
          opacity: 1,
          fillOpacity: 1,
          weight: 2
        }).bindPopup(`<dl>
        <dt>Mode</dt>
        <dd>${point.mode}</dd>
        <dt>Latitude</dt>
        <dd>${point.location.lat}</dd>
        <dt>Longitude</dt>
        <dd>${point.location.lng}</dd>
      </dl>`))).addTo(this.map);
      },
      complete: () => clearTrail()
    });

    onDeactivate.subscribe(() => this.walkers$ = null);
    this.walkers$ = this.store.walkers$;
  }

  private safeZones(): void {
    let layer: L.LayerGroup | null = null;

    const clearSafeZones = () => {
      if (layer) layer.remove();
    };

    this.pet$.pipe(
      switchMap(pet => this.store.safeZones$.pipe(
        map(safeZones => safeZones.filter(sz => sz.pets.findIndex(p => p.id == pet.id) >= 0))
      )),
      takeUntil(this.unsubscribe$.pipe(filter(u => u !== 'mode')))
    ).subscribe({
      next: safeZones => {
        clearSafeZones();

        const polygons = safeZones.map(sz => L.polygon(sz.area as LatLngTuple[])
          .setStyle({color: '#ff8f00', opacity: 0.8, weight: 2, fillColor: '#ff8f00', fillOpacity: 0.35})
          .bindPopup(`<strong>${sz.title}</strong><p>${sz.address}</p>`)
          .addTo(this.map));

        layer = L.layerGroup(polygons).addTo(this.map);
      },
      complete: () => clearSafeZones()
    });
  }

  registerMode(key: string, label: string, onActivate: (onDeactivate: Observable<any>) => void, activate: boolean = false): void {
    this.modes.push({key, label, activate: onActivate});
    if (activate) this.mode.setValue(key);
  }

  unregisterMode(key: string): void {
    const i = this.modes.findIndex(m => m.key === key);
    if (i < this.defaultModes) return;
    this.modes.splice(i, 1);
    if (this.mode.value === key) this.mode.setValue('navigation');
  }

  async ngOnDestroy(): Promise<void> {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  // async sendPush(): Promise<void> {
  //   const pet = await firstValueFrom(this.pet$);
  //   const isOk = await this.systemDialog.confirm(`Are you sure you want to send test notifications to ${pet.alias}'s owner?`);
  //   if (!isOk) return;
  //   await firstValueFrom(this.servicesClient.sendTestNotification(new SendTestNotification({pet_id: pet.id})));
  // }
}

interface MapMode {
  key: string;
  label: string;
  activate: (onDeactivate: Observable<any>) => void;
}
