import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrdersComponent} from './orders/orders.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'shared';
import * as StoreClients from './clients-store';
import {environment} from "../../environments/environment";
import {OrderDetailsComponent} from './orders/order-details/order-details.component';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {ReviewsComponent} from "./reviews/reviews.component";

@NgModule({
  declarations: [
    OrdersComponent,
    OrderDetailsComponent,
    ReviewsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxJsonViewerModule,
    RouterModule.forChild([
      {
        path: 'orders',
        component: OrdersComponent
      },
      {
        path: 'reviews',
        component: ReviewsComponent
      },
      {path: '**', redirectTo: 'orders', pathMatch: 'full'}
    ])
  ],
  providers: [
    {provide: StoreClients.API_BASE_URL_STORE, useFactory: () => environment.apiUrl + '/api/admin/store'},
    StoreClients.OrderClient,
    StoreClients.ReviewClient
  ]
})
export class StoreModule {
}
