import {Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Directive({
  selector: 'mat-paginator'
})
export class PaginatorExtensionsDirective implements OnDestroy {

  private unsubscribe$: Subject<any> = new Subject();

  @Input() disableAutoScroll = false

  constructor(private readonly paginator: MatPaginator,
              private readonly elementRef: ElementRef,
              private readonly route: ActivatedRoute,
              private readonly router: Router) {
    const params = new URLSearchParams(this.route.snapshot.fragment);
    if (params.has('page')) {
      this.paginator.pageIndex = Number(params.get('page')) - 1;
    }
    if (params.has('page_size')) {
      this.paginator.pageSize = Number(params.get('page_size'));
    }

    this.paginator.page
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        const fragment = new URLSearchParams(this.route.snapshot.fragment || '');
        fragment.set('page', (event.pageIndex + 1).toString());
        fragment.set('page_size', event.pageSize.toString());

        void this.router.navigate([], {
          queryParams: this.route.snapshot.queryParams,
          fragment: fragment.toString(),
          replaceUrl: true
        });

        if (!this.disableAutoScroll) {
          this.elementRef.nativeElement.parentElement.scrollIntoView()
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
