<ngrx-busy>
  <div *ngIf="tree$ | async as tree"
       class="container">

    <div class="total">
      <div class="distance">
        {{(tree.walk_distance / 1000)  | number: '1.0-1':'en'}} km
        <small>Total for {{tree.days}} days</small>
      </div>

      <div class="total-stats">
        <div class="total-stats-item">
          {{tree.walk_amount}} <small>walks</small>
        </div>
        <div class="total-stats-item">
          {{(tree.walk_avg_distance / 1000)  | number: '1.0-1':'en'}} <small>avg. km</small>
        </div>
        <div class="total-stats-item">
          {{tree.walk_avg_steps}} <small>avg. steps</small>
        </div>
      </div>
    </div>

    <div class="month">
      <mat-form-field appearance="fill" class="month-form">
        <!--          <mat-label>Select an option</mat-label>-->
        <mat-select [formControl]="monthCtrl">
          <mat-optgroup *ngFor="let yearSet of tree.years"
                        label="{{yearSet.year}}">
            <mat-option *ngFor="let monthSet of yearSet.months"
                        [value]="monthSet">
              {{monthSet.date | moment:'MMMM, yyyy'}}
            </mat-option>
          </mat-optgroup>

        </mat-select>
      </mat-form-field>

      <div *ngIf="monthCtrl.value"
           class="month-stats">
        {{monthCtrl.value.walk_amount}} walks, {{(monthCtrl.value.walk_distance / 1000) | number: '1.0-1':'en'}} km
      </div>
    </div>

    <div class="walks">
      <div class="walks-scroll">

        <ng-container *ngFor="let day of (timeline$ | async) | keyvalue: compareDay; let i = index">
          <mat-divider *ngIf="i != 0"></mat-divider>
          <ng-container *ngFor="let item of day.value">
            <ng-container [ngSwitch]="item.type">
              <a *ngSwitchCase="'walk'"
                 [routerLink]="['tracks', item.id]"
                 [routerLinkActive]="['active']"
                 class="walk">

                <div class="map">
                  <img [src]="item.preview || '/assets/images/preview_not_available.svg'"
                       onerror="this.src='/assets/images/preview_not_available.svg';"/>
                </div>

                <div class="info">

                  <div *ngIf="!item.ended_at"
                       class="in-progress">
                    <mat-icon>timelapse</mat-icon>
                    <span>In progress</span>
                  </div>

                  <span class="started">
                    <zoned-time [time]="item.started_at"
                                [zone]="item.started_at_zone"
                                format="MMM D, LT"></zoned-time>
                  </span>

                  <div class="description">
                    <mat-icon>pets</mat-icon>
                    <span>{{(item.distance / 1000) | number:'1.0-1':'en'}} km,&nbsp;</span>
                    <span>{{item.duration | moment:'HH:mm:ss'}}</span>
                  </div>

                  <span class="steps">{{item.steps | number:'1.0-0':'fr-FR'}} steps</span>

                  <div class="walkers">
                    <img *ngFor="let walker of item.walkers"
                         [src]="(walker.avatar || '/assets/images/profile.jpg') + '?size=small&scale=2'"
                         [matTooltip]="walker.full_name"
                         onerror="this.src='/assets/images/profile.jpg';"
                         class="walker"/>
                  </div>

                  <div *ngIf="!!item.achievements.length"
                       class="achievements">
                    <mat-icon svgIcon="trophy"></mat-icon>
                    <span *ngIf="item.achievements.length > 1"
                          class="achievements-amount">{{item.achievements.length}}</span>
                  </div>
                </div>

              </a>

              <a *ngSwitchCase="'rest'"
                 [routerLink]="['traces', item.id]"
                 [routerLinkActive]="['active']"
                 class="walk">

                <div class="map">
                  <img [src]="item.preview || '/assets/images/preview_not_available.svg'"
                       onerror="this.src='/assets/images/preview_not_available.svg';"/>
                </div>

                <div class="info">

                  <div *ngIf="!item.ended_at"
                       class="in-progress">
                    <mat-icon>timelapse</mat-icon>
                    <span>In progress</span>
                  </div>

                  <span class="started">
                    <zoned-time [time]="item.started_at"
                                [zone]="item.started_at_zone"
                                format="MMM D, LT"></zoned-time>
                  </span>

                  <div class="description">
                    <mat-icon class="type">home</mat-icon>
                    <span>{{(item.distance / 1000) | number:'1.0-1':'en'}} km,&nbsp;</span>
                    <span>{{item.duration | moment:'HH:mm:ss'}}</span>
                  </div>

                  <span class="steps">{{item.steps | number:'1.0-0':'fr-FR'}} steps</span>

                </div>

              </a>

              <a *ngSwitchCase="'rejected_track'"
                 [routerLink]="['tracks', item.id]"
                 [routerLinkActive]="['active']"
                 class="rejected-track">
                <mat-icon class="type">pets</mat-icon>
                <span class="started">
                  <zoned-time [time]="item.started_at"
                              [zone]="item.started_at_zone"
                              format="MMM D, LT"></zoned-time>
                </span>
                <span>Rejected by {{item.rejected_reason | humanize:'track_rejected_reason'}}</span>
              </a>

            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ngrx-busy>
