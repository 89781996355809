import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {Observable} from 'rxjs';
import {
  Filter,
  GetSubscriptionPlans, SideNav, SubscriptionDescription,
  SubscriptionPlan,
  SubscriptionsClient
} from 'shared';
import {AdminUserFormComponent} from '../admin-users/admin-user-form/admin-user-form.component';
import {SubscriptionPlanFormComponent} from './subscription-plan-form/subscription-plan-form.component';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPlansComponent {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  columns = ['price', 'trial', 'id', 'created', 'options'];
  plans$: Observable<SubscriptionPlan[]>;

  constructor(
    public subscriptionsClient: SubscriptionsClient,
    private readonly sidenav: SideNav,
    private cdr: ChangeDetectorRef) {
    this.plans$ = this.subscriptionsClient.getSubscriptionPlans(new GetSubscriptionPlans()).pipe(withBusy(() => this.busy));
  }

  async editPlan(plan: SubscriptionPlan): Promise<void> {
    const result = await this.sidenav.open(SubscriptionPlanFormComponent, {data: plan})
      .beforeClosed().toPromise();
    if (!result) { return; }

    this.plans$ = this.subscriptionsClient.getSubscriptionPlans(new GetSubscriptionPlans()).pipe(withBusy(() => this.busy));
    this.cdr.detectChanges();
  }

  getDescriptionName(plan: SubscriptionPlan): string {
    const description = plan.subscription_descriptions.filter(d => d.lang === 'en')[0];

    return description ? description.name : '';
  }
}
