import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {Sex} from 'shared';
import {Pet, PetPartial, PetShareLevel} from '../public-clients';

@Component({
  selector: 'app-pet-card',
  templateUrl: './pet-card.component.html',
  styleUrls: ['./pet-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PetCardComponent {
  @HostBinding('class.dense')
  private get dense() {
    return this.appearance === 'dense';
  }
  @HostBinding('class.accepted')
  @Input() accepted: boolean = true;

  @Input() pet: Pet | (PetPartial & { level: PetShareLevel });
  @Input() appearance: 'default' | 'dense' = 'default';

  Sex = Sex;

  get fullPet(): Pet | null {
    if ((<Pet>this.pet).breed) return <Pet>this.pet;
    return null;
  }
}
