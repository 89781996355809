<app-headline>
  <h1 app-headline-title
      color="primary">Update statistic</h1>
</app-headline>

<ngrx-busy>
  <mat-card *ngFor="let stat of stats$ | async" style="margin-bottom: 1rem">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>{{ stat.revision }}</mat-card-title>
        <mat-card-subtitle>Total operated devices in the revision: {{ stat.total }}</mat-card-subtitle>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <table mat-table
             [dataSource]="stat.versions">
        <ng-container matColumnDef="version">
          <th mat-header-cell
              *matHeaderCellDef>Firmware version
          </th>
          <td mat-cell
              *matCellDef="let row">{{ row.version }}
          </td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell
              *matHeaderCellDef>Number of devices
          </th>
          <td mat-cell
              *matCellDef="let row">{{ row.total }}
          </td>
        </ng-container>
        <ng-container matColumnDef="percent">
          <th mat-header-cell
              *matHeaderCellDef>
          </th>
          <td mat-cell
              *matCellDef="let row">
            <span class="percent">{{ row.percent }}%</span>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="['version', 'total', 'percent']"></tr>
        <tr mat-row
            *matRowDef="let row; columns: ['version', 'total', 'percent']"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</ngrx-busy>
