import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule, MAT_PAGINATOR_DEFAULT_OPTIONS} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from '@angular/material/divider';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatProgressBarModule} from "@angular/material/progress-bar";

import {A11yModule} from "@angular/cdk/a11y";
import {OverlayModule} from "@angular/cdk/overlay";
import {ScrollingModule} from '@angular/cdk/scrolling';

import {MtxDatetimepickerModule} from '@ng-matero/extensions/datetimepicker';
import {provideMomentDatetimeAdapter} from '@ng-matero/extensions-moment-adapter';
import {MTX_DATETIME_FORMATS} from '@ng-matero/extensions/core';
import {NgrxBusyModule} from 'ngrx-busy';

import {NgPipesModule} from 'ngx-pipes';

import {IMaskModule} from 'angular-imask';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {NgxChartsModule} from "@swimlane/ngx-charts";

import {SideNavModule} from './sidenav';
import {SystemDialogModule} from './system-dialog';
import {ErrorBar, ErrorModule} from './error';
import {PresentationModule} from './presentation';
import {TableModule} from './table';
import {HeadlineModule} from './headline';
import {LegendModule} from './legend';
import * as Clients from './clients';
import {BusyInterceptor} from './busy.interceptor';
import {HumanizePipe} from './humanize.pipe';
import {HexPipe} from './hex.pipe';
import {ExcludePipe} from './exclude.pipe';
import {StringInsertPipe} from './string-insert.pipe';
import {EnumPipe} from './enum.pipe';
import {DurationPipe, MomentPipe, UtcPipe} from './moment.pipe';
import {HighlightComponent} from './highlight.component';
import {environment} from "../environments/environment";
import {ZonedTimeComponent} from "./zoned-time.component";
import {ChargeComponent} from './charge/charge.component';
import {VirtualScrollSizesDirective} from "./virtual-scroll-sizes.directive";
import {OverlayHoverDirective} from "./overlay-hover.directive";
import {ArraySortPipe} from "./sort.pipe";
import {AuthInterceptor} from './auth.interceptor';
import {initDic} from './server-dictionary';

const material = [
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSidenavModule,
  MatAutocompleteModule,
  MatTableModule,
  MatSortModule,
  MatTabsModule,
  MatRadioModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatListModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatButtonToggleModule,
  MatCardModule,
  MatMenuModule,
  MatTooltipModule,
  MatChipsModule,
  MatRippleModule,
  MatDialogModule,
  MatDividerModule,
  MatProgressBarModule,
  MtxDatetimepickerModule,
  MatSlideToggleModule
]

const modules = [
  ...material,
  NgPipesModule,
  NgrxBusyModule,
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  NgxChartsModule,
  IMaskModule,
  SideNavModule,
  SystemDialogModule,
  ErrorModule,
  PresentationModule,
  TableModule,
  HeadlineModule,
  LegendModule,
  NgxJsonViewerModule,
  A11yModule,
  OverlayModule,
  ScrollingModule
];

export function env_Init() {
  return () => fetch('environment.json')
    .then(response => response.ok ? response.json() : Promise.reject())
    .then(body => Object.keys(body).forEach(key => environment[key] = body[key]))
    .catch(() => {})
    .then(() => fetch(environment.apiUrl + '/api/admin/get-dictionaries', {
      method: 'PUT', headers: { "Content-Type": "application/json" }, body: '{}'
    }))
    .then(res => res.json())
    .then(body => initDic(body));
}

const pipes = [HumanizePipe, HexPipe, ExcludePipe, EnumPipe, StringInsertPipe, MomentPipe, UtcPipe, DurationPipe, ArraySortPipe];
const directives = [VirtualScrollSizesDirective, OverlayHoverDirective];
const components = [HighlightComponent, ZonedTimeComponent, ChargeComponent];
const services = [
  {provide: APP_INITIALIZER, useFactory: env_Init, deps: [HttpClient], multi: true},
  {provide: Clients.API_BASE_URL, useFactory: () => environment.apiUrl},
  {provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  {provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {showFirstLastButtons: true, pageSizeOptions: [20, 50, 100]}},
  // {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  // {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
  provideMomentDateAdapter(undefined, {useUtc: true}),
  provideMomentDatetimeAdapter(undefined, {useUtc: true}),
  // { provide: DateAdapter, useClass: MomentDateAdapter },
  // { provide: DatetimeAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS], },
  { provide: MTX_DATETIME_FORMATS, useValue: {
      parse: {
        dateInput: 'DD.MM.YYYY',
        monthInput: 'MMMM',
        yearInput: 'YYYY',
        timeInput: 'HH:mm:ss',
        datetimeInput: 'DD.MM.YYYY HH:mm:ss',
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthInput: 'MMMM',
        yearInput: 'YYYY',
        timeInput: 'HH:mm:ss',
        datetimeInput: 'DD.MM.YYYY HH:mm:ss',
        monthYearLabel: 'YYYY MMMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        popupHeaderDateLabel: 'MMM DD, ddd',
      }
    }
  },
  ErrorBar
];

@NgModule({
  imports: [...modules, HttpClientModule],
  declarations: [...pipes, ...directives, ...components],
  exports: [...modules, ...pipes, ...directives, ...components],
  providers: [
    ...services,
    DecimalPipe
  ]
})
export class SharedModule {
}
