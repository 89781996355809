<form name="form"
      [formGroup]="form"
      (ngSubmit)="save()"
      class="side-form">

  <h3>{{ mode === EditMode.Edit ? 'Update' : 'Create' }} user</h3>

  <mat-form-field appearance="fill">
    <mat-label>User name</mat-label>
    <input matInput
           formControlName="username">
    <mat-error *ngIf="form.get('username').hasError('required')">
      User name is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Display name</mat-label>
    <input matInput
           formControlName="nickname">
    <mat-error *ngIf="form.get('nickname').hasError('required')">
      Display name is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{ mode === EditMode.Edit ? 'New password' : 'Password' }}</mat-label>
    <input matInput
           formControlName="password">
    <mat-error *ngIf="form.get('password').hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Role</mat-label>
    <mat-select formControlName="roles"
                multiple>
      <mat-option *ngFor="let role of roles$ | async"
                  [value]="role">
        {{role}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="side-form-actions">
    <button mat-raised-button
            type="submit"
            color="primary"
            [disabled]="form.invalid">
      {{mode === EditMode.Edit ? 'Update' : 'Create'}}
    </button>
  </div>

  <app-form-error></app-form-error>
</form>
