<div class="side-form">

  <div class="h2 mb-24">Service settings</div>

  <mat-slide-toggle color="primary"
                    [checked]="collar.gsm_data"
                    (change)="save('gsm_data', $event.checked)">
    GSM data
  </mat-slide-toggle>

  <mat-slide-toggle color="primary"
                    [checked]="collar.gsm_update"
                    (change)="save('gsm_update', $event.checked)">
    GSM update
  </mat-slide-toggle>
</div>
