import {DeviceMode, SpeedLevel} from 'shared';
import {DeviceMode as PublicDeviceMode} from './public-clients';

const roundToNearest5 = x => Math.round(x / 5) * 5;

export function deviceModeShade(mode: DeviceMode | PublicDeviceMode): string;
export function deviceModeShade(mode: DeviceMode | PublicDeviceMode, ix: number, total: number): string;
export function deviceModeShade(mode: DeviceMode | PublicDeviceMode, ix?: number, total?: number): string {
  // let shade = ix != null && total != null
  //   ? (0.6 - 0.6 / total * ix)
  //   : 0;
  // const opacity = ix != null && delimiter != null
  //     ? Math.max(20, 100 - Math.trunc(ix / 15) * 20) / 100
  //     : 1;

  const highlight = (initial: number): number => {
    if (ix == null || total == null) { return initial; }
    return Math.min(80, initial + roundToNearest5((80 - initial) / total * ix));
  };

  switch (mode) {
    case DeviceMode.Walk:
    case DeviceMode.LowEnergy:
    case DeviceMode.FwUpdate:
    case DeviceMode.EnergySaving: return hslToHex(208, 90, highlight(57)); // return `rgba(50, 151, 245, ${opacity})`;
    case DeviceMode.Ble:
    case DeviceMode.Alarm: return hslToHex(31, 95, highlight(53)); // `rgba(250, 140, 22, ${opacity})`;
    case DeviceMode.Lost: return hslToHex(1, 87, highlight(60)); // `rgba(242, 69, 65, ${opacity})`;
    case DeviceMode.OffLeash: return hslToHex(228, 82, highlight(55)); // `rgba(47, 84, 235, ${opacity})`;
    case DeviceMode.Sleep:
    case DeviceMode.Safe: return hslToHex(83, 81, highlight(43)); // `rgba(130, 200, 20, ${opacity})`;
    default: return hslToHex(0, 0, highlight(54)); // return `rgba(140, 140, 140, ${opacity})`;
  }
}


export function speedLevelShade(speedLevel: SpeedLevel, ix: number, total: number): string {
  const highlight = (initial: number): number => {
    return Math.min(80, initial + roundToNearest5((80 - initial) / total * ix));
  };

  switch (speedLevel) {
    case SpeedLevel.OnFoot: return hslToHex(208, 90, highlight(57)); // return `rgba(50, 151, 245, ${opacity})`;
    case SpeedLevel.Car: return hslToHex(31, 95, highlight(53)); // return `rgba(50, 151, 245, ${opacity})`;
    default: hslToHex(0, 0, highlight(54)); // return `rgba(140, 140, 140, ${opacity})`;
  }
}

export function hslToHex(h, s, l): string {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function hexToHsl(hex): { h: number, s: number, l: number } {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  r /= 255, g /= 255, b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if(max == min){
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  s = s*100;
  s = Math.round(s);
  l = l*100;
  l = Math.round(l);
  h = Math.round(360*h);

  return {h: h, s: s, l: l};
}

export enum TrackColor {
  OnFoot = '#53ACF5FF',
  Car = '#FFA940FF',
  Plane = '#F24541FF',
  Off = 'rgb(138, 138, 138)'
}
