import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-highlight',
  template: `<span [innerHTML]="formatted"></span>`,
  styleUrls: ['highlight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HighlightComponent  {

  @Input() set value(val: string) {
    this.formatted = this.sanitizer.bypassSecurityTrustHtml(this.syntaxHighlight(val));
  }

  formatted: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
  }

  private syntaxHighlight = (json) => {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }

    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          match = match.substring(1, match.length - 2) + ':';
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="app-highlight app-highlight__json--' + cls + '">' + match + '</span>';
    });
  }
}
