<div [ngSwitch]="!!feed.icons ? feed.icons.length : 0"
     class="preview">
  <img *ngSwitchCase="0"
       [src]="'/assets/images/collar-info.png?size=small&scale=2'"
       class="preview-img"/>
  <img *ngSwitchCase="1"
       [src]="feed.icons[0] + '?size=small&scale=2'"
       class="preview-img"/>
  <ng-container *ngSwitchDefault>
    <img [src]="feed.icons[0] + '?size=small&scale=2'"
         class="preview-img first"/>
    <img [src]="feed.icons[1] + '?size=small&scale=2'"
         class="preview-img second"/>
  </ng-container>

  <div *ngIf="feed.badge_url" [style]="'background-color: ' + feed.badge_color || 'white'"
       class="badge">
    <img [src]="feed.badge_url">
  </div>
</div>
<span class="desc" [innerHTML]="feed.message"></span>
<span class="timestamp">
  <zoned-time [time]="feed.timestamp"
              [zone]="tz"
              format="calendar"></zoned-time>
<!--  {{feed.timestamp | moment:'calendar'}}-->
</span>

