import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {ObservableInput} from 'ngx-observable-input';
import {Observable, of, zip} from 'rxjs';
import {distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {GetRejectedTracks, TrackRejectedReason, TracksClient} from 'shared';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import * as moment from 'moment';
import {
  Pet,
  Timeline,
  TimelinePublicClient,
  TimelineTree,
  TimelineTreeMonth,
  TimelineTreeYear
} from '../public-clients';
import {UntypedFormControl} from '@angular/forms';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TracksComponent {

  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  @ObservableInput() @Input('pet') pet$: Observable<Pet>;

  tree$: Observable<Tree>;

  monthCtrl = new UntypedFormControl();

  timeline$: Observable<{ [key: string]: (Timeline & RejectedTrack)[]; }>;

  constructor(tracksClient: TracksClient,
              timelineClient: TimelinePublicClient) {

    this.tree$ = this.pet$.pipe(
      distinctUntilChanged((prev, cur) => prev.id == cur.id),
      switchMap(pet => timelineClient.getTimelineTree(pet.id).pipe(
        map(tree => {
          let casted = tree as Tree;
          casted.years.forEach(yearSet => {
            yearSet.months.forEach(monthSet => {
              monthSet.date = moment([yearSet.year, monthSet.month - 1, 1]);
              monthSet.pet = pet;
            })
          });
          return casted;
        })
      )),
      withBusy(() => this.busy),
      tap(tree => setTimeout(() => this.monthCtrl.setValue(tree.years.length ? tree.years[0].months[0] : null), 50))
    );

    this.timeline$ = this.monthCtrl.valueChanges.pipe(
      startWith(this.monthCtrl.value),
      switchMap(selected => !!selected ? zip(
        timelineClient.getTimelineGroup(selected.pet.id, selected.date.year(), selected.date.month() + 1),
        tracksClient.getRejectedTracks(new GetRejectedTracks({
          pet_id: selected.pet.id,
          year: selected.date.year(),
          month: selected.date.month() + 1
        }))
      ) : of([{}, []])),
      map(([tl, tracks]) => {
        Object.keys(tracks).forEach(day => {
          if (!tl[day]) tl[day] = [];
          tl[day].push(...tracks[day].map(track =>
            ({
              ...new Timeline({
                type: 'rejected_track',
                id: track.id,
                started_at: track.started_at,
                started_at_zone: track.started_at_zone,
              }), rejected_reason: track.rejected_reason
            } as Timeline & { rejected_reason: TrackRejectedReason })));
          tl[day].sort((a, b) => b.started_at.unix() - a.started_at.unix())
        });
        return tl;
      }),
      withBusy(() => this.busy)
    )
  }

  compareDay(a: KeyValue<string, (Timeline & RejectedTrack)[]>, b: KeyValue<string, (Timeline & RejectedTrack)[]>): number {
    return moment(b.key).unix() - moment(a.key).unix();
  }
}

class Tree extends TimelineTree {
  years: TreeYear[];
}

class TreeYear extends TimelineTreeYear {
  months: TreeMonth[];
}

class TreeMonth extends TimelineTreeMonth {
  date: moment.Moment;
  pet: Pet;
}

interface RejectedTrack {
  rejected_reason: TrackRejectedReason
}
