import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {SIDE_NAV_DATA, SideNavRef} from 'shared/sidenav';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  Breed, BreedsClient,
  EditMode, UpdateBreed
} from 'shared';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-breed-form',
  templateUrl: './breed-form.component.html',
  styleUrls: ['./breed-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreedFormComponent {

  EditMode = EditMode;
  Number = Number;

  mode: EditMode;
  form: UntypedFormGroup;

  constructor(
    private readonly sideNavRef: SideNavRef<BreedFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private breedsClient: BreedsClient,
    @Inject(SIDE_NAV_DATA) public breed?: Breed
  ) {
    this.mode = breed ? EditMode.Edit : EditMode.Create;

    this.form = this.formBuilder.group({
      en: [breed ? breed.localize.en : null, Validators.required],
      ru: [breed ? breed.localize.ru : null],
      height: [breed ? breed.height.toString() : null]
    });
  }

  async save(): Promise<void> {
    if (!this.form.valid) { return; }

    try {
      const command = this.form.getRawValue();
      if (this.mode === EditMode.Create || !this.breed) {
        await firstValueFrom(this.breedsClient.addBreed(command));
      } else {
        await firstValueFrom(this.breedsClient.updateBreed(new UpdateBreed({breed_id: this.breed.id, ...command})));
      }
      this.sideNavRef.close(true);
    } catch (error) {
      this.form.setErrors(error);
    }
  }
}
