import {Observable, ReplaySubject} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {SideNavComponent} from './sidenav.component';

export class SideNavRef<_, R = any> {
  private static readonly result$ = new ReplaySubject<any | undefined>(1);

  constructor(
    private readonly component: SideNavComponent
  ) {
    SideNavRef.result$.next(undefined);
  }

  close(result?: R): void {
    SideNavRef.result$.next(result);
    void this.component.side.close();
    this.component.cdr.detectChanges();
  }

  setResult(result?: R): void {
    SideNavRef.result$.next(result);
  }

  afterClosed(): Observable<R | undefined> {
    return this.component.side._closedStream.pipe(switchMap(() => SideNavRef.result$), take(1));
  }

  beforeClosed(): Observable<R | undefined> {
    return this.component.side.closedStart.pipe(switchMap(() => SideNavRef.result$), take(1));
  }
}
