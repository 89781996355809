<app-legend #legend
            style="display: none">
  <app-legend-item [key]="DeviceStatus.Active"
                   color="green">Active
  </app-legend-item>
  <app-legend-item [key]="DeviceStatus.Inactive">Inactive</app-legend-item>
  <app-legend-item [key]="DeviceStatus.Banned"
                   color="red">Banned
  </app-legend-item>
</app-legend>

<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Collars</h1>
  </app-headline>

  <app-filter [columns]="['keywords', 'status']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option></mat-option>
        <mat-option *ngFor="let status of Dic.device_statuses | keyvalue"
                    [value]="status.key">
          {{status.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="dataSource"
             matSort
             matSortActive="create_date"
             matSortDirection="desc"
             matSortDisableClear>
        <ng-container matColumnDef="id">
          <th mat-header-cell
              *matHeaderCellDef>Device id
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <app-code>{{row.id}}</app-code>
          </td>
        </ng-container>
        <ng-container matColumnDef="serial">
          <th mat-header-cell
              *matHeaderCellDef>Serial number
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Revision
          </th>
          <td mat-cell
              *matCellDef="let row">
            <button mat-icon-button
                    (click)="filter.apply({'keywords': row.revision})">
              <mat-icon>search</mat-icon>
            </button>
            <a routerLink="types"
               mat-button
               color="primary"
               [filter]="{keywords: row.revision}"
               filterLink="device-types">
              {{row.revision}}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="firmware">
          <th mat-header-cell
              *matHeaderCellDef>Firmware
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.firmware}}
          </td>
        </ng-container>
        <ng-container matColumnDef="ccid">
          <th mat-header-cell
              *matHeaderCellDef>CCID
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.ccid}}</td>
        </ng-container>
        <ng-container matColumnDef="imei">
          <th mat-header-cell
              *matHeaderCellDef>IMEI
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.imei}}</td>
        </ng-container>
        <ng-container matColumnDef="mac">
          <th mat-header-cell
              *matHeaderCellDef>MAC address
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.mac}}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell
              *matHeaderCellDef>Phone number
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="mode">
          <th mat-header-cell
              *matHeaderCellDef>Mode
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.mode | humanize:'device_modes'}}</td>
        </ng-container>
        <ng-container matColumnDef="last_active">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Last active
          </th>
          <td mat-cell
              *matCellDef="let row">{{row.last_active | utc | moment:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="create_date">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Create date
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.create_date | utc | moment:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell
              *matHeaderCellDef></th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <button mat-icon-button
                    [matMenuTriggerFor]="simOptions"
                    [matMenuTriggerData]="{data: row}">
              <mat-icon *ngIf="row.sim_status == SimStatus.Inactive"
                        matTooltip="Inactive"
                        class="inactive-icon">sim_card
              </mat-icon>
              <mat-icon *ngIf="row.sim_status == SimStatus.Active"
                        matTooltip="Active"
                        class="active-icon">sim_card
              </mat-icon>
              <mat-icon *ngIf="row.sim_status == SimStatus.Pending"
                        matTooltip="Pending"
                        class="pending-icon">sim_card
              </mat-icon>
            </button>
            <button mat-icon-button
                    [routerLink]="['battery', row.id]">
              <mat-icon matTooltip="Click to view battery details">battery_full</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    matTooltip="Service tools"
                    (click)="openService(row)">
              <mat-icon>construction</mat-icon>
            </button>
            <a mat-icon-button
               matTooltip="View requests"
               [routerLink]="[row.id]">
              <mat-icon svgIcon="requests"></mat-icon>
            </a>
            <a mat-icon-button
               [color]="row.linked_pet != null ? 'primary' : null"
               [routerLink]="row.linked_pet != null ? ['../customers', row.linked_pet.owner_id, 'pets', row.linked_pet.pet_id] : null">
              <mat-icon matTooltip="{{row.linked_pet != null ? 'Go to user/pet details' : 'There is no linked pet'}}">
                person
              </mat-icon>
            </a>
          </td>
        </ng-container>


        <!-- <ng-container matColumnDef="options">
            <button mat-icon-button
                    [color]="row.encrypted ? 'primary' : 'warn'"
                    [matMenuTriggerFor]="rowOptions"
                    [matMenuTriggerData]="{data: row}">
              <mat-icon>{{row.encrypted ? 'verified_user' : 'remove_moderator'}}</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell
              *matHeaderCellDef></th>
          <td mat-cell
              *matCellDef="let row">
            <button mat-icon-button
                    (click)="openForm(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container> -->

        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: columns"
            [legendRow]="legend"
            [legendRowValue]="row.status"></tr>
      </table>
    </div>
    <mat-paginator></mat-paginator>

    <mat-menu #simOptions="matMenu">
      <ng-template matMenuContent
                   let-device="data">
        <button mat-menu-item
                (click)="simDetails(device)">
          <mat-icon>info</mat-icon>
          Info
        </button>
        <button *ngIf="device.sim_status === SimStatus.Inactive"
                mat-menu-item
                (click)="activateSim(device)">
          <mat-icon>toggle_off</mat-icon>
          Activate
        </button>
        <button *ngIf="device.sim_status === SimStatus.Active"
                mat-menu-item
                (click)="deactivateSim(device)">
          <mat-icon>toggle_on</mat-icon>
          Deactivate
        </button>
      </ng-template>
    </mat-menu>

  </ngrx-busy>
</mat-card>

<ng-template #activate
             let-device="device">
  Sim card will be activated:
  <div>

  </div>
  <p>CCID: {{device.ccid}}</p>
  <p>IMEI: {{device.imei}}</p>
</ng-template>
