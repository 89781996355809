import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import { Order } from 'app/store/clients-store';
import { SIDE_NAV_DATA } from 'shared';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsComponent implements OnInit {

  constructor(@Inject(SIDE_NAV_DATA) public order: Order) {}

  ngOnInit(): void {}
}
