import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';

import {SharedModule, SideNavModule} from 'shared';
import {AppComponent} from './app.component';
import {LoginModule} from './login/login.module';
import {SupportModule} from './support/support.module';
import {DevicesModule} from './devices/devices.module';
import {WalksModule} from './walks/walks.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CustomersModule} from './customers/customers.module';
import {NavBarComponent} from 'app/nav-bar/navbar.component';
import {StoreModule} from './store/store.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {BreedsModule} from './breeds/breeds.module';
import {AchievementsModule} from './achievements/achievements.module';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    LoginModule,
    SideNavModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => DashboardModule
      },
      {
        path: 'support',
        loadChildren: () => SupportModule
      },
      {
        path: 'customers',
        loadChildren: () => CustomersModule
      },
      {
        path: 'breeds',
        loadChildren: () => BreedsModule
      },
      {
        path: 'achievements',
        loadChildren: () => AchievementsModule
      },
      {
        path: 'devices',
        loadChildren: () => DevicesModule
      },
      {
        path: 'walks',
        loadChildren: () => WalksModule
      },
      {
        path: 'store',
        loadChildren: () => StoreModule
      },
      {
        path: '**',
        redirectTo: 'customers',
        pathMatch: 'full'
      }
    ])
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: navigator.language
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
