import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  EditMode,
  SIDE_NAV_DATA,
  SideNavRef,
  AddUserCommand,
  GetUserRolesQuery,
  UpdateUserCommand,
  UserResponse,
  UsersClient
} from 'shared';

@Component({
  selector: 'app-admin-user-form',
  templateUrl: './admin-user-form.component.html',
  styleUrls: ['./admin-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminUserFormComponent {

  EditMode = EditMode;

  mode: EditMode;
  form: UntypedFormGroup;
  roles$: Observable<string[]>;

  constructor(
    private readonly sideNavRef: SideNavRef<AdminUserFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private usersClient: UsersClient,
    @Inject(SIDE_NAV_DATA) public user?: UserResponse
  ) {
    this.mode = user ? EditMode.Edit : EditMode.Create;
    this.form = this.formBuilder.group({
      username: [user ? user.username : '', Validators.required],
      nickname: [user ? user.nickname : '', Validators.required],
      password: ['', user ? null : Validators.required],
      roles: [user ? user.roles : []]
    });

    this.roles$ = this.usersClient.getUserRoles(new GetUserRolesQuery())
      .pipe(map(roles => roles.map(role => role.name)));
  }

  async save(): Promise<void> {
    if (!this.form.valid) return;

    const command = this.form.getRawValue();
    try {
      this.mode == EditMode.Create
        ? await this.usersClient.addUser(new AddUserCommand(command)).toPromise()
        : await this.usersClient.updateUser(new UpdateUserCommand({id: this.user.id, ...command})).toPromise();
      this.sideNavRef.close(true);
    } catch (error) {
      this.form.setErrors(error);
    }
  }
}
