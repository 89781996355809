import {ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {
  dic,
  SideNav,
  Filter,
  PagedResponse,
  Collar,
  DevicesClient,
  GetCollars,
  DeviceStatus,
  SimStatus,
  SystemDialog,
  ObservedDataSource,
  ActivateSim,
  DeactivateSim
} from 'shared';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {MatDialog} from "@angular/material/dialog";
import {SimDetailComponent} from "./sim-detail.component";
import {ServiceFormComponent} from "./service-form/service-form.component";

@Component({
  selector: 'app-collars',
  templateUrl: './collars.component.html',
  styleUrls: ['./collars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollarsComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ViewChild('activate') activateTmpl: TemplateRef<any>;

  dataSource: DevicesDataSource;
  columns = ['id', 'type', 'firmware', 'serial', 'ccid', 'imei', 'mac', 'mode', 'last_active', 'create_date', 'status'];

  DeviceStatus = DeviceStatus;
  SimStatus = SimStatus;
  Dic = dic;

  constructor(private devicesClient: DevicesClient,
              private sidenav: SideNav,
              private systemDialog: SystemDialog,
              private dialog: MatDialog) {

    this.dataSource = new DevicesDataSource(devicesClient);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
  }

  simDetails(request: Collar) {
    this.dialog.open(SimDetailComponent, {data: request});
  }

  async activateSim(device: Collar) {
    const isOk = await this.systemDialog.confirm(this.activateTmpl, {
      confirm: {title: 'Confirm', color: 'accent'},
      device
    });
    if (!isOk) return;

    await this.devicesClient.activateSim(new ActivateSim({collar_id: device.id})).pipe(withBusy(() => this.busy)).toPromise();
    this.dataSource.refresh();
  }

  async deactivateSim(device: Collar) {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to deactivate the sim?`, {
      confirm: {
        title: 'Confirm',
        color: 'warn'
      }
    });
    if (!isOk) return;

    await this.devicesClient.deactivateSim(new DeactivateSim({collar_id: device.id})).pipe(withBusy(() => this.busy)).toPromise();
    this.dataSource.refresh();
  }

  openService(collar: Collar) {
    this.sidenav.open(ServiceFormComponent, {data: collar})
  }
}

class DevicesDataSource extends ObservedDataSource<Collar, GetCollars> {
  constructor(private devicesClient: DevicesClient) {
    super(device => device.id);
  }

  source(query: Partial<GetCollars>): Observable<PagedResponse<Collar>> {
    return this.devicesClient.getCollars(new GetCollars(query));
  }
}
