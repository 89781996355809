import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {SignInCommand, AuthManager} from 'shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {

  form = this.formBuilder.group({
    user_name: ['', [Validators.required]],
    password: ['', Validators.required],
    is_persistent: true
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authManager: AuthManager,
    private router: Router
  ) {
  }

  async login(): Promise<void> {
    if (!this.form.valid) return;
    const success = await this.authManager.signIn(new SignInCommand(this.form.getRawValue()));
    if (!success) {
      this.form.setErrors({'invalid_credentials': true});
      return;
    }

    await this.router.navigateByUrl(this.router.url);
  }
}
