<mat-card>
  <app-headline>
    <h1 app-headline-title>Support users</h1>
    <app-headline-actions align="left">
      <button mat-icon-button
              (click)="openForm()">
        <mat-icon>add</mat-icon>
      </button>
    </app-headline-actions>
  </app-headline>

  <ngrx-busy>
    <table mat-table
           [dataSource]="dataSource"
           matSort
           matSortActive="username"
           matSortDirection="asc"
           matSortDisableClear>
      <ng-container matColumnDef="username">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Name
        </th>
        <td mat-cell
            *matCellDef="let row">{{row.username}}</td>
      </ng-container>
      <ng-container matColumnDef="nickname">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Display name
        </th>
        <td mat-cell
            *matCellDef="let row">{{row.nickname}}</td>
      </ng-container>
      <ng-container matColumnDef="roles">
        <th mat-header-cell
            *matHeaderCellDef>Roles
        </th>
        <td mat-cell
            *matCellDef="let row">{{row.roles}}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let row">
          <button mat-icon-button
                  color="warn"
                  (click)="remove(row)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button
                  (click)="openForm(row)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row
          *matHeaderRowDef="['username', 'nickname', 'roles', 'options']"></tr>
      <tr mat-row
          *matRowDef="let row; columns: ['username', 'nickname', 'roles', 'options']"></tr>
    </table>
  </ngrx-busy>
</mat-card>
