import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HighchartsChartModule} from 'highcharts-angular';
import {SharedModule} from 'shared';
import {DeviceTypesComponent} from './device-types/device-types.component';
import {DevicesComponent} from './devices.component';
import {DeviceTypeFormComponent} from './device-types/device-type-form/device-type-form.component';
import {CollarsComponent} from './collars/collars.component';
import {DeviceBatteryComponent} from './device-battery/device-battery.component';
import {DeviceDetailComponent} from './device-detail/device-detail.component';
import {RequestsModule} from 'app/requests/requests.module';
import {SimDetailComponent} from './collars/sim-detail.component';
import {FlashlightsComponent} from "./flashlights/flashlights.component";
import {FirmwaresComponent} from "./firmwares/firmwares.component";
import {BeaconsComponent} from "./beacons/beacons.component";
import {BatteriesComponent} from "./batteries/batteries.component";
import {ServiceFormComponent} from "./collars/service-form/service-form.component";
import {FirmwareFormComponent} from "./firmwares/firmware-form/firmware-form.component";

@NgModule({
  declarations: [
    DevicesComponent,
    CollarsComponent,
    FlashlightsComponent,
    FirmwaresComponent,
    FirmwareFormComponent,
    BeaconsComponent,
    BatteriesComponent,
    DeviceTypesComponent,
    DeviceTypeFormComponent,
    ServiceFormComponent,
    DeviceBatteryComponent,
    DeviceDetailComponent,
    SimDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RequestsModule,
    HighchartsChartModule,
    MatProgressSpinnerModule,
    RouterModule.forChild([
      {
        path: '',
        component: DevicesComponent
      },
      {
        path: 'types',
        component: DeviceTypesComponent
      },
      {
        path: 'battery/:id',
        component: DeviceBatteryComponent
      },
      {
        path: ':id',
        component: DeviceDetailComponent
      }
    ])
  ]
})
export class DevicesModule {
}
