<ng-container [ngSwitch]="state$ | async">
  <ng-container *ngSwitchCase="'reachable'"
                [ngTemplateOutlet]="main"></ng-container>
  <ng-container *ngSwitchCase="'guest'"
                [ngTemplateOutlet]="login"></ng-container>
</ng-container>

<ng-template #main>
  <div class="nav">
    <app-navbar></app-navbar>
  </div>
  <main class="main">
    <app-side-nav>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </app-side-nav>
  </main>
</ng-template>

<ng-template #login>
  <app-login></app-login>
</ng-template>
