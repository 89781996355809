import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as Highcharts from 'highcharts';
import {DevicesClient, Filter, GetCollarBattery} from 'shared';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-device-battery',
  templateUrl: './device-battery.component.html',
  styleUrls: ['./device-battery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceBatteryComponent implements OnInit {
  isDataLoaded = false;
  formFilter: UntypedFormGroup;
  device_id: string
  start_date: Date;
  end_date: Date;
  filt: any;
  @ViewChild(Filter) filter: Filter;

  constructor(fb: UntypedFormBuilder,
              private devicesClient: DevicesClient,
              private readonly route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {

    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.formFilter = fb.group({
      keywords: ['', Validators.required],
      start_date: [d, Validators.required],
      end_date: [new Date(), Validators.required],
    });

  }

  async ngOnInit() {
    this.route.params.subscribe(p => {
      this.formFilter.controls['keywords'].setValue(p.id);
      this.refresh();
    })

  }

  barHighcharts: typeof Highcharts = Highcharts;
  highcharts: typeof Highcharts = Highcharts;
  linesHighcharts: typeof Highcharts = Highcharts;


  async refresh() {
    this.cdr.detectChanges();
    this.isDataLoaded = false;
    if (this.formFilter.invalid)
      return;

    let cat = this.xAxisGenerator(this.formFilter.controls['start_date'].value, this.formFilter.controls['end_date'].value);
    let bch = [];
    let bi = [];
    let data = await this.devicesClient.getCollarBattery(new GetCollarBattery({
      avg: cat.avg,
      device_id: this.formFilter.controls['keywords'].value,
      start_date: this.formFilter.controls['start_date'].value,
      end_date: this.formFilter.controls['end_date'].value
    })).toPromise();

    data.battery_charges.forEach(x => {
      let a = moment.unix(x.timestamp).utc() as any;
      bch.push([a._i, x.battery_charge]);
    });
    this.lineChartOptions.series = [
      {
        type: "line",
        name: "Battery",
        data: bch
      }];

    data.batteries_info.forEach(x => {
      let en = moment.unix(x.end_time).utc() as any;

      let st = moment.unix(x.start_time).utc() as any;
      let y = (en._i - st._i) / (60 * 60 * 24);

      bi.push(
        {
          type: 'bar',
          name: x.battery_id + ', Period ' + st.format('DD-MM-YYYY HH:mm') + ' - ' + en.format('DD-MM-YYYY HH:mm'),
          data: [y]
        }
      )
    });
    if (data.batteries_info.length == 1) {
      let design_capacity = {
        type: 'line',
        name: 'Design capacity',
        data: []
      };
      let full_cap = {
        type: 'line',
        name: 'Full cap',
        data: []
      };
      let n_cycles = {
        type: 'line',
        name: 'n cycles ',
        data: []
      };
      let cur_capacity = {
        type: 'line',
        name: 'cur capacity ',
        data: []
      };
      let cur_voltage = {
        type: 'line',
        name: 'cur voltage ',
        data: []
      };

      data.batteries_info[0].spec_parmeters.forEach(x => {
        let d = moment.unix(x.timestamp).utc() as any;
        design_capacity.data.push([d._i, x.design_capacity]);
        full_cap.data.push([d._i, x.full_cap]);
        n_cycles.data.push([d._i, x.n_cycles]);
        cur_capacity.data.push([d._i, x.cur_capacity]);
        cur_voltage.data.push([d._i, x.cur_voltage]);
      });
      let lins = [];
      lins.push(design_capacity);
      lins.push(full_cap);
      lins.push(n_cycles);
      lins.push(cur_capacity);
      lins.push(cur_voltage);
      this.linesChartOptions.series = lins;

    }
    this.barChartOptions.series = bi;
    this.isDataLoaded = true;
    this.cdr.detectChanges();
  }

  lineChartOptions: Highcharts.Options = {
    chart: {
      zoomType: 'x'
    },
    title: {
      text: "Battery charge"
    },
    xAxis: {
      type: 'datetime'
    },
    legend: {
      enabled: false
    },
    yAxis: {
      allowDecimals: false,
      type: "linear",
      min: 0,
      max: 100,
      title: {
        text: "Charge, %"
      }
    },
    series: [
      {
        type: "line",
        name: "Battery",
        data: []
      }
    ],
    plotOptions: {
      area: {
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },
  };

  barChartOptions: Highcharts.Options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Battery working days'
    },
    xAxis: {
      categories: ['Batteries']
    },
    yAxis: {
      type: 'category'

    },
    legend: {
      reversed: true
    },

    plotOptions: {
      series: {
        cursor: 'pointer',
        stacking: 'normal',
        point: {
          events: {
            click: function () {
              alert('Category: ' + this.category + ', value: ' + this.y);
            }
          }
        }
      }
    }

  }

  linesChartOptions: Highcharts.Options = {

    title: {
      text: 'Special parameters'
    },

    subtitle: {
      text: 'Source: thesolarfoundation.com'
    },

    yAxis: {
      title: {
        text: 'Number of Employees'
      }

    },

    xAxis: {
      type: 'datetime'
      // accessibility: {
      //   rangeDescription: 'Range: 2010 to 2017'
      // }
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        pointStart: 2010
      }
    },

    series: [{
      type: 'line',
      name: 'Installation',
      data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
    }, {
      type: 'line',
      name: 'Manufacturing',
      data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
    }, {
      type: 'line',
      name: 'Sales & Distribution',
      data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
    }, {
      type: 'line',
      name: 'Project Development',
      data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
    }, {
      type: 'line',
      name: 'Other',
      data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }

  }


  xAxisGenerator(s: Date, e: Date): any {
    let xAxis = [];

    var start = new Date(Date.parse(s.toString()));
    var end = new Date(Date.parse(e.toString()));
    if (end.getFullYear() - start.getFullYear() >= 1) {
      for (let i = 2015; i <= end.getUTCFullYear(); i++) {
        xAxis.push(i);
      }
      return {avg: 1, xAxis};
    }
    if (end.getMonth() - start.getMonth() >= 1) {
      for (let i = start.getMonth(); i <= end.getMonth(); i++) {
        let m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        xAxis.push(m[i]);
      }
      return {avg: 2, xAxis};
    }

    if (end.getDate() - start.getDate() >= 1) {
      for (let i = start.getDate(); i <= end.getDate(); i++) {
        xAxis.push(i);
      }
      return {avg: 3, xAxis};
    }

    if (end.getHours() - start.getHours() >= 1) {
      for (let i = start.getHours(); i <= end.getHours(); i++) {
        xAxis.push(i);
      }
      return {avg: 4, xAxis};
    }
  }

}
