import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ErrorBar} from './error-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormErrorComponent} from './form-error.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from './error.interceptor';


@NgModule({
  declarations: [
    FormErrorComponent
    // ErrorBarComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSnackBarModule
  ],
  providers: [
    ErrorBar,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      deps: [ErrorBar],
      multi: true
    }
  ],
  exports: [
    FormErrorComponent
  ]
})
export class ErrorModule {
}
