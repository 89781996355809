import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {DeviceTypeFormComponent} from './device-type-form/device-type-form.component';
import {SideNav, Filter, TableDataSource, DevicesClient, CollarRevision, GetCollarRevisions} from 'shared';
import {NgrxBusy, withBusy} from 'ngrx-busy';

@Component({
  selector: 'app-device-types',
  templateUrl: './device-types.component.html',
  styleUrls: ['./device-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTypesComponent implements AfterViewInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(Filter) filter: Filter;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();
  private refresh$ = new BehaviorSubject(true);

  dataSource = new TableDataSource<CollarRevision>();
  loading$ = new BehaviorSubject(false);

  constructor(private devicesClient: DevicesClient,
              private sidenav: SideNav) {
    this.refresh$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.devicesClient.getCollarRevisions(new GetCollarRevisions())),
      tap(() => this.loading$.next(false)),
      withBusy(() => this.busy),
      takeUntil(this.unsubscribe$)
    ).subscribe(data => this.dataSource.data = data);
  }

  ngAfterViewInit(): void {
    this.dataSource.filter = this.filter;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, key, filter) => filter.split(' ')
      .map(search => search.trim().toLocaleLowerCase())
      .every(search => data.id.toLocaleLowerCase().indexOf(search) !== -1
        || data.description.toLocaleLowerCase().indexOf(search) !== -1);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async openForm(user?: CollarRevision): Promise<void> {
    const result = await this.sidenav.open(DeviceTypeFormComponent, {data: user})
      .beforeClosed().toPromise();
    if (!result) return;
    this.refresh$.next(true);
  }
}
