<div class="walk"
     [style]="walk.preview ? '--preview: url(' + walk.preview + ');' : null">

  <div class="hd">
    <img [src]="walk.preview || '/assets/images/preview_not_available.svg'"
         onerror="this.src='/assets/images/preview_not_available.svg';"/>
  </div>

  <div class="info">
    <span class="started">
      <zoned-time [time]="walk.started_at"
                  [zone]="walk.started_at_zone"
                  preferred="utc"
                  format="DD.MM LT"></zoned-time>&nbsp;
    </span>
    <span>{{walk.duration | moment:'HH:mm:ss'}},&nbsp;</span>
    <span>{{(walk.distance / 1000) | number:'1.0-1':'en'}} km,&nbsp;</span>
    <span>{{walk.steps | number:'1.0-0':'fr-FR'}} steps</span>
  </div>

  <img [src]="(walk.pet.avatar || '/assets/images/pet.png') + '?size=small&scale=2'"
       [matTooltip]="walk.pet.alias"
       onerror="this.src='/assets/images/pet.png';"
       class="pet"/>

  <img [src]="(walk.owner.avatar || '/assets/images/profile.jpg') + '?size=small&scale=2'"
       [matTooltip]="walk.owner.full_name"
       onerror="this.src='/assets/images/profile.png';"
       class="owner"/>
</div>
