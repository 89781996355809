<div class="dashboard">
  <ngrx-busy>
    <ng-container *ngIf="customer$ | async as customer">
      <a [routerLink]="['..', customer.id]"
         class="customer">
        <app-customer-card [customer]="customer"></app-customer-card>
      </a>

      <div class="pets">
        <div *ngFor="let ownerGr of pets$ | async | keyvalue">
          <span class="pet-owner">
            {{ownerGr.key.full_name}}
          </span>
          <ng-container *ngFor="let pet of ownerGr.value">
            <app-pet-card [pet]="pet"
                          [accepted]="pet.accepted"
                          matRipple
                          class="pet"
                          [routerLink]="['..', customer.id, 'pets', pet.id]"
                          [routerLinkActive]="['active']"></app-pet-card>
            <div class="shares">
              <a *ngFor="let share of pet.shares"
                    class="share"
                   [routerLink]="!!share.full_name ? ['..', share.id] : undefined">
                <img [src]="(share.avatar || '/assets/images/profile.jpg') + '?size=small&scale=2'"
                     class="avatar"
                     onerror="this.src='/assets/images/profile.jpg';"/>

                <div class="profile">
                  <span class="name">{{share.full_name || share.email}}</span>
                  <span [ngClass]="share.accepted ? 'level' : 'level invited'">{{share.accepted ? 'Shared' : 'Invited'}} as {{share.level | humanize:'share_levels'}}</span>
                </div>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ngrx-busy>
</div>

<div class="dashboard-outlet">
  <router-outlet #outlet></router-outlet>
</div>
