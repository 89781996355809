import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AchievementTypesComponent } from './achievement-types/achievement-types.component';
import {SharedModule} from '../../shared';
import {RouterModule} from '@angular/router';
import {BreedsComponent} from '../breeds/breeds.component';



@NgModule({
  declarations: [
    AchievementTypesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: AchievementTypesComponent
      }
    ])
  ]
})
export class AchievementsModule { }
