<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Breeds</h1>
    <app-headline-actions align="left">
      <button mat-icon-button
              (click)="openForm()">
        <mat-icon>add</mat-icon>
      </button>
    </app-headline-actions>
  </app-headline>

  <app-filter [columns]="['keywords']">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="dataSource"
             matSort
             matSortActive="create_date"
             matSortDirection="desc"
             matSortDisableClear>
        <ng-container matColumnDef="id">
          <th mat-header-cell
              *matHeaderCellDef>Breed id
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <app-code>{{row.id}}</app-code>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Name
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="height">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>Height
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.height}}
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell
              *matHeaderCellDef>
          </th>
          <td mat-cell
              *matCellDef="let row">
            <button mat-icon-button
                    (click)="openForm(row)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button
                    color="warn"
                    (click)="delete(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="['id', 'name', 'height', 'options']"></tr>
        <tr mat-row
            *matRowDef="let row; columns: ['id', 'name', 'height', 'options']"></tr>
      </table>
    </div>

    <mat-paginator [pageSize]="50"></mat-paginator>
  </ngrx-busy>
</mat-card>
