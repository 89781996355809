<div class="navigation">
  <div class="cards">
    <app-collar-card *ngIf="collar$ | async as collar"
                     [collar]="collar"></app-collar-card>

    <app-subscription-card *ngIf="pet$ | async as pet"
                           [pet]="pet"></app-subscription-card>

    <ng-container *ngIf="pet$ | async as pet">
      <app-activity-card *ngIf="collar$ | async as collar"
                         [collar]="collar"
                         [pet]="pet"></app-activity-card>
    </ng-container>
  </div>

  <div class="modes">
    <mat-button-toggle-group [formControl]="mode">
      <mat-button-toggle *ngFor="let mode of modes"
                         [value]="mode.key">{{mode.label}}</mat-button-toggle>
    </mat-button-toggle-group>

<!--    <button mat-button-->
<!--            color="accent"-->
<!--            matTooltip="Send test push"-->
<!--            (click)="sendPush()">-->
<!--      <mat-icon>notifications_active</mat-icon>-->
<!--    </button>-->

    <ng-container *ngFor="let template of toolbar.items">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
  </div>

  <div class="map">
    <ngrx-busy>
      <div id="map"></div>
    </ngrx-busy>

    <div class="walkers">
      <img *ngFor="let walker of walkers$ | async"
           [src]="(walker.avatar || '/assets/images/profile.jpg') + '?size=small&scale=2'"
           [matTooltip]="walker.full_name"
           onerror="this.src='/assets/images/profile.jpg';"
           class="walker"/>
    </div>
  </div>

  <app-tracks *ngIf="pet$ | async as pet"
              [pet]="pet"
              class="tracks"></app-tracks>
</div>

<router-outlet #outlet="outlet"></router-outlet>

<app-requests *ngIf="collar$ | async as collar"
              [device]="collar"
              class="requests"></app-requests>

<dl *ngIf="collar$ | async as collar" style="position: absolute; right: 0; top: 0; z-index: 1000; display: grid;grid-template-columns: min-content auto; grid-gap: 0.3rem; white-space: nowrap;">
  <dt>low temp alert</dt>
  <dd style="margin-inline-start: 1rem;">{{collar.lower_temp_alert}}</dd>
  <dt>upper temp alert</dt>
  <dd style="margin-inline-start: 1rem;">{{collar.upper_temp_alert}}</dd>
</dl>

<ng-template #petMarker
             let-pet="pet"
             let-color="color">
  <div>
    <img [src]="pet.avatar || '/assets/images/pet.png'"
         [ngStyle]="{
         'position': 'absolute',
         'z-index': 5,
         'height.px': 56,
         'width.px': 56,
         'border-radius.%': 50,
         'border': 'solid 3px ' + color,
         'background': color
         }" />
    <i [ngStyle]="{
      'display': 'block',
      'position': 'absolute',
      'bottom.px': 2,
      'left.px': 21,
      'height.px': 20,
      'width.px': 20,
      'border-radius.px': 3,
      'transform': 'rotate(-45deg)',
      '-webkit-transform': 'rotate(-45deg)',
      'background': color
      }"></i>
  </div>
</ng-template>
