<app-headline>
  <h1 app-headline-title
      color="primary">Walks</h1>
  <app-headline-actions align="right">
    <mat-paginator></mat-paginator>
  </app-headline-actions>
</app-headline>

<ngrx-busy>
  <div class="grid">
    <a *ngFor="let walk of walks$ | async"
       [routerLink]="['/customers', walk.owner.id, 'pets', walk.pet.id, 'tracks', walk.id]">
      <app-walk-card [walk]="walk"></app-walk-card>
    </a>
  </div>
</ngrx-busy>
