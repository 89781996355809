<ng-template #toolbar>
<!--  <mat-checkbox [(ngModel)]="showAllMarkers"-->
<!--                (ngModelChange)="showAllMarkersChanged()"-->
<!--                class="toolbar-option">-->
<!--    Show all markers-->
<!--  </mat-checkbox>-->

<!--  <mat-checkbox [formControl]="clustered"-->
<!--                class="toolbar-option">-->
<!--    Use cluster-->
<!--  </mat-checkbox>-->
</ng-template>

<ng-template #close>
  <a mat-flat-button
     class="mode-button close"
     [routerLink]="['../../']">
    <mat-icon>close</mat-icon>
  </a>
</ng-template>

<ngrx-busy class="info">
  <mat-card class="info-card">
    <ng-container *ngIf="trace$ | async as trace">
      <p><span>Started at</span>
        <zoned-time [time]="trace.started_at"
                    [zone]="trace.started_at_zone"></zoned-time>
      </p>
      <p><span>Ended at</span>
        <zoned-time [time]="trace.ended_at"
                    [zone]="trace.ended_at_zone"></zoned-time>
      </p>
      <p><span>Duration</span> <span>{{trace.duration | moment}}</span></p>
      <p><span>Distance</span> <span>{{(trace.distance / 1000) | number:'1.1-1':'en'}} km</span></p>
      <p><span>Calories</span>&nbsp;<span [innerText]="trace.calories"></span></p>
      <p><span>Steps</span>&nbsp;<span [innerText]="trace.steps"></span></p>
    </ng-container>
  </mat-card>

  <mat-card class="tabs-card">
    <mat-card-content class="outlined">
      <table mat-table
             *ngIf="trace$ | async as trace"
             [dataSource]="trace.history">
        <ng-container matColumnDef="type">
          <th mat-header-cell
              *matHeaderCellDef>Type
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.type | humanize:'safe_zone_types'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="period">
          <th mat-header-cell
              *matHeaderCellDef>Period
          </th>
          <td mat-cell
              *matCellDef="let row">
            <zoned-time [time]="row.timestamp"
                        [zone]="trace.started_at_zone"
                        format="LT"></zoned-time> [{{row.duration | moment}}]
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef>Name
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="steps">
          <th mat-header-cell
              *matHeaderCellDef>Steps
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.steps}}
          </td>
        </ng-container>
        <ng-container matColumnDef="distance">
          <th mat-header-cell
              *matHeaderCellDef>Distance
          </th>
          <td mat-cell
              *matCellDef="let row">
            <span>{{(row.distance / 1000) | number:'1.1-1':'en'}} km</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="calories">
          <th mat-header-cell
              *matHeaderCellDef>Calories
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.calories}}
          </td>
        </ng-container>
        <ng-container matColumnDef="run_time">
          <th mat-header-cell
              *matHeaderCellDef>Run time
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.run_time | moment}}
          </td>
        </ng-container>
        <ng-container matColumnDef="rest_time">
          <th mat-header-cell
              *matHeaderCellDef>Rest time
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.rest_time | moment}}
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="['period', 'type', 'name', 'steps', 'distance', 'calories', 'run_time', 'rest_time']"></tr>
        <tr mat-row
            *matRowDef="let row; columns: ['period', 'type', 'name', 'steps', 'distance', 'calories', 'run_time', 'rest_time']"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</ngrx-busy>

