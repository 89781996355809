<app-legend #legend
            style="display: none">
  <app-legend-item color="green"
                   [key]="BtpStatusCode.OK">Success
  </app-legend-item>
  <app-legend-item color="orange"
                   [key]="BtpStatusCode.BadRequest">Wrong format
  </app-legend-item>
  <app-legend-item color="red"
                   [key]="BtpStatusCode.InternalServerError">Internal error. Resending required
  </app-legend-item>
</app-legend>

<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Requests</h1>
    <app-headline-actions class="headline-filters">

      <button mat-icon-button
              matTooltip="Download requests as json">
        <mat-icon (click)="download()">download</mat-icon>
      </button>

      <mat-button-toggle-group [value]="view"
                               appearance="legacy"
                               (valueChange)="view = $event">
        <mat-button-toggle value="request">
          <mat-icon>north_east</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="response">
          <mat-icon>south_east</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <ng-container *ngTemplateOutlet="extraFilters"></ng-container>

    </app-headline-actions>
  </app-headline>

  <app-filter [columns]="['id', 'start_date_request', 'end_date_request', 'start_date_packet', 'end_date_packet', 'response_status', 'module_id', 'error_code', 'battery_id', 'parameters', 'packet_types', 'distinct_packets']"
              refreshControl="auto">

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Request ID</mat-label>
      <input matInput
             formControlName="id">
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Bin pack</mat-label>
      <mat-select formControlName="packet_types"
                  multiple>
        <mat-option *ngFor="let packetType of Dic.packet_types | keyvalue:comparePacket"
                    [value]="packetType.key">
          <small>[{{packetType.key | hex}}]</small> {{packetType.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Response status</mat-label>
      <mat-select formControlName="response_status">
        <mat-option></mat-option>
        <mat-option *ngFor="let status of Dic.response_data_statuses | keyvalue"
                    [value]="status.key">
          {{status.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Module</mat-label>
      <mat-select formControlName="module_id">
        <mat-option></mat-option>
        <mat-option *ngFor="let module of Dic.error_modules | keyvalue"
                    [value]="module.key">
          {{module.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Error code</mat-label>
      <mat-select formControlName="error_code">
        <mat-option></mat-option>
        <mat-option *ngFor="let error of errorCodes$ | async | keyvalue:compareCode"
                    [value]="error.key">
          <small>[{{error.key}}]</small> {{error.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Battery</mat-label>
      <input matInput
             formControlName="battery_id">
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Parameters</mat-label>
      <input matInput
             formControlName="parameters">
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Start date for request</mat-label>

      <mtx-datetimepicker #startDateForRequest
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="startDateForRequest"
             formControlName="start_date_request">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="startDateForRequest"></mtx-datetimepicker-toggle>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>End date for request</mat-label>

      <mtx-datetimepicker #endDateForRequest
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="endDateForRequest"
             formControlName="end_date_request">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="endDateForRequest"></mtx-datetimepicker-toggle>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Start date for packet</mat-label>

      <mtx-datetimepicker #startDateForPacket
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="startDateForPacket"
             formControlName="start_date_packet">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="startDateForPacket"></mtx-datetimepicker-toggle>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>End date for packet</mat-label>

      <mtx-datetimepicker #endDateForPacket
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="endDateForPacket"
             formControlName="end_date_packet">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="endDateForPacket"></mtx-datetimepicker-toggle>
    </mat-form-field>

    <mat-form-field appearance="outline" filter-control>
      <mat-select [formControl]="sortPackets">
        <mat-option value="position_up">Position ↑</mat-option>
        <mat-option value="position_down">Position ↓</mat-option>
        <mat-option value="timestamp_up">Timestamp ↑</mat-option>
        <mat-option value="timestamp_down">Timestamp ↓</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-template #extraFilters>
      <mat-checkbox formControlName="distinct_packets"
                    color="primary"
                    labelPosition="before">Distinct packets
      </mat-checkbox>
    </ng-template>
  </app-filter>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             multiTemplateDataRows
             [dataSource]="dataSource"
             matSort
             matSortActive="created_at"
             matSortDirection="desc"
             matSortDisableClear>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell
              mat-sort-header
              *matHeaderCellDef>Request
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <p>
              <strong><code class="protocol">{{row.protocol || '-'}}</code></strong>
              <span>
              <code class="elapsed">[{{row.elapsed}}ms]</code>
                {{row.created_at | utc | moment}}
            </span>
            </p>
            <p>
              <app-code>{{row.id}}</app-code>
              <span>
<!--              <a mat-icon-button-->
                <!--                 [routerLink]="['/requests', 'device', row.device_id]"-->
                <!--                 [filter]="{start_date_packet: row.min_timestamp, end_date_packet: row.max_timestamp}">-->
                <!--                  <mat-icon>perm_device_information</mat-icon>-->
                <!--              </a>-->
              <button mat-icon-button
                      (click)="details(row)">
                <mat-icon>pageview</mat-icon>
              </button>
            </span>
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th mat-header-cell
              *matHeaderCellDef>Packets
          </th>
          <td mat-cell
              *matCellDef="let row; let i = dataIndex;"
              [legendRow]="legend"
              [legendRowValue]="row.response_status">
            <app-packets [slice]="20"
                         [data]="view === 'request' ? row.request_packets : row.response_packets"></app-packets>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="['created_at', 'content']"></tr>
        <tr mat-row
            *matRowDef="let row; columns: ['created_at', 'content']"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[20, 500]"></mat-paginator>
  </ngrx-busy>
</mat-card>
