<div class="header">
  <div>
    <span class="os" [ngSwitch]="warn()">
      {{session.os}} <ng-container *ngIf="session.app_version">[{{session.app_version}}]</ng-container> ({{session.culture}})
      <mat-icon *ngSwitchCase="true"
                color="accent"
                matTooltip="Push token updated at {{session.updated_at | utc | moment:'medium'}}">info</mat-icon>
      <mat-icon *ngSwitchCase="false"
                color="primary"
                matTooltip="Push token updated at {{session.updated_at | utc | moment:'medium'}}">fiber_manual_record</mat-icon>
    </span>
    <span class="since">Since {{session.created_at | utc | moment:'medium'}}</span>
  </div>
  <div class="ip"><span class="caption">IP:</span> {{session.last_ip}}</div>
</div>
