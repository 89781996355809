<mat-card>
  <app-headline>
    <h1 app-headline-title>User roles</h1>
    <app-headline-actions align="left">
      <button mat-icon-button
              (click)="openForm()">
        <mat-icon>add</mat-icon>
      </button>
    </app-headline-actions>
  </app-headline>

  <ngrx-busy>
    <table mat-table
           [dataSource]="dataSource"
           matSort
           matSortActive="create_date"
           matSortDirection="desc"
           matSortDisableClear>
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Name
        </th>
        <td mat-cell
            *matCellDef="let row">{{row.name}}</td>
      </ng-container>
      <ng-container matColumnDef="scopes">
        <th mat-header-cell
            *matHeaderCellDef>Scopes
        </th>
        <td mat-cell
            *matCellDef="let row">{{row.scopes}}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let row">
          <button mat-icon-button
                  color="warn"
                  (click)="remove(row)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button
                  (click)="openForm(row)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row
          *matHeaderRowDef="['name', 'scopes', 'options']"></tr>
      <tr mat-row
          *matRowDef="let row; columns: ['name', 'scopes', 'options']"></tr>
    </table>
  </ngrx-busy>
</mat-card>

