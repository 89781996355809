<ngrx-busy>
  <form [formGroup]="form"
        (ngSubmit)="save()"
        class="side-form">

    <h3>{{mode === EditMode.Edit ? 'Update' : 'Create'}} firmware</h3>

    <ng-container *ngIf="mode === EditMode.Create">
      <mat-form-field appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type"
                    required>
          <mat-option *ngFor="let type of types$ | async"
                      [value]="type.id">
            {{type.id}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('type').hasError('required')">
          Type is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill"
                      style="cursor: pointer;"
                      (click)="fileInput.click()">
        <mat-label>File</mat-label>
        <input #fakeInput
               matInput
               [value]="selectedFile"
               readonly="true">
        <input #fileInput
               hidden
               (change)="setFile(fileInput.files[0])"
               type="file">
        <mat-icon matSuffix>attach_file</mat-icon>
        <mat-error *ngIf="form.get('file').hasError('required')">
          File is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="mode === EditMode.Edit">

      <mat-form-field appearance="fill">
        <mat-label>EN</mat-label>
        <textarea matInput
                  formControlName="description_en"
                  cdkTextareaAutosize
                  required></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>RU</mat-label>
        <textarea matInput
                  formControlName="description_ru"
                  cdkTextareaAutosize
                  required></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Note</mat-label>
        <textarea matInput
                  formControlName="note"
                  cdkTextareaAutosize
                  required></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Percent for testing</mat-label>
        <input matInput
               formControlName="percent_for_testing"
               [imask]="{mask: Number, min: 0, max: 100}">
      </mat-form-field>
    </ng-container>

    <div class="side-form-actions">
      <button mat-raised-button
              type="submit"
              color="primary"
              [disabled]="form.invalid">
        {{mode === EditMode.Edit ? 'Update' : 'Create'}}
      </button>
    </div>

    <app-form-error></app-form-error>
  </form>
</ngrx-busy>
