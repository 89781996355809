<ngrx-busy>
  <div class="safe-zone">
    <div class="hd">
      <img [src]="safeZone.preview"
           class="preview"/>
    </div>

    <div class="info">
      <span class="title">{{safeZone.title}}</span>
      <span class="address">{{safeZone.address}}</span>
      <mat-divider></mat-divider>

      <div class="points">
        <mat-icon>wifi</mat-icon>
        {{safeZone.access_points.length}} Wi-Fi points
        <ul>
          <li *ngFor="let wifi of safeZone.access_points">
            <i class="wifi-state"
               [ngClass]="{'wifi-ok': wifi.connection_status === AccessPointConnectionStatus.Successful, 'wifi-nc': wifi.connection_status === AccessPointConnectionStatus.NotConnectedYet}"></i>
            {{wifi.ssid}}
            <span *ngIf="wifi.connection_status !== AccessPointConnectionStatus.Successful"
                  class="wifi-status">[{{wifi.connection_status | humanize:'wi_fi_states' | lowercase}}]</span>
          </li>
        </ul>
      </div>

      <mat-divider></mat-divider>

      <div class="points">
        <mat-icon>wifi_tethering</mat-icon>
        {{safeZone.ble_beacons.length}} beacons
        <ul>
          <li *ngFor="let beacon of safeZone.ble_beacons">
            <app-charge [value]="beacon.battery_charge"></app-charge>
            {{beacon.name}}
          </li>
        </ul>
      </div>
    </div>

    <div class="pets">
      <img *ngFor="let pet of safeZone.pets"
           [src]="(pet.avatar || '/assets/images/pet.png') + '?size=small&scale=2'"
           [matTooltip]="pet.alias"
           onerror="this.src='/assets/images/pet.png';"
           class="pet"/>
    </div>
  </div>
</ngrx-busy>
