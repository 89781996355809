import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {DashboardClient, FirmwareVersionStat, GetFirmwareStats, TableDataSource} from 'shared';
import {Observable, Subject} from 'rxjs';
import {NgrxBusy, withBusy} from "ngrx-busy";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-firmware-stats',
  templateUrl: './firmware-stats.component.html',
  styleUrls: ['./firmware-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirmwareStatsComponent {

  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();
  stats$: Observable<Stat[]>;

  constructor(private dashboardClient: DashboardClient) {
  }

  ngOnInit(): void {
    this.stats$ = this.dashboardClient.getFirmwareStats(new GetFirmwareStats())
      .pipe(
        withBusy(() => this.busy),
        map(stats => stats.map(stat => <Stat>{
          revision: stat.revision,
          total: stat.total,
          versions: new TableDataSource<FirmwareVersionStat>(stat.versions)
        }))
      )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}

interface Stat {
  revision: string;
  total: number;
  versions: TableDataSource<FirmwareVersionStat>;
}
