import {ChangeDetectorRef, Injectable} from '@angular/core';

import {SideNavComponent} from './sidenav.component';

@Injectable()
export class SideNavService {

  private _cdr: ChangeDetectorRef;
  private container: SideNavComponent | undefined;

  get instance(): SideNavComponent | undefined {
    return this.container;
  }

  get cdr(): ChangeDetectorRef | undefined {
    return this._cdr;
  }

  register(component: SideNavComponent, cdr: ChangeDetectorRef): void {
    this.container = component;
    this._cdr = cdr;
  }
}
