import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'averia-legend'},
  encapsulation: ViewEncapsulation.None
})
export class LegendComponent {
  private colors = new Map<any, string>();

  getColor(key: any): string | null {
    return this.colors.get(key);
  }

  registerItem(keys: any[], color): void {
    if (!keys) return;
    keys.forEach(key => this.colors.set(key, color));
  }
}

@Component({
  selector: 'app-legend-item',
  template: `<ng-content></ng-content>`,
  host: {class: 'averia-legend-item'},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendItemComponent {

  @Input()
  get color(): string {
    return this._color;
  }

  set color(value: string) {
    if (value !== this._color) {
      if (this._color) {
        this.elementRef.nativeElement.classList.remove(`averia-legend-item-${this._color}`);
      }
      if (value) {
        this.elementRef.nativeElement.classList.add(`averia-legend-item-${value}`);
      }

      this._color = value;
    }
    this.register();
  }

  private _color: string;

  @Input()
  set key(value: any | undefined) {
    this._keys = [value];
    this.register();
  }

  @Input()
  get keys(): any[] {
    return this._keys;
  }

  set keys(value: any[]) {
    this._keys = value;
    this.register();
  }

  private _keys: any[];


  constructor(private elementRef: ElementRef, private legend: LegendComponent) {
  }

  private register() {
    this.legend.registerItem(this.keys, this.color);
  }
}

declare type LegendRowPosition = 'top' | 'left';

@Directive({
  selector: '[legendRow]',
  host: {class: 'averia-legend-row'}
})
export class LegendRowDirective implements OnChanges {

  private _color: string;

  @Input('legendRow') source: LegendComponent;
  @Input('legendRowValue') value: any;

  @Input('legendRowPosition')
  get position(): LegendRowPosition {
    return this._position;
  }

  set position(value: LegendRowPosition) {
    if (value !== this._position) {
      if (this._position) {
        this.elementRef.nativeElement.classList.remove(`averia-legend-row-${this._position}`);
      }
      if (value) {
        this.elementRef.nativeElement.classList.add(`averia-legend-row-${value}`);
      }

      this._position = value;
    }
  }

  private _position: LegendRowPosition;

  constructor(private elementRef: ElementRef) {
    this.position = 'left';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.source) return;
    if (this.value == undefined) return;

    const color = this.source.getColor(this.value);

    if (color !== this._color) {
      if (this._color) {
        this.elementRef.nativeElement.classList.remove(`averia-legend-row-${this._color}`);
      }
      if (color) {
        this.elementRef.nativeElement.classList.add(`averia-legend-row-${color}`);
      }

      this._color = color;
    }
  }
}
