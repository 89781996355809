import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-headline, [app-headline]',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'averia-headline'},
  encapsulation: ViewEncapsulation.None
})
export class HeadlineComponent {
}
