export class EqMap<K, V> extends Map<K, V> {
  constructor(private readonly comparer: (x: K, y: K) => boolean) {
    super();
    // this._map = new Map<K, V>();
  }

  delete(key: K): boolean {
    const keys = this.keys();
    while (true) {
      const res = keys.next();
      if (res.done) return false;
      if (this.comparer(res.value, key)) return super.delete(res.value);
    }
  }

  get(key: K): V | undefined {
    if (!this.has(key)) return undefined;

    const keys = this.keys();
    while (true) {
      const res = keys.next();
      if (res.done) return undefined;
      if (this.comparer(res.value, key)) return super.get(res.value);
    }
  }

  has(key: K): boolean {
    const keys = this.keys();
    while (true) {
      const res = keys.next();
      if (res.done) return false;
      if (this.comparer(res.value, key)) return true;
    }
  }

  set(key: K, value: V) : this {
    const keys = this.keys();
    while (true) {
      const res = keys.next();
      if (res.done) {
        super.set(key, value);
        break;
      }
      if (this.comparer(res.value, key)) {
        super.set(res.value, value);
        break;
      }
    }

    return this;
  }
}
