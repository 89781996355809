import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SIDE_NAV_DATA, SideNavRef} from 'shared/sidenav';
import {
  EditMode,
  AddCollarRevision,
  DevicesClient,
  CollarRevision,
  UpdateCollarRevision,
  UserPartialResponse, UsersClient, GetUsersQuery
} from 'shared';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-device-type-form',
  templateUrl: './device-type-form.component.html',
  styleUrls: ['./device-type-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceTypeFormComponent {

  EditMode = EditMode;

  mode: EditMode;
  form: UntypedFormGroup;

  reviewers$: Observable<UserPartialResponse[]>;

  constructor(
    private readonly sideNavRef: SideNavRef<DeviceTypeFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private devicesClient: DevicesClient,
    private usersClient: UsersClient,
    @Inject(SIDE_NAV_DATA) public deviceType?: CollarRevision
  ) {
    this.mode = deviceType ? EditMode.Edit : EditMode.Create;
    this.form = this.formBuilder.group({
      id: [deviceType ? {value: deviceType.id, disabled: true} : '', Validators.required],
      description: [deviceType ? deviceType.description : '', Validators.required],
      storage_size: [deviceType ? {value: '', disabled: true} : '', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1)]],
      reviewers: [deviceType && deviceType.reviewers ? deviceType.reviewers.map(r => r.id) : []]
    });

    this.reviewers$ = this.usersClient.getUsers(new GetUsersQuery());
  }

  async save(): Promise<void> {
    if (!this.form.valid) return;

    const command = this.form.getRawValue();
    try {
      this.mode == EditMode.Create
        ? await this.devicesClient.addCollarRevision(new AddCollarRevision(command)).toPromise()
        : await this.devicesClient.updateCollarRevision(new UpdateCollarRevision({id: this.deviceType.id, ...command})).toPromise();
      this.sideNavRef.close(true);
    } catch (error) {
      this.form.setErrors(error);
    }
  }
}
