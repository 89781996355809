import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CodeComponent} from 'shared/presentation/code.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PhonePipe} from "./phone.pipe";


@NgModule({
  declarations: [CodeComponent, PhonePipe],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    CodeComponent,
    PhonePipe
  ]
})
export class PresentationModule {
}
