<ng-container [ngSwitch]="appearance">
  <code *ngSwitchCase="'text'"
        #container
        (click)="copy()">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </code>
  <ng-container *ngSwitchCase="'button'">
    <button matTooltip
            #tooltip="matTooltip"
            matTooltipPosition="right"
            mat-icon-button
            (click)="copy(); $event.stopImmediatePropagation()">
      <mat-icon>file_copy</mat-icon>
    </button>
    <code #container style="position: absolute; font-size: 0; z-index: -1000">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </code>
  </ng-container>
</ng-container>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
