import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ConnectedPosition} from '@angular/cdk/overlay';
import {ObservableInput} from 'ngx-observable-input';
import {firstValueFrom, Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {deviceModeShade} from '../utils';
import {Collar, DeviceError, DeviceUserMode, FlashlightStatus} from '../public-clients';
import {
  DevicesClient,
  GetCollars,
  Collar as PrivateCollar,
  GetCustomerBatteries
} from 'shared';
import {MatDialog} from "@angular/material/dialog";
import {CollarBatteriesComponent} from "./collar-batteries.component";

@Component({
  selector: 'app-collar-card',
  templateUrl: './collar-card.component.html',
  styleUrls: ['./collar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollarCardComponent {
  @ObservableInput() @Input('collar') collar$: Observable<Collar>;
  private$: Observable<PrivateCollar>;

  DeviceUserMode = DeviceUserMode;

  FlashlightStatus = FlashlightStatus;

  _positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom'
    }
  ];

  deviceModeShade = deviceModeShade;

  constructor(private devicesClient: DevicesClient,
              private dialog: MatDialog) {
    this.private$ = this.collar$.pipe(
      map(collar => collar.id),
      distinctUntilChanged(),
      switchMap(id => this.devicesClient.getCollars(new GetCollars({keywords: id}))),
      map(paged => paged.items[0])
    )
  }

  async showBatteries(): Promise<void> {
    let collarId = await firstValueFrom(this.collar$.pipe(map(collar => collar.id)));
    const batteries = await firstValueFrom(this.devicesClient.getCustomerBatteries(new GetCustomerBatteries({collar_id: collarId})));
    this.dialog.open(CollarBatteriesComponent, {data: batteries});
  }

  // async showFlashlights(): Promise<void> {
  //   let collarId = await firstValueFrom(this.collar$.pipe(map(collar => collar.id)));
  //   this.requestsClient.getRequests(new GetRequests({device_id: collarId, packet_types: [80], page_size: 500}));
  //   const batteries = await firstValueFrom(this.devicesClient.getCustomerBatteries(new GetCustomerBatteries({collar_id: collarId})));
  //   this.dialog.open(CollarBatteriesComponent, {data: batteries});
  // }

  getError(errors: DeviceError) {
    if (errors == 0) return '';
    let sb = [];
    if (errors & DeviceError.FlashMemoryError) sb.push('Flash memory error');
    if (errors & DeviceError.BadBatteryConnection) sb.push('Bad battery connection');
    if (errors & DeviceError.GpsJamming) sb.push('GPS jamming');
    if (errors & DeviceError.GsmJamming) sb.push('JSM jamming');
    if (errors & DeviceError.IMUError) sb.push('Accelerometer error');
    return sb.join(', ');
  }
}
