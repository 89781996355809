import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthManager} from 'shared';
import {iif, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {NgrxBusy} from 'ngrx-busy';
import {registerLocaleData} from '@angular/common';
import fr from '@angular/common/locales/fr';

type AppState = 'guest' | 'outdated' | 'unreachable' | 'reachable';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  @ViewChild('updateBusy') busy: NgrxBusy;

  state$: Observable<AppState>;

  constructor(
    private icons: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authManager: AuthManager
  ) {
    this.icons.addSvgIcon('user-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/user-black.svg'));
    this.icons.addSvgIcon('walks-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/walks-black.svg'));
    this.icons.addSvgIcon('sound_detection_dog_barking', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sound_detection_dog_barking.svg'));
    this.icons.addSvgIcon('trophy', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trophy.svg'));
    this.icons.addSvgIcon('requests', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/requests.svg'));
    this.icons.addSvgIcon('pets-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pets-black.svg'));
    this.icons.addSvgIcon('firmwares-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/firmware-black.svg'));
    this.icons.addSvgIcon('achievements', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/achievements.svg'));
    this.icons.addSvgIcon('devices-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/devices-black.svg'));
    this.icons.addSvgIcon('dashboard-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/dashboard-black.svg'));
    this.icons.addSvgIcon('reports-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/reports.svg'));
    this.icons.addSvgIcon('subscription-plans-black', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/subscription-plans-black.svg'));
    this.icons.addSvgIcon('collar', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/collar.svg'));
    this.icons.addSvgIcon('log', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/log.svg'));
    this.icons.addSvgIcon('safe-zone', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/safe-zone.svg'));

    this.state$ = this.authManager.loggedIn$.pipe(
      mergeMap(loggedIn => iif(() => !loggedIn,
        of(<AppState> 'guest'),
        of(<AppState> 'reachable')
        ))
    );

    registerLocaleData(fr);
  }
}
