<mat-tab-group *ngIf="request$ | async as request">
  <mat-tab label="Info">
    <mat-dialog-content>
      <p class="info-row">
        <span class="mat-body-strong">Id</span>
        <app-code appearance="text">{{request.id}}</app-code>
      </p>
      <p class="info-row">
        <span class="mat-body-strong">Device id</span>
        <app-code appearance="text">{{request.device_id}}</app-code>
      </p>
      <p class="info-row">
        <span class="mat-body-strong">IP</span>
        <app-code appearance="text">{{request.remote_ip}}</app-code>
      </p>
      <p class="info-row">
        <span class="mat-body-strong">Create date</span>
        {{request.created_at | utc | moment}}
      </p>
      <p class="info-row">
        <span class="mat-body-strong">Response status</span>
        {{request.response_status | humanize:'response_data_statuses'}}
      </p>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="Request headers">
    <mat-dialog-content>
      <ngx-json-viewer [json]="requestHeaders$ | async" expanded="true"></ngx-json-viewer>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="Parsed request">
    <mat-dialog-content>
      <app-packets [data]="parsedRequestPackets$ | async"></app-packets>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="Request">
    <mat-dialog-content>
      <pre class="hex">{{request.request_hex | strInsert:2:' '}}</pre>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="Response headers">
    <mat-dialog-content>
      <ngx-json-viewer [json]="responseHeaders$ | async" expanded="true"></ngx-json-viewer>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="Parsed response">
    <mat-dialog-content>
      <app-packets [data]="parsedResponsePackets$ | async"
                   [excludeTimestamp]="true"></app-packets>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="Response">
    <mat-dialog-content>
      <pre class="hex">{{request.response_hex | strInsert:2:' '}}</pre>
    </mat-dialog-content>
  </mat-tab>
</mat-tab-group>
