import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {combineLatest, firstValueFrom, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {NgrxBusy} from 'ngrx-busy';
import {
  Filter,
  GetRequests,
  IGetRequests,
  IRequestSnapshot,
  ObservedDataSource,
  PagedResponse,
  RequestPacket,
  RequestsClient,
  BtpStatusCode,
  SortOrder,
  RequestSnapshot,
  dic
} from 'shared';
import {MatSort} from '@angular/material/sort';
import * as moment from 'moment';
import {Moment} from 'moment';
// import {LocalStorage} from "ngx-store";
import {ObservableInput} from 'ngx-observable-input';
import {MatDialog} from '@angular/material/dialog';
import {RequestDetailComponent} from './request-detail/request-detail.component';
import {KeyValue} from '@angular/common';
import {Collar} from '../customers/public-clients';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ObservableInput() @Input('device') device$: Observable<Collar>;

  private unsubscribe$: Subject<any> = new Subject();

  BtpStatusCode = BtpStatusCode;

  dataSource: RequestsDataSource;
  Dic = dic;

  errorCodes$: Observable<{ [key: number]: string; }>;

  sortPackets = new UntypedFormControl('position_up');

  view: 'request' | 'response' = 'request';

  constructor(
    private requestsClient: RequestsClient,
    private dialog: MatDialog
  ) {
    const sort = this.sortPackets.valueChanges.pipe(
      startWith(this.sortPackets.value),
      map((s: string) => s.split('_')),
      map(([s, order]) => ({
        sort_packets_by: s,
        sort_packets_order: order === 'down' ? SortOrder.Descending : SortOrder.Ascending
      }))
    );

    this.dataSource = new RequestsDataSource(requestsClient, sort, this.device$);
    // this.responseStatuses$ = this.serverDictionary.set$.pipe(map(set => set.response_data_statuses));
    // this.errorModules$ = this.serverDictionary.set$.pipe(map(set => set.error_modules));
    // this.packetTypes$ = this.serverDictionary.set$.pipe(map(set => set.packet_types));
  }

  ngOnInit(): void {
    this.filter.resetOnChange.set('module_id', 'error_code');
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.filter;
    this.dataSource.sort = this.sort;
    this.dataSource.busy = this.busy;

    this.errorCodes$ = this.filter.filterChange.pipe(
      map(f => f.module_id),
      distinctUntilChanged(),
      map(moduleId => dic.error_codes[moduleId])
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  parsedAccessor(packet: RequestPacket, name: string): any {
    return packet.parsed[name];
  }

  details(request: RequestSnapshot): void {
    this.dialog.open(RequestDetailComponent, {data: request});
  }

  comparePacket(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return parseInt(a.key as any, 10) - parseInt(b.key as any, 10);
  }

  compareCode(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return parseInt(a.key as any, 10) - parseInt(b.key as any, 10);
  }

  async download(): Promise<void> {
    let collar = await firstValueFrom(this.device$);
    let element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(this.dataSource.json));
    element.setAttribute('download', `requests-${collar.id}.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}

interface IRequestModel extends IRequestSnapshot {
  min_timestamp: Moment;
  max_timestamp: Moment;
}

class RequestsDataSource extends ObservedDataSource<IRequestModel, IGetRequests> {
  json: string;

  constructor(
    private requestsClient: RequestsClient,
    private sort$: Observable<{ sort_packets_by: string; sort_packets_order: SortOrder }>,
    private device$: Observable<Collar>) {
    super(request => request.id);
  }

  source(query: Partial<IGetRequests>): Observable<PagedResponse<IRequestModel>> {
    return combineLatest([this.sort$, this.device$.pipe(distinctUntilChanged((prev, cur) => prev.id == cur.id))]).pipe(
      filter(([sort, device]) => !!device),
      switchMap(([sort, device]) => this.requestsClient.getRequests(new GetRequests({...query, ...sort, device_id: device.id}))),
      tap(paged => this.json = JSON.stringify(paged.toJSON().items)),
      map(paged => ({
        ...paged, items: paged.items.map(item => {
          const timestamps = item.request_packets
            ? item.request_packets.map(packet => packet.parsed.timestamp).filter(ts => !!ts)
            : [item.created_at.unix()];
          return <IRequestModel> ({
            ...item,
            min_timestamp: moment.unix(Math.min(...timestamps)).utc(),
            max_timestamp: moment.unix(Math.max(...timestamps)).utc()
          });
        })
      }))
    );
  }
}
