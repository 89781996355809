import {Directive, OnInit, Output, SimpleChange} from "@angular/core";
import {BehaviorSubject, fromEvent, merge, Subject} from "rxjs";
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {map, takeUntil} from "rxjs/operators";

@Directive({
  selector: '[cdkConnectedOverlay][cdkConnectedOverlayHover]'
})
export class OverlayHoverDirective implements OnInit {
  @Output() hovered = new BehaviorSubject(false);

  private unsubscribe$: Subject<any> = new Subject();

  constructor(private connected: CdkConnectedOverlay) {
  }

  ngOnInit(): void {
    if (!(this.connected.origin instanceof CdkOverlayOrigin)) return;
    const el = this.connected.origin.elementRef.nativeElement;
    merge(
      fromEvent(el, 'mouseenter').pipe(map(() => true)),
      fromEvent(el, 'mouseout').pipe(map(() => false))
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(open => {
      const prev = this.connected.open;
      this.connected.open = open;
      this.connected.ngOnChanges({'open': new SimpleChange(prev, open, false)})
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
