<ngrx-busy>
  <form [formGroup]="form"
        (ngSubmit)="save()"
        class="side-form">

    <h3>Update subscription plan</h3>

    <mat-form-field appearance="fill">
      <mat-label>Languages</mat-label>
      <mat-select formControlName="lang"
                  (selectionChange)="langChanged($event.value)">
        <mat-option *ngFor="let lang of supportedLanguages"
                    [value]="lang">
          {{lang}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput
             formControlName="name"
             required>
      <mat-error *ngIf="form.get('name').hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <textarea matInput
                rows="3"
                formControlName="description"
                required>
      </textarea>
      <mat-error *ngIf="form.get('description').hasError('required')">
        Description is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Promo label</mat-label>
      <textarea matInput
                rows="3"
                formControlName="promo_label"
                required>
      </textarea>
      <mat-error *ngIf="form.get('promo_label').hasError('required')">
        Promo label <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <div class="side-form-actions">
      <button mat-raised-button
              type="submit"
              color="primary"
              [disabled]="form.invalid">
        Update
      </button>
    </div>

    <app-form-error></app-form-error>
  </form>
</ngrx-busy>
