<!--<mat-card>-->
<!--  <app-headline>-->
<!--    <h1 app-headline-title-->
<!--        color="primary">Achievement types</h1>-->
<!--  </app-headline>-->
<!--  <mat-card-content class="outlined">-->
<!--    <ngrx-busy>-->
<!--      <div class="ach-types">-->
<!--        <div *ngFor="let achievementType of $achievementTypes | async" class="ach-type">-->
<!--          <div class="id-label"><app-code>{{achievementType.id}}</app-code>{{achievementType.id}}</div>-->
<!--          <img src="{{achievementType.picture_url}}"-->
<!--               (click)="swapPictures(achievementType)"-->
<!--               [ngClass]="{'main-picture': achievementType.inactive_picture_url, 'non-clickable': !achievementType.inactive_picture_url}"-->
<!--               class="picture" />-->
<!--          <img *ngIf="achievementType.inactive_picture_url"-->
<!--               src="{{achievementType.inactive_picture_url}}"-->
<!--               (click)="swapPictures(achievementType.description)"-->
<!--               class="picture inactive-picture" />-->
<!--          <h2>{{achievementType.header}}</h2>-->
<!--          <div class="description">{{achievementType.description}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </ngrx-busy>-->
<!--  </mat-card-content>-->
<!--</mat-card>-->

<app-headline>
  <h1 app-headline-title
      color="primary">Achievement types</h1>
</app-headline>

<div class="ach-types">
  <mat-card *ngFor="let achievementType of $achievementTypes | async">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>{{achievementType.header}}</mat-card-title>
        <mat-card-subtitle><app-code>{{achievementType.id}}</app-code> {{achievementType.id}}</mat-card-subtitle>
        <div class="picture">
          <img class="picture_url" mat-card-md-image [src]="achievementType.picture_url">
          <img class="inactive_picture_url" mat-card-md-image [src]="achievementType.inactive_picture_url">
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      {{achievementType.description}}
    </mat-card-content>
  </mat-card>
</div>
