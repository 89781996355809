import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {filter, map, share, switchMap, tap} from 'rxjs/operators';
import {Customer, CustomersClient, GetSessions, Session} from 'shared';
import {ActivatedRoute} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import * as moment from 'moment-timezone';
import {Feed, NotificationSettings, SafeZone, UserPublicClient} from '../public-clients';
import {CustomerStore} from '../customer.store';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerDetailsComponent {
  @ViewChild('szBusy', {static: true}) szBusy: NgrxBusy;
  @ViewChild('feedBusy', {static: true}) feedBusy: NgrxBusy;
  @ViewChild('sessionsBusy', {static: true}) sessionsBusy: NgrxBusy;

  safeZones$: Observable<SafeZone[]>;
  feeds$: Observable<Feed[]>;
  sessions$: Observable<Session[]>;
  customer$: Observable<Customer>;
  notificationSettings$: Observable<NotificationSettings>;

  constructor(private route: ActivatedRoute,
              private customersClient: CustomersClient,
              private store: CustomerStore,
              private userPublicClient: UserPublicClient) {
    this.safeZones$ = this.store.safeZones$;
    this.notificationSettings$ = this.store.profile$.pipe(
      filter(profile => !!profile),
      map(profile => profile.notification_settings)
    );

    this.sessions$ = route.params.pipe(
      switchMap(params => customersClient.getSessions(new GetSessions({customer_id: params.id}))),
      withBusy(() => this.sessionsBusy),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false
      })
    );

    this.customer$ = this.store.private$;

    this.feeds$ = this.userPublicClient.getFeed(null, null, null, null, null, 1000).pipe(
      map(paged => paged.items),
      tap(items => items.forEach(item => item.timestamp = moment(item.timestamp))),
      withBusy(() => this.feedBusy),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false
      })
    );
  }
}
