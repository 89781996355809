<mat-dialog-content>
  <ng-container *ngFor="let battery of batteries; let i = index">
    <mat-divider *ngIf="i != 0"></mat-divider>
    <dl>
      <dt>ID</dt>
      <dd>{{battery.id}}</dd>
      <dt>Serial</dt>
      <dd>{{battery.serial}}</dd>
    </dl>
  </ng-container>
</mat-dialog-content>
