<ng-container *ngIf="conv$ | async as conv">
  <span cdkOverlayOrigin
        #origin="cdkOverlayOrigin"
        class="origin"
        [innerHTML]="(conv[preferred] || conv.utc) | moment:format"></span>

  <ng-template cdkConnectedOverlay
               [cdkConnectedOverlayOrigin]="origin"
               [cdkConnectedOverlayOpen]="showOverlay$ | async">
    <div class="zoned-tooltip">
      <table class="conversions">
        <tr>
          <th class="zoned-tooltip-head"
              colspan="3">Time conversion
          </th>
        </tr>
        <tr *ngIf="conv.local">
          <td class="zone">{{conv.local | moment:'z'}} • Local</td>
          <td class="time">{{conv.local | moment:'LT'}}</td>
          <td class="date">{{conv.local | moment: 'MMM D, YYYY'}}</td>
        </tr>
        <tr>
          <td class="zone">{{conv.utc | moment:'z'}}</td>
          <td class="time">{{conv.utc | moment:'LT'}}</td>
          <td class="date">{{conv.utc | moment: 'MMM D, YYYY'}}</td>
        </tr>
        <tr>
          <td class="zone">{{conv.guess | moment:'z'}} • Your computer</td>
          <td class="time">{{conv.guess | moment:'LT'}}</td>
          <td class="date">{{conv.guess | moment: 'MMM D, YYYY'}}</td>
        </tr>
      </table>
    </div>
  </ng-template>
</ng-container>
