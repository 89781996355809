import {ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {Collar, DevicesClient, GetSimInfo} from "shared";
import {NgrxBusy, withBusy} from "ngrx-busy";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-sim-detail',
  templateUrl: './sim-detail.component.html',
  styleUrls: ['./sim-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimDetailComponent {

  @ViewChild(NgrxBusy) busy: NgrxBusy;

  sim$: Observable<ReadonlyMap<string, string>>;

  constructor(@Inject(MAT_DIALOG_DATA) public device: Collar,
              private devicesClient: DevicesClient) {
    this.sim$ = this.devicesClient.getSimInfo(new GetSimInfo({collar_id: device.id})).pipe(
      map(sim => new Map<string, string>(Object.entries(sim))),
      withBusy(() => this.busy)
    );
  }
}
