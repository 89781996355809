import {AfterViewInit, ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import * as moment from 'moment';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {EMPTY, first, map, Observable} from 'rxjs';
import {
  AuthManager,
  Filter,
  ObservedDataSource,
  PagedResponse,
  ProfileResponse,
  SideNav,
  SystemDialog,
  WarningConfirm
} from 'shared';
import {AuthScopes} from 'shared/auth-scopes';
import {Expand2, Order, OrderClient, OrderStatus, OrderType, UserAddress} from '../clients-store';
import {OrderDetailsComponent} from './order-details/order-details.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrdersComponent implements AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(Filter) filter: Filter;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(NgrxBusy) busy: NgrxBusy;

  moment = moment;
  status: number;
  // tslint:disable-next-line:variable-name
  start_date: Date;
  // tslint:disable-next-line:variable-name
  end_date: Date;
  statusKeys = Object.keys(OrderStatus).filter((v) => !isNaN(Number(v)));
  statusMapping = OrderStatusMapping;
  dataSource: OrdersDataSource;
  columns = ['id', 'status', 'create_date', 'email', 'tracking_number', 'total_amount', 'shipping_address', 'options'];
  profile$: Observable<ProfileResponse>;

  constructor(
    private orderClient: OrderClient,
    private sidenav: SideNav,
    private systemDialog: SystemDialog,
    private authManager: AuthManager
  ) {
    this.profile$ = authManager.profile$.pipe(first());
  }

  ngAfterViewInit(): void {
    this.dataSource = new OrdersDataSource(this.orderClient);
    this.dataSource.filter = this.filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
  }

  async showOrderDetails(order: Order): Promise<void> {
    const result = await this.sidenav.open(OrderDetailsComponent, {data: order});
    if (!result) { return; }
  }

  async approveOrder(order: Order): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to approve order, id = ${order.id}?`, WarningConfirm);
    if (!isOk) { return; }

    this.orderClient.approve(order.id, order.revision).pipe(withBusy(() => this.busy)).subscribe({next: updated => {
        if (updated.status != OrderStatus._2) return;
        this.dataSource.refresh();
        this.systemDialog.alert('Order has been successfully approved.');
      }});
  }

  async cancelOrder(order: Order): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to cancel order, id = ${order.id}?`, WarningConfirm);
    if (!isOk) { return; }

    this.orderClient.refund(order.id, order.revision).pipe(withBusy(() => this.busy)).subscribe({next: () => {
        this.dataSource.refresh();
        this.systemDialog.alert('Order has been successfully cancelled.');
      }});
  }

  canCancelOrder(profile: ProfileResponse, order: Order): boolean {
    return !(order.status == OrderStatus._1 && order.type == OrderType._1)
      && (profile.roles && profile.roles.includes('Admin') || profile.scopes.includes(AuthScopes.OrdersManage));
  }

  canApproveOrder(profile: ProfileResponse, order: Order): boolean {
    return order.status == OrderStatus._1 && order.type == OrderType._1
      && (profile.roles && profile.roles.includes('Admin') || profile.scopes.includes(AuthScopes.OrdersManage));
  }
}

class OrdersDataSource extends ObservedDataSource<GridOrder, GetOrder> {
  constructor(private orderClient: OrderClient) {
    super(order => order.id);
  }

  source(query: Partial<GetOrder>): Observable<PagedResponse<GridOrder>> {
    if (query.keywords && query.keywords.length < 2) {
      return EMPTY;
    }

    const expandAllRequest = Object.keys(Expand2).map(key => Expand2[key]).reduce((prev, current) => prev + ',' + current);
    const dateFrom = query.start_date ? this.getDateInUtc(query.start_date).format('YYYY-MM-DD') : undefined;
    const dateTo = query.end_date ? this.getDateInUtc(query.end_date).format('YYYY-MM-DD') : undefined;
    const status = query.status ? parseInt(query.status, 10) : undefined;
    const sortParam = this.getSortParameterValue(query.sort_by, query.sort_order);

    return this.orderClient
      .order((expandAllRequest as Expand2), query.page, query.page_size, sortParam, query.keywords, undefined,
        undefined, undefined, undefined, status, undefined, dateFrom, dateTo)
      .pipe(map(storeData => ({
        items: storeData.items
          .map(order => ({...order, shippingAddressString: this.getShippingAddress(order.shippingAddress)} as GridOrder)),
        meta: {
          total_count: storeData._meta.totalCount
        }
      })));
  }

  // convert browser date to utc
  private getDateInUtc(datepickerDate: moment.Moment): moment.Moment {
    const adminTimezone = moment.tz.guess();
    return datepickerDate.tz(adminTimezone).utc(true);
  }

  private getSortParameterValue(tableColumn: string, sortOrder: number): string {
    let result = sortOrder === 0 ? '-' : '';

    if (tableColumn === 'create_date') {
      result += 'create_date';
    }
    else if (tableColumn === 'id') {
      result += 'id';
    }

    return result;
  }

  // https://www.campussims.com/how-to-write-a-us-address/#:~:text=The%20recipient's%20first%20and%20last,state%2C%20but%20not%20zip%20code)
  // noinspection JSMethodCanBeStatic
  private getShippingAddress(address: UserAddress): string {
    let result = '';

    if (address.first_name && address.last_name) {
      result += address.first_name + ' ' + address.last_name;
    }
    if (address.street) {
      result += ', ' + address.street;
    }
    if (address.suite) {
      result += ', ' + address.suite;
    }
    if (address.area && address.area.name && address.area.alpha) {
      result += ', ' + address.area.name + ', ' + address.area.alpha;
      if (address.zip) {
        result += ' ' + address.zip;
      }
    }
    if (address.locality && address.locality.name) {
      result += ', ' + address.locality.name;
    }
    if (address.country && address.country.name) {
      result += ', ' + address.country.name;
    }

    return result;
  }
}

class GridOrder extends Order {
  readonly shippingAddressString: string;
}

class GetOrder {
  keywords?: string;
  status?: string;
  page?: number;
  // tslint:disable-next-line:variable-name
  page_size?: number;
  // tslint:disable-next-line:variable-name
  sort_order: number;
  // tslint:disable-next-line:variable-name
  sort_by: string;
  // tslint:disable-next-line:variable-name
  start_date: moment.Moment;
  // tslint:disable-next-line:variable-name
  end_date: moment.Moment;
}

type StatusName = 'Pending approve' | 'Processing' | 'Canceled' | 'Fulfillment error' | 'Pending fulfillment' | 'Fulfilled'
  | 'Out for delivery' | 'In transit' | 'Completed' | 'Partially returned' | 'Returned';

const OrderStatusMapping: Record<OrderStatus, StatusName> = {
  [OrderStatus._1]: 'Pending approve',
  [OrderStatus._2]: 'Processing',
  [OrderStatus._3]: 'Canceled',
  [OrderStatus._4]: 'Fulfillment error',
  [OrderStatus._5]: 'Pending fulfillment',
  [OrderStatus._6]: 'Fulfilled',
  [OrderStatus._7]: 'Out for delivery',
  [OrderStatus._8]: 'In transit',
  [OrderStatus._9]: 'Completed',
  [OrderStatus._10]: 'Partially returned',
  [OrderStatus._11]: 'Returned',
};
