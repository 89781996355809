import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AccessPointConnectionStatus, SafeZone} from '../public-clients';

@Component({
  selector: 'app-safe-zone-card',
  templateUrl: './safe-zone-card.component.html',
  styleUrls: ['./safe-zone-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SafeZoneCardComponent {

  @Input() safeZone: SafeZone;

  AccessPointConnectionStatus = AccessPointConnectionStatus
}
