<ng-container *ngIf="collar$ | async as collar">
  <ng-container *ngIf="private$ | async as private">
    <a mat-mini-fab
       [routerLink]="['/devices']"
       [filter]="{keywords: collar.id}"
       color="primary"
       filterLink="collars"
       matTooltip="Navigate to collar"
       class="collar-icon">
      <mat-icon svgIcon="collar"></mat-icon>
    </a>

    <app-code appearance="text"
              class="id">{{collar.id}}</app-code>

    <span class="revision">
      <span>{{private.revision}} ({{collar.firmware}})</span>
    </span>

    <mat-icon *ngIf="collar.errors != 0"
              [matTooltip]="getError(collar.errors)"
              color="warn"
              class="errors">error
    </mat-icon>

    <span class="mode"
          [style.background]="deviceModeShade(collar.mode)">{{collar.mode | humanize:'device_modes'}}</span>

    <span class="user_mode"
          [style.background]="'#2f98f4'">{{collar.user_mode | humanize:DeviceUserMode}}</span>

<!--    <button mat-mini-fab-->
<!--            class="flashlight">-->
<!--      <mat-icon color="primary"-->
<!--                [matTooltip]="collar.flashlight | humanize:FlashlightStatus">{{collar.flashlight == FlashlightStatus.Off ? 'flashlight_off' : 'flashlight_on'}}</mat-icon>-->
<!--    </button>-->
    <mat-icon color="primary"
              [matTooltip]="collar.flashlight | humanize:FlashlightStatus"
              [routerLink]="['flashlights', collar.id]"
              class="flashlight">{{collar.flashlight == FlashlightStatus.Off ? 'flashlight_off' : 'flashlight_on'}}</mat-icon>

    <mat-icon class="online"
              [matTooltip]="collar.online ? 'Collar is online' : 'Collar is offline'"
              [color]="collar.online ? 'primary' : 'warn'">signal_cellular_alt</mat-icon>

    <button *ngIf="collar.battery"
            (click)="showBatteries()"
            class="charge">
      <!--    <mat-icon>battery_std</mat-icon>-->
      <app-charge [value]="collar.battery.charge"
                  cdkOverlayOrigin
                  #origin="cdkOverlayOrigin"></app-charge>
      <ng-template cdkConnectedOverlay
                   [cdkConnectedOverlayOrigin]="origin"
                   [cdkConnectedOverlayPositions]="_positions"
                   cdkConnectedOverlayHover>
        <div *ngIf="collar.battery"
             class="battery">
          <div class="battery-head">Battery</div>
          <dl>
            <dt>ID</dt>
            <dd>{{private.battery.id}}</dd>
            <dt>Serial</dt>
            <dd>{{collar.battery.serial_number}}</dd>
            <dt>Charge</dt>
            <dd>{{collar.battery.charge}}%</dd>
            <dt>Installed at</dt>
            <dd>{{collar.battery.attach_time | utc | moment:'medium'}}</dd>
          </dl>
        </div>
      </ng-template>
    </button>

    <dl>
      <dt>Serial</dt>
      <dd>{{collar.serial_number}}</dd>
      <dt>CCID</dt>
      <dd>{{private.ccid}}</dd>
      <dt>IMEI</dt>
      <dd>{{private.imei}}</dd>
      <dt>MAC</dt>
      <dd>{{private.mac}}</dd>
    </dl>

    <span class="created">Created {{private.create_date | utc | moment:'medium'}}</span>
  </ng-container>
</ng-container>
