<form name="form"
      [formGroup]="form"
      (ngSubmit)="save()"
      class="side-form">

  <h3>{{mode === EditMode.Edit ? 'Update' : 'Create'}} revision</h3>

  <mat-form-field appearance="fill">
    <mat-label>ID</mat-label>
    <input matInput
           formControlName="id">
    <mat-error *ngIf="form.get('id').hasError('required')">
      Id is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Storage size (bytes)</mat-label>
    <input matInput
           formControlName="storage_size">
    <mat-error *ngIf="form.get('storage_size').hasError('required')">
      Storage size is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="form.get('storage_size').hasError('pattern') || form.get('storage_size').hasError('min')">
      Please enter correct size value
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <textarea matInput
              formControlName="description"
              cdkTextareaAutosize
              required></textarea>
    <mat-error *ngIf="form.get('description').hasError('required')">
      Description is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Reviewers</mat-label>
    <mat-select formControlName="reviewers"
                multiple>
      <mat-option *ngFor="let reviewer of reviewers$ | async"
                  [value]="reviewer.id">
        {{reviewer.nickname}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="side-form-actions">
    <button mat-raised-button
            type="submit"
            color="primary"
            [disabled]="form.invalid">
      {{mode === EditMode.Edit ? 'Update' : 'Create'}}
    </button>
  </div>

  <app-form-error></app-form-error>
</form>
