<form [formGroup]="form"
      (submit)="login()"
      novalidate>

  <h1 class="mat-h1 title">Sign-in to your Averia account</h1>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input autofocus="autofocus"
           formControlName="user_name"
           name="login"
           autocomplete="username"
           matInput>
    <mat-icon class="secondary-text"
              matSuffix>mail
    </mat-icon>
    <mat-error *ngIf="form.get('user_name').hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="!form.get('user_name').hasError('required') &&
                    form.get('user_name').hasError('user_name')">
      Please enter a valid email address
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input formControlName="password"
           matInput
           type="password"
           autocomplete="current-password"
           name="password">
    <mat-icon class="secondary-text"
              matSuffix>vpn_key
    </mat-icon>
    <mat-error>
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-checkbox formControlName="is_persistent">Remember me</mat-checkbox>
  <button mat-raised-button
          [disabled]="form.invalid"
          color="primary">
    Sign-in
  </button>
  <mat-error *ngIf="form.hasError('invalid_credentials')">
    Provided credentials are invalid
  </mat-error>
</form>
