import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {Filter} from './filter';
import {FilterRefresh} from "./refresh";
import {FilterControlDirective, FilterRouterDirective} from './filter-directives';
import {PaginatorExtensionsDirective} from './paginator-extensions.directive';
import { FilterResetComponent } from './reset';


@NgModule({
  declarations: [
    Filter,
    FilterRefresh,
    FilterResetComponent,
    FilterControlDirective,
    FilterRouterDirective,
    PaginatorExtensionsDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule
  ],
  exports: [
    Filter,
    FilterControlDirective,
    FilterRouterDirective,
    PaginatorExtensionsDirective
  ]
})
export class TableModule {
}
