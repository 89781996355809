import {Pipe, PipeTransform, LOCALE_ID, Inject} from '@angular/core';
import {Duration, isDuration, Moment} from 'moment';
import * as moment from 'moment';

@Pipe({name: 'moment'})
export class MomentPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: Moment | Duration | number | undefined | null, format?: string): string | null {
    if (!value) { return null; }

    if (isDuration(value)) {
      return value.format('d[d] h[h] m[m] s[s]', {largest: 2, trim: 'both'});
    }

    const localed = toDate(value).locale(this.locale);

    if (format === 'calendar') {
        return localed.calendar(null, {
          sameDay: '[today at] LT',
          lastDay: '[yesterday at] LT',
          nextDay: '[tomorrow]',
          nextWeek: 'dddd',
          lastWeek: 'LLL',
          sameElse: 'LLL'
        });
    }

    const dateFormat = format === 'medium'
      ? 'lll'
      : !!format
        ? format
        : 'L LTS';

    return localed.format(dateFormat);
  }
}

function toDate(value): moment.Moment {
  return typeof value === 'number'
    ? moment.unix(value)
    : moment(value);
}

@Pipe({name: 'utc'})
export class UtcPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: Moment | Duration | number | undefined | null, timezone?: string): Moment | null {
    if (!value) { return null; }

    return timezone
      ? moment.tz(toDate(value).format('YYYY-MM-DDTHH:mm:ss'), timezone).utc()
      : toDate(value).utc();
  }
}

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: any): moment.Duration | null {
    if (!value) { return null; }

    return moment.duration(value);
  }
}
