import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {SideNavService} from './sidenav.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: 'sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SideNavComponent {
  @ViewChild('container', {read: ViewContainerRef}) container!: ViewContainerRef;
  @ViewChild(MatSidenav) side!: MatSidenav;

  hasBackdrop = true;

  constructor(
    readonly service: SideNavService,
    public cdr: ChangeDetectorRef
  ) {
    service.register(this, cdr);
  }
}
