import {ChangeDetectionStrategy, Component, HostBinding, Input, ViewChild} from '@angular/core';
import {Customer, EmailConfirmationClient, EmailConfirmationCommand, UserStatus} from 'shared';
import {NgrxBusy, withBusy} from "ngrx-busy";

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerCardComponent {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  @HostBinding('class.blocked') get blocked() { return this.customer && this.customer.status === UserStatus.Blocked; }

  @Input() customer: Customer;

  UserStatus = UserStatus;

  constructor(private emailConfirmationClient: EmailConfirmationClient) {
  }

  async confirmEmail(): Promise<any> {
    await this.emailConfirmationClient.emailConfirmation(new EmailConfirmationCommand({customer_id: this.customer.id})).pipe(withBusy(() => this.busy)).toPromise();
    this.customer.email_confirmed = true;
  }
}
