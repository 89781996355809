import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {Filter, PagedResponse, Flashlight, DevicesClient, GetFlashlights, ObservedDataSource} from 'shared';
import {NgrxBusy} from 'ngrx-busy';

@Component({
  selector: 'app-flashlights',
  templateUrl: './flashlights.component.html',
  styleUrls: ['./flashlights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlashlightsComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  dataSource: FlashlightDataSource;
  columns = ['mac_address', 'serial', 'revision', 'firmware', 'create_date', 'status'];

  constructor(devicesClient: DevicesClient) {
    this.dataSource = new FlashlightDataSource(devicesClient);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
  }
}

class FlashlightDataSource extends ObservedDataSource<Flashlight, GetFlashlights> {
  constructor(private devicesClient: DevicesClient) {
    super(device => device.mac_address);
  }

  source(query: Partial<GetFlashlights>): Observable<PagedResponse<Flashlight>> {
    return this.devicesClient.getFlashlights(new GetFlashlights(query));
  }
}
