<mat-card>
  <app-headline>
    <h1 app-headline-title
        color="primary">Device battery</h1>
    <app-headline-actions>
      <a mat-flat-button
         color="primary"
         routerLink="../..">
        Back to devices
        <mat-icon>navigate_before</mat-icon>
      </a>
    </app-headline-actions>
  </app-headline>

  <form [formGroup]="formFilter"
        class="averia-filter">
    <mat-form-field appearance="outline"
                    filter-control
                    size="long">
      <mat-label>Search</mat-label>
      <input matInput
             formControlName="keywords">
    </mat-form-field>
    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>Start date</mat-label>

      <mtx-datetimepicker #startDate
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="startDate"
             formControlName="start_date">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="startDate"></mtx-datetimepicker-toggle>
    </mat-form-field>
    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>End date</mat-label>

      <mtx-datetimepicker #endDate
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="endDate"
             formControlName="end_date">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="endDate"></mtx-datetimepicker-toggle>
    </mat-form-field>

    <button mat-icon-button
            (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </form>

</mat-card>

<mat-card>
  <highcharts-chart *ngIf="isDataLoaded"
                    [Highcharts]="highcharts"
                    [options]="lineChartOptions"
                    class="chart"></highcharts-chart>
</mat-card>

<mat-card>
  <highcharts-chart *ngIf="isDataLoaded"
                    [Highcharts]="barHighcharts"
                    [options]="barChartOptions"
                    class="chart"></highcharts-chart>
</mat-card>

<mat-card>
  <highcharts-chart *ngIf="isDataLoaded"
                    [Highcharts]="linesHighcharts"
                    [options]="linesChartOptions"
                    class="chart"></highcharts-chart>
</mat-card>
