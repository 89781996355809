import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {catchError, filter, shareReplay, startWith, switchMap, map, take} from 'rxjs/operators';
import {
  AuthenticationClient,
  GetProfileQuery,
  ProfileResponse,
  SignInCommand,
  SignOutCommand
} from './clients';

@Injectable({
  providedIn: 'root'
})
export class AuthManager {

  private refreshProfile$ = new Subject<ProfileResponse | null | undefined>();

  profile$: Observable<ProfileResponse>;
  loggedIn$: Observable<boolean>;
  isLoggedIn: () => Promise<boolean>;

  constructor(
    private authenticationClient: AuthenticationClient,
    private router: Router) {

    const auth$ = this.refreshProfile$.pipe(
      startWith(<ProfileResponse | null | undefined>undefined),
      switchMap(profile => typeof profile === 'undefined' ? this.getProfile() : of(profile)),
      shareReplay(1)
    );

    this.profile$ = auth$.pipe(filter(profile => !!profile));
    this.loggedIn$ = auth$.pipe(map(profile => !!profile));
    this.isLoggedIn = () => this.loggedIn$.pipe(take(1)).toPromise();
  }

  private getProfile = () => this.authenticationClient.getProfile(new GetProfileQuery()).pipe(catchError(() => of(null)));

  async signIn(command: SignInCommand): Promise<boolean> {
    try {
      await this.authenticationClient.signIn(command).toPromise();
      const profile = await this.getProfile().toPromise();
      this.refreshProfile$.next(profile);
    } catch {
      return false;
    }
    return true;
  }

  async signOut(): Promise<void> {
    await this.authenticationClient.signOut(new SignOutCommand()).toPromise();
    this.refreshProfile$.next(null);
  }

  async signOut401(): Promise<void> {
    this.refreshProfile$.next(null);
  }
}
