import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LegendComponent, LegendItemComponent, LegendRowDirective} from './legend.component';

@NgModule({
  declarations: [
    LegendComponent,
    LegendItemComponent,
    LegendRowDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LegendComponent,
    LegendItemComponent,
    LegendRowDirective
  ]
})
export class LegendModule {
}
