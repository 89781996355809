<div class="dialog-header">
  <h1 mat-dialog-title>Confirmation</h1>
  <button [mat-dialog-close]="false"
          mat-icon-button
          tabindex="-1"
          class="dialog-close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
<!--  <span *ngIf="isString(data.message); else data.message"-->
<!--        [innerHTML]="data.message"></span>-->
  <span *ngIf="isString(data.message)"
        [innerHTML]="data.message"></span>
  <ng-container *ngIf="!isString(data.message)"
                [ngTemplateOutlet]="data.message"
                [ngTemplateOutletContext]="data"></ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
          [color]="data.cancel.color"
          [mat-dialog-close]="false">{{data.cancel.title}}
  </button>
  <button mat-flat-button
          [color]="data.confirm.color"
          [mat-dialog-close]="true">{{data.confirm.title}}
  </button>
</mat-dialog-actions>
