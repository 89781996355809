import {ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Battery} from 'shared';
import {NgrxBusy} from 'ngrx-busy';

@Component({
  selector: 'app-collar-batteries',
  templateUrl: './collar-batteries.component.html',
  styleUrls: ['./collar-batteries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollarBatteriesComponent {

  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  constructor(@Inject(MAT_DIALOG_DATA) public batteries: Battery[]) {
  }
}
