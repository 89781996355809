import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  SIDE_NAV_DATA,
  SideNavRef, SubscriptionDescription,
  SubscriptionPlan,
  SubscriptionsClient,
  UpdateSubscriptionDescriptionCommand
} from '../../../../shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgrxBusy, withBusy} from 'ngrx-busy';

@Component({
  selector: 'app-subscription-plan-form',
  templateUrl: './subscription-plan-form.component.html',
  styleUrls: ['./subscription-plan-form.component.scss']
})
export class SubscriptionPlanFormComponent implements OnInit {
  @ViewChild(NgrxBusy) busy: NgrxBusy;
  form: UntypedFormGroup;
  selectedLang: string;
  supportedLanguages = ['en', 'ru'];

  constructor(private readonly sideNavRef: SideNavRef<SubscriptionPlanFormComponent>,
              private formBuilder: UntypedFormBuilder,
              public subscriptionsClient: SubscriptionsClient,
              @Inject(SIDE_NAV_DATA) public plan: SubscriptionPlan) {

    const languages = plan.subscription_descriptions.map(d => d.lang);

    if (plan.subscription_descriptions.length > 0) {
      this.selectedLang = languages.some(lang => lang === 'en') ? 'en' : languages[0];
    }
    else {
      this.selectedLang = this.supportedLanguages[0];
    }

    this.fillForm();
  }

  ngOnInit(): void {
  }

  async save(): Promise<void> {
    if (!this.form.valid) { return; }

    const command = this.form.getRawValue();
    try {
      await this.subscriptionsClient.updateSubscriptionDescription(
        new UpdateSubscriptionDescriptionCommand({subscription_plan_id: this.plan.id, ...command})).pipe(withBusy(() => this.busy))
        .toPromise();
      this.sideNavRef.setResult(true);
    } catch (error) {
      this.form.setErrors(error);
    }
  }

  langChanged(newValue: any): void{
    let description = this.plan.subscription_descriptions.filter(d => d.lang === this.selectedLang)[0];
    const formData = this.form.getRawValue();

    if (description) {
      description.description = formData.description;
      description.name = formData.name;
      description.label = formData.promo_label;
    }
    else {
      description = new SubscriptionDescription({
        description: formData.description,
        name: formData.name,
        label: formData.promo_label,
        lang: this.selectedLang });
      this.plan.subscription_descriptions.push(description);
    }
    this.selectedLang = newValue;
    this.fillForm();
  }

  fillForm(): void {
    const description = this.plan.subscription_descriptions.filter(d => d.lang === this.selectedLang)[0];

    this.form = this.formBuilder.group({
      lang: [ this.selectedLang ],
      name: [ description ? description.name : '', Validators.required],
      description: [description ? description.description : '', Validators.required],
      promo_label: [description ? description.label : '', Validators.required],
    });
  }
}
