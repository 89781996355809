import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import * as moment from 'moment';
import {NgrxBusy} from 'ngrx-busy';
import {EMPTY, first, firstValueFrom, map, Observable} from 'rxjs';
import {
  AuthManager,
  Filter,
  ObservedDataSource,
  PagedResponse,
  ProfileResponse,
  SideNav,
  SystemDialog,
  UserStatus, WarningConfirm
} from 'shared';
import {AuthScopes} from 'shared/auth-scopes';
import {Avatar, Body, BodyStatus, Expand7, Review, ReviewClient, ReviewStatus} from '../clients-store';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReviewsComponent implements AfterViewInit, OnDestroy {
  // @ViewChild(MatSort) sort: MatSort;
  @ViewChild(Filter) filter: Filter;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(NgrxBusy) busy: NgrxBusy;

  moment = moment;
  statusKeys = Object.keys(ReviewStatus).filter((v) => !isNaN(Number(v)));
  Status = {
    [ReviewStatus._0]: 'Deleted',
    [ReviewStatus._1]: 'New',
    [ReviewStatus._2]: 'Published',
    [ReviewStatus._3]: 'Spam'
  };
  dataSource: ReviewsDataSource;
  columns = ['status', 'product', 'email', 'review_text', 'star_count', 'create_date', 'options'];
  canChangeStatus$: Observable<boolean>;
  reviews$: Observable<Review[]>;

  constructor(
    private reviewClient: ReviewClient,
    private sidenav: SideNav,
    private systemDialog: SystemDialog,
    private authManager: AuthManager
  ) {
    this.canChangeStatus$ = authManager.profile$.pipe(
      map(profile => profile.roles && profile.roles.includes('Admin') || profile.scopes.includes(AuthScopes.ReviewsManage))
    );
  }

  ngAfterViewInit(): void {
    this.dataSource = new ReviewsDataSource(this.reviewClient);
    this.dataSource.filter = this.filter;
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
    this.reviews$ = this.dataSource.connect()
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  avatar(avatar: Avatar) {
    if (avatar == null || avatar.image == null) return null;
    return 'https://averia.com' + avatar.image.replace('{dimension}', 'medium');
  }

  async changeStatus(review: Review, status: 0 | 1 | 2 | 3): Promise<void> {
    if (status < 0 || status > 3) return;
    const isOk = await this.systemDialog.confirm(`Are you sure you want to ${({
      [0]: 'delete',
      [1]: 'moderate',
      [2]: 'publish',
      [3]: 'move to spam'
    }[status])} review from user ${review.user.name}?`, WarningConfirm);
    if (!isOk) return;

    await firstValueFrom(this.reviewClient.changeStatus(review.id, new Body({status: status})));
    this.dataSource.refresh();
  }

  protected readonly UserStatus = UserStatus;
}

class ReviewsDataSource extends ObservedDataSource<Review, GetReviews> {
  constructor(private reviewClient: ReviewClient) {
    super(order => order.id);
  }

  source(query: Partial<GetReviews>): Observable<PagedResponse<Review>> {

    const expandAllRequest = Object.keys(Expand7).map(key => Expand7[key]).reduce((prev, current) => prev + ',' + current);
    const dateFrom = query.start_date ? this.getDateInUtc(query.start_date).format('YYYY-MM-DD') : undefined;
    const dateTo = query.end_date ? this.getDateInUtc(query.end_date).format('YYYY-MM-DD') : undefined;
    const status = query.status ? parseInt(query.status, 10) : undefined;
    const sortParam = this.getSortParameterValue(query.sort_by, query.sort_order);

    return this.reviewClient
      .review((expandAllRequest as Expand7), query.page, query.page_size, sortParam, undefined, undefined,
        undefined, status, dateFrom, dateTo)
      .pipe(map(storeData => ({
        items: storeData.items,
        meta: {
          total_count: storeData._meta.totalCount
        }
      })));
  }

  // convert browser date to utc
  private getDateInUtc(datepickerDate: moment.Moment): moment.Moment {
    const adminTimezone = moment.tz.guess();
    return datepickerDate.tz(adminTimezone).utc(true);
  }

  private getSortParameterValue(tableColumn: string, sortOrder: number): string {
    let result = sortOrder === 0 ? '-' : '';

    if (tableColumn === 'create_date') {
      result += 'create_date';
    }
    else if (tableColumn === 'id') {
      result += 'id';
    }

    return result;
  }
}

class GetReviews {
  status?: string;
  page?: number;
  // tslint:disable-next-line:variable-name
  page_size?: number;
  // tslint:disable-next-line:variable-name
  sort_order: number;
  // tslint:disable-next-line:variable-name
  sort_by: string;
  // tslint:disable-next-line:variable-name
  start_date: moment.Moment;
  // tslint:disable-next-line:variable-name
  end_date: moment.Moment;
}
