import {ChangeDetectionStrategy, Component, HostListener, Input} from '@angular/core';
import * as moment from 'moment-timezone';
import {ObservableInput} from 'ngx-observable-input';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'zoned-time',
  templateUrl: './zoned-time.component.html',
  styleUrls: ['./zoned-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZonedTimeComponent {

  @HostListener('mouseenter') show() { this.showOverlay$.next(true); }
  @HostListener('mouseout') hide() { this.showOverlay$.next(false); }

  @ObservableInput() @Input('time') time$: Observable<moment.Moment | string>;
  @ObservableInput() @Input('zone') zone$: Observable<string>;
  @Input() preferred: 'local' | 'utc' | 'guess' = 'local';
  @Input() format = 'MMM D, LT';

  conv$: Observable<ZonedTime>;
  showOverlay$ = new BehaviorSubject(false);

  constructor() {
    this.conv$ = combineLatest([this.time$, this.zone$]).pipe(
      filter(([time]) => !!time),
      map(([time, zone]) => (typeof time === 'string' || time instanceof String ? [moment(time), zone] : [time, zone]) as [moment.Moment, string]),
      map(([time, zone]) => {
        const local = !!zone ? moment.tz(time.format('YYYY-MM-DDTHH:mm:ss'), zone) : null;
        const utc = local ? moment.unix(local.unix()).utc() : moment.unix(time.unix()).utc();
        const guess = moment.tz(utc, moment.tz.guess());
        return { local, utc, guess };
      })
    );
  }
}

interface ZonedTime {
  local: moment.Moment,
  utc: moment.Moment,
  guess: moment.Moment
}
