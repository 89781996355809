import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {
  DeleteConfirm,
  ErrorBar,
  SideNav,
  SystemDialog,
  GetUsersQuery,
  RemoveUserCommand,
  UserResponse,
  UsersClient,
  TableDataSource
} from 'shared';
import {AdminUserFormComponent} from './admin-user-form/admin-user-form.component';
import {NgrxBusy, withBusy} from 'ngrx-busy';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminUsersComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();
  private refresh$ = new BehaviorSubject(true);

  dataSource = new TableDataSource<UserResponse>();
  loading$ = new BehaviorSubject(false);

  constructor(
    private readonly sidenav: SideNav,
    private usersClient: UsersClient,
    private systemDialog: SystemDialog,
    private errorBar: ErrorBar
  ) {
    this.refresh$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.usersClient.getUsers(new GetUsersQuery())),
      tap(() => this.loading$.next(false)),
      withBusy(() => this.busy),
      takeUntil(this.unsubscribe$)
    ).subscribe(channels => this.dataSource.data = channels);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async openForm(user?: UserResponse): Promise<void> {
    const result = await this.sidenav.open(AdminUserFormComponent, {data: user})
      .beforeClosed().toPromise();
    if (!result) return;
    this.refresh$.next(true);
  }

  async remove(user: UserResponse): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to delete '${user.nickname}' user?`, DeleteConfirm);
    if (!isOk) return;
    try {
      await this.usersClient.removeUser(new RemoveUserCommand({id: user.id})).toPromise();
      this.refresh$.next(true);
    } catch (error) {
      this.errorBar.open(error);
    }
  }
}
