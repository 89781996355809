import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, firstValueFrom, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {
  Breed,
  BreedsClient,
  DeleteBreed,
  DeleteConfirm,
  Filter,
  GetBreeds,
  SideNav,
  SystemDialog,
  TableDataSource
} from 'shared';
import {BreedFormComponent} from './breed-form/breed-form.component';
import {NgrxBusy, withBusy} from 'ngrx-busy';

@Component({
  selector: 'app-breeds',
  templateUrl: './breeds.component.html',
  styleUrls: ['./breeds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreedsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(Filter) filter: Filter;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();
  private refresh$ = new BehaviorSubject(true);

  dataSource = new TableDataSource<Breed>();

  constructor(private breedsClient: BreedsClient,
              private sidenav: SideNav,
              private systemDialog: SystemDialog) {
  }

  ngOnInit(): void {
    this.refresh$.pipe(
      switchMap(() => this.breedsClient.getBreeds(new GetBreeds())),
      withBusy(() => this.busy),
      takeUntil(this.unsubscribe$)
    ).subscribe(data => this.dataSource.data = data);
  }

  ngAfterViewInit(): void {
    this.dataSource.filter = this.filter;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const baseSorting = this.dataSource.sortingDataAccessor;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => baseSorting(data, sortHeaderId);
    this.dataSource.filterPredicate = (data, key: any, filter: any) =>
      (<string> filter).split(' ')
        .map(search => search.trim().toLocaleLowerCase())
        .every(search => data.id?.toLocaleLowerCase().indexOf(search) !== -1
          || data.name.toLocaleLowerCase().indexOf(search) !== -1);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async openForm(breed?: Breed): Promise<void> {
    const result = await this.sidenav.open(BreedFormComponent, {data: breed})
      .beforeClosed().toPromise();
    if (!result) { return; }
    this.refresh$.next(true);
  }

  // async review(firmware: FirmwareResponse): Promise<void> {
  //   await this.firmwaresClient.reviewFirmware(new ReviewFirmwareCommand({id: firmware.id})).toPromise();
  //   this.refresh$.next(true);
  // }

  async delete(breed: Breed): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to delete '${breed.name}'?`, DeleteConfirm);
    if (!isOk) { return; }
    await firstValueFrom(this.breedsClient.deleteBreed(new DeleteBreed({breed_id: breed.id})));
    this.refresh$.next(true);
  }

  // reviewAvailable(firmware: FirmwareResponse): boolean {
  //   return !!firmware.reviews && firmware.reviews.some(review => review.id === this.profile.id && !review.reviewed);
  // }
  //
  // activateAvailable(firmware: FirmwareResponse): boolean {
  //   return firmware.status !== FirmwareStatus.Active && (!firmware.reviews || firmware.reviews.every(review => review.reviewed));
  // }

  // async activate(firmware: FirmwareResponse): Promise<void> {
  //   await this.firmwaresClient.activateFirmware(new ActivateFirmwareCommand({id: firmware.id})).toPromise();
  //   this.refresh$.next(true);
  // }
}
