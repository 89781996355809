import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'shared';
import {DashboardComponent} from "./dashboard.component";
import {FirmwareStatsComponent} from "./firmware-stats.component";
import {API_BASE_URL_PUBLIC} from "../customers/public-clients";
import {environment} from "../../environments/environment";

@NgModule({
  declarations: [
    DashboardComponent,
    FirmwareStatsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: DashboardComponent
      }
    ])
  ],
  providers: [
    {provide: API_BASE_URL_PUBLIC, useFactory: () => environment.mobileApiUrl},
  ]
})
export class DashboardModule {
}
