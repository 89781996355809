<ng-template #legend
             let-modes="modes">
  <div class="legend modes-legend">
    <h4>Legend</h4>
    <ng-container *ngFor="let item of modes">
      <label [ngStyle]="{'color': item.color}">
        <input type="checkbox"
               [value]="item.mode"
               checked
               disabled>
        {{item.title}}
      </label>
    </ng-container>
  </div>
</ng-template>

<ng-template #trackColorsLegend
             let-trackColorsInfo="trackColorsInfo">
  <div class="legend">
    <h4>Speed Levels</h4>
    <ng-container *ngFor="let item of trackColorsInfo">
      <label [ngStyle]="{'color': item.color}">
        <i [ngStyle]="{'background': item.color}"></i> {{item.title}}
      </label>
    </ng-container>
  </div>
</ng-template>

<ng-template #popup
             let-point="point">
  <dl>
    <dt>Timestamp</dt>
    <dd>{{point.timestamp | utc | moment:'YYYY-MM-DD HH:mm:ss'}}</dd>
    <dt>Mode</dt>
    <dd>{{point.mode | humanize:'device_modes'}}</dd>
    <dt>Latitude</dt>
    <dd>{{point.lat}}</dd>
    <dt>Longitude</dt>
    <dd>{{point.lng}}</dd>
    <dt>Altitude</dt>
    <dd>{{point.alt}}</dd>
    <dt>Accuracy</dt>
    <dd>{{point.accuracy}}</dd>
    <dt>Fix type</dt>
    <dd>{{point.fixed_by | humanize:'fix_types'}}</dd>
    <dt>Battery charge</dt>
    <dd>{{point.battery_charge}}</dd>
  </dl>
</ng-template>

<ng-template #relativePointPopup
             let-point="point">
  <dl>
    <dt>Timestamp</dt>
    <dd>{{point.timestamp | utc | moment:'YYYY-MM-DD HH:mm:ss'}}</dd>
    <dt>Latitude</dt>
    <dd>{{point.location.lat}}</dd>
    <dt>Longitude</dt>
    <dd>{{point.location.lng}}</dd>
    <dt>Altitude</dt>
    <dd>{{point.location.alt}}</dd>
    <dt>Accuracy</dt>
    <dd>{{point.accuracy}}</dd>
    <dt>Fix type</dt>
    <dd>{{point.fixed_by | humanize:'fix_types'}}</dd>
    <dt>Distance</dt>
    <dd>{{point.distance}}</dd>
    <dt>Speed</dt>
    <dd>{{point.speed}}</dd>
    <dt>Speed change</dt>
    <dd>{{point.speed_change}}</dd>
    <dt>Duration</dt>
    <dd>{{point.duration}}</dd>
    <dt>Activity type</dt>
    <dd>{{point.activity_state | humanize:'activities'}}</dd>
  </dl>
</ng-template>

<ng-template #straightPartPopup let-partInfo="part">
  <dl>
    <dt>activity type</dt>
    <dd>{{partInfo.activity_type}}</dd>
    <dt>steps</dt>
    <dd>{{partInfo.steps_number}}</dd>
    <dt>points number</dt>
    <dd>{{partInfo.points.length}}</dd>
    <dt>avg speed</dt>
    <dd>{{partInfo.avg_speed}}m/s</dd>
    <dt>distance by gps</dt>
    <dd>{{partInfo.distance_by_gps}}m</dd>
    <dt>distance by activities</dt>
    <dd>{{partInfo.distance_by_activities}}m</dd>
    <dt>steps by meter</dt>
    <dd>{{partInfo.steps_by_meter}}</dd>
    <dt>duration</dt>
    <dd>{{partInfo.duration | moment}}</dd>
    <dt><strong>ratio </strong></dt>
    <dd><strong>{{partInfo.ratio}}</strong></dd>
  </dl>
</ng-template>

<ng-template #toolbar>
<!--  <mat-checkbox [(ngModel)]="showAllMarkers"-->
<!--                (ngModelChange)="showAllMarkersChanged()"-->
<!--                class="toolbar-option">-->
<!--    Show all markers-->
<!--  </mat-checkbox>-->

<!--  <mat-checkbox [formControl]="clustered"-->
<!--                class="toolbar-option">-->
<!--    Use cluster-->
<!--  </mat-checkbox>-->
</ng-template>

<ng-template #close>
  <a mat-flat-button
     class="mode-button close"
     [routerLink]="['../../']">
    <mat-icon>close</mat-icon>
  </a>
</ng-template>

<ngrx-busy class="info">
  <mat-card class="info-card">
    <ng-container *ngIf="!recalculateForm.enabled && !ratioForm.enabled && track$ | async as track">
      <mat-card-content>
        <p><span>Track ID</span> <app-code appearance="text">{{track.id}}</app-code></p>
        <p>
          <span>Started at</span>
          <span>
            <zoned-time [time]="track.started_at"
                        [zone]="track.started_at_zone"></zoned-time>
            <app-code>
              {{ track.started_at | utc : track.started_at_zone | moment:'DD.MM.YYYY, HH:mm:ss' }}
            </app-code>
          </span>
        </p>
        <p>
          <span>
            Ended at
          </span>
          <span>
            <zoned-time *ngIf="track.ended_at"
                        [time]="track.ended_at"
                        [zone]="track.ended_at_zone"></zoned-time>
            <span *ngIf="!track.ended_at">...</span>
            <app-code *ngIf="track.ended_at">
              {{ track.ended_at | utc: track.ended_at_zone | moment:'DD.MM.YYYY, HH:mm:ss' }}
            </app-code>
          </span>
        </p>
        <p><span>Duration</span> <span>{{track.duration | moment}}</span></p>
        <p><span>Distance by steps</span> <span>{{track.distance ? track.distance + ' meters' : ''}}</span></p>
        <p><span>Distance by GPS</span> <span>{{track.length ? track.length + ' meters' : ''}}</span></p>
        <p><span>Calories</span>&nbsp;<span>{{track.calories}}</span></p>
        <p><span>Steps</span>&nbsp;<span>{{track.steps}}</span></p>
  <!--      <p><span>Battery</span> <span *ngIf="track.battery">{{track.battery.initial}}/{{track.battery.final}}-->
  <!--        <span class="percent">{{track.battery.initial - track.battery.final}}%</span></span></p>-->
        <p>
          <span>Status</span>
          <span *ngIf="!track.ended_at">In progress</span>
          <span *ngIf="track.ended_at && track.rejected_reason != null">Rejected by {{track.rejected_reason | humanize:'track_rejected_reason'}}</span>
          <span *ngIf="track.ended_at && track.rejected_reason == null">Completed</span>
        </p>
      </mat-card-content>
    </ng-container>

    <ng-container *ngIf="recalculateForm.enabled">
      <form [formGroup]="recalculateForm"
            class="test-card">

        <mat-form-field>
          <mat-label>Accuracy percentile</mat-label>
          <input matInput
                 formControlName="accuracy_percentile"
                 [imask]="{mask: Number, scale: 0}">
        </mat-form-field>

        <div class="card-columns">
          <mat-form-field>
            <mat-label>[Foot] Kalman decay</mat-label>
            <input matInput
                   formControlName="foot_kalman_decay"
                   [imask]="{mask: Number, radix: '.', scale: 0}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>[Car] Kalman decay</mat-label>
            <input matInput
                   formControlName="car_kalman_decay"
                   [imask]="{mask: Number, radix: '.', scale: 0}">
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>Bunch area</mat-label>
          <input matInput
                 formControlName="bunch_area"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Douglas peucker tolerance</mat-label>
          <input matInput
                 formControlName="douglas_tolerance"
                 [imask]="{mask: Number, radix: '.', scale: 3, normalizeZeros: false}">
        </mat-form-field>

        <div class="card-columns">
          <mat-form-field>
            <mat-label>Curve level</mat-label>
            <input matInput
                   formControlName="curve_level"
                   [imask]="{mask: Number, scale: 0}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Curve strength</mat-label>
            <input matInput
                   formControlName="curve_strength"
                   [imask]="{mask: Number, radix: '.', scale: 3, normalizeZeros: false}">
          </mat-form-field>
        </div>

        <div class="card-columns">
          <mat-form-field>
            <mat-label>Ema alpha</mat-label>
            <input matInput
                   formControlName="ema_alpha"
                   [imask]="{mask: Number, radix: '.', scale: 3, max: 1, normalizeZeros: false}">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ema window</mat-label>
            <input matInput
                   formControlName="ema_window"
                   [imask]="{mask: Number, max: 255, scale: 0}">
          </mat-form-field>
        </div>

        <app-form-error></app-form-error>
      </form>
      <mat-card-actions align="end">
        <button mat-button
                color="primary"
                (click)="recalculateConfirm()">Update
        </button>
      </mat-card-actions>
    </ng-container>

    <ng-container *ngIf="ratioForm.enabled">
      <form [formGroup]="ratioForm"
            class="test-card">

        <mat-form-field>
          <mat-label>Max Duration Between Activities (sec)</mat-label>
          <input matInput
                 formControlName="max_duration"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Min Steps Number</mat-label>
          <input matInput
                 formControlName="min_steps"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Duration Delta Between Activity And Point (sec)</mat-label>
          <input matInput
                 formControlName="boundary_activity_and_point_delta"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>

        <div class="card-columns">
          <mat-form-field>
            <mat-label>Window Points Number</mat-label>
            <input matInput
                   formControlName="window_points_number"
                   [imask]="{mask: Number, radix: '.', scale: 0}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Min Windows Number</mat-label>
            <input matInput
                   formControlName="min_windows_number"
                   [imask]="{mask: Number, radix: '.', scale: 0}">
          </mat-form-field>
        </div>

        <div class="card-columns">
          <mat-form-field>
            <mat-label>Min Distance (in m)</mat-label>
            <input matInput
                   formControlName="min_distance"
                   [imask]="{mask: Number, radix: '.', scale: 0}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Speed Dispersion</mat-label>
            <input matInput
                   formControlName="speed_dispersion"
                   [imask]="{mask: Number, radix: '.', scale: 2}">
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>Max Distance Between Two Points (in m)</mat-label>
          <input matInput
                 formControlName="max_two_points_distance"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Max Duration Between Two Points (sec)</mat-label>
          <input matInput
                 formControlName="max_two_points_duration"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Max accuracy (in m)</mat-label>
          <input matInput
                 formControlName="max_accuracy"
                 [imask]="{mask: Number, radix: '.', scale: 0}">
        </mat-form-field>

        <app-form-error></app-form-error>
      </form>
    </ng-container>
  </mat-card>

  <mat-card class="tabs-card">
    <mat-tab-group>
      <mat-tab label="Walkers">
        <table mat-table
               *ngIf="track$ | async as track"
               [dataSource]="track.walkersD">
          <ng-container matColumnDef="id">
            <th mat-header-cell
                *matHeaderCellDef>Id
            </th>
            <td mat-cell
                *matCellDef="let row"
                class="min-width">
              <app-code>{{row.id}}</app-code>
            </td>
          </ng-container>
          <ng-container matColumnDef="full_name">
            <th mat-header-cell
                *matHeaderCellDef>Full name
            </th>
            <td mat-cell
                *matCellDef="let row">{{row.first_name}} {{row.last_name}}</td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell
                *matHeaderCellDef>Email
            </th>
            <td mat-cell
                *matCellDef="let row">{{row.email}}</td>
          </ng-container>
          <ng-container matColumnDef="walk_duration">
            <th mat-header-cell
                *matHeaderCellDef>Duration %
            </th>
            <td mat-cell
                *matCellDef="let row">{{calcDurationPercent(track, row.duration)}}</td>
          </ng-container>
          <tr mat-header-row
              *matHeaderRowDef="['id', 'full_name', 'email','walk_duration']"></tr>
          <tr mat-row
              *matRowDef="let row; columns: ['id', 'full_name', 'email','walk_duration']"></tr>
        </table>
      </mat-tab>
      <mat-tab label="Activity">
        <table mat-table
               *ngIf="track$ | async as track"
               [dataSource]="track.stats"
               class="activity">
          <ng-container matColumnDef="type">
            <th mat-header-cell
                *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let row">{{row.activity_type}}</td>
          </ng-container>
          <ng-container matColumnDef="duration">
            <th mat-header-cell
                [attr.colspan]="2"
                *matHeaderCellDef>Duration
            </th>
            <td mat-cell
                *matCellDef="let row">{{row.duration | moment}}</td>
          </ng-container>
          <ng-container matColumnDef="duration_percent">
            <td mat-cell
                *matCellDef="let row">
              <span class="percent">{{row.percent_by_time}}%</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="steps">
            <th mat-header-cell
                [attr.colspan]="2"
                *matHeaderCellDef>Steps
            </th>
            <td mat-cell
                *matCellDef="let row">{{row.steps}}</td>
          </ng-container>
          <ng-container matColumnDef="steps_percent">
            <td mat-cell
                *matCellDef="let row">
              <span class="percent">{{row.percent_by_steps}}%</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="distance">
            <th mat-header-cell
                [attr.colspan]="2"
                *matHeaderCellDef>Distance
            </th>
            <td mat-cell
                *matCellDef="let row">{{row.distance}}</td>
          </ng-container>
          <ng-container matColumnDef="distance_percent">
            <td mat-cell
                *matCellDef="let row">
              <span class="percent">{{row.percent_by_distance}}%</span>
            </td>
          </ng-container>
          <tr mat-header-row
              *matHeaderRowDef="['type', 'duration', 'steps', 'distance']"></tr>
          <tr mat-row
              *matRowDef="let row; columns: ['type', 'duration', 'duration_percent', 'steps', 'steps_percent', 'distance', 'distance_percent']"></tr>
        </table>
      </mat-tab>
      <mat-tab label="Achievements">
        <div class="achievements">
          <div *ngFor="let achievement of achievements$ | async"
               [matTooltip]="achievement.description"
               class="achievement">
            <img [src]="achievement.icon">
            <span class="achievement-header">{{achievement.header}}</span>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Battery">

        <ng-template matTabContent>
          <div class="battery">
            <ngx-charts-line-chart [results]="battery$ | async"
                                   [xAxisTickFormatting]="dateTickFormatting"
                                   [xAxis]="true"
                                   [yAxis]="true">
              <ng-template #tooltipTemplate
                           let-model="model">
                <div class="battery-tooltip">
                  {{model.series}}: {{model.value}}<br>
                  {{model.timestamp | utc | moment}}
                </div>
              </ng-template>

              <ng-template #seriesTooltipTemplate
                           let-model="model">
                <ng-container *ngFor="let row of model">
                  <ng-template ngFor let-row [ngForOf]="model"
                               [ngTemplateOutlet]="tooltipTemplate"
                               [ngTemplateOutletContext]="{model: row}"></ng-template>
                </ng-container>
              </ng-template>
            </ngx-charts-line-chart>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ngrx-busy>

