import {Directive, ElementRef, Input} from '@angular/core';
import {CanColor, mixinColor} from '@angular/material/core';

class HeadlineTitleBase {
    constructor(public _elementRef: ElementRef) {
    }
}

@Directive({
    selector: '[app-headline-title]',
    exportAs: 'headlineTitle',
    host: {class: 'averia-headline-title', '[class.sticky]': 'sticky'},
    inputs: ['color']
})
export class HeadlineTitleDirective extends mixinColor(HeadlineTitleBase, 'primary') implements CanColor {
    @Input() sticky = true;

    constructor(elementRef: ElementRef) {
        super(elementRef);
    }
}

@Directive({
    selector: '[app-headline-actions], app-headline-actions',
    host: {class: 'averia-headline-actions'}
})
export class HeadlineActionsDirective {
}
