import {Component, OnInit, ViewChild} from '@angular/core';
import {AchievementsClient, AchievementType, GetAchievementTypes} from '../../../shared';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'app-achievement-types',
  templateUrl: './achievement-types.component.html',
  styleUrls: ['./achievement-types.component.scss']
})
export class AchievementTypesComponent implements OnInit {
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  $achievementTypes: Observable<AchievementType[]>;

  constructor(private achievementsClient: AchievementsClient) { }

  ngOnInit(): void {
    this.$achievementTypes = this.achievementsClient.getAchievementTypes(new GetAchievementTypes())
      .pipe(withBusy(() => this.busy));
  }

  // swapPictures(type: AchievementType): void {
  //   if (!type.inactive_picture_url) {
  //     return;
  //   }
  //
  //   const tmp = type.inactive_picture_url;
  //   type.inactive_picture_url = type.picture_url;
  //   type.picture_url = tmp;
  // }
}
