<ng-container *ngIf="data$ | async as data">
  <table mat-table
         [dataSource]="data.packets">

    <ng-container matColumnDef="type">
      <th mat-header-cell
          *matHeaderCellDef>Bin pack
      </th>
      <td mat-cell
          *matCellDef="let packet">
        <small>[{{packet.packet_type | hex}}]</small> {{packet.packet_type | humanize:'packet_types'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <th mat-header-cell
          *matHeaderCellDef>Timestamp
      </th>
      <td mat-cell
          *matCellDef="let packet"
          class="min-width">
        {{packet.parsed.timestamp | utc | moment}}
      </td>
    </ng-container>

    <ng-container matColumnDef="parsed">
      <th mat-header-cell
          *matHeaderCellDef>Parsed
      </th>
      <td mat-cell
          *matCellDef="let packet">
        <app-highlight [value]="packet.parsed | exclude:['timestamp'] | json"></app-highlight>
      </td>
    </ng-container>

    <mat-text-column name="device_mode"
                     headerText="Device mode"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="previous_device_mode"
                     headerText="Prev device mode"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="bat_capacity"
                     headerText="Capacity"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="bat_voltage"
                     headerText="Voltage"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="humidity"
                     headerText="Wet"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="rx_level"
                     headerText="RX Level"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="snr"
                     headerText="SNR"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="sv_in_view"
                     headerText="SVS"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="pos_accu"
                     headerText="Radius"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="fix_type"
                     headerText="GPS FT"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="height"
                     headerText="Alt"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="speed"
                     headerText="Speed"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="bat_charge"
                     headerText="Charge"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="latitude"
                     headerText="Latitude"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="longitude"
                     headerText="Longitude"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="flashlight_status"
                     headerText="FL status"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="ext_temp"
                     headerText="Temp"
                     [dataAccessor]="parsedAccessor"></mat-text-column>
    <mat-text-column name="sensor_activity"
                     headerText="Activity"
                     [dataAccessor]="parsedAccessor"></mat-text-column>


    <ng-container matColumnDef="load_more">
      <td mat-footer-cell
          *matFooterCellDef
          colspan="3">
        <button *ngIf="data.left_packets.length >= 20"
                mat-button
                (click)="loadPackets(data, 20)">
          Show next 20
        </button>

        <button mat-button
                (click)="loadPackets(data, data.left_packets.length)">
          Show left {{data.left_packets.length}}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="data.columns">
    <tr mat-row
        *matRowDef="let packet; columns: data.columns"></tr>
    <tr mat-footer-row
        *matFooterRowDef="['load_more']"
        [hidden]="data.left_packets.length == 0"></tr>
  </table>
</ng-container>
