<mat-sidenav-container class="sidenav-container"
                       [hasBackdrop]="hasBackdrop">
<!--    <mat-drawer #drawer mode="Side" [position]="'end'" style="width: 30%">-->
    <mat-sidenav #sidenav
                 mode="Side"
                 position="end"
                 fixedInViewport="false"
                 class="sidenav">
        <div class="sidenav-content">
            <template #container></template>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
