import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  DeleteConfirm,
  SideNav,
  SystemDialog,
  GetUserRolesQuery,
  RemoveUserRoleCommand,
  UserRoleResponse,
  UsersClient,
  TableDataSource
} from 'shared';
import {AdminRoleFormComponent} from './admin-role-form/admin-role-form.component';
import {NgrxBusy, withBusy} from 'ngrx-busy';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminRolesComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private readonly unsubscribe$ = new Subject();
  private refresh$ = new BehaviorSubject(true);

  dataSource = new TableDataSource<UserRoleResponse>();
  loading$ = new BehaviorSubject(false);

  constructor(
    private readonly sidenav: SideNav,
    private usersClient: UsersClient,
    private systemDialog: SystemDialog
  ) {
    this.refresh$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.usersClient.getUserRoles(new GetUserRolesQuery())),
      tap(() => this.loading$.next(false)),
      withBusy(() => this.busy),
      takeUntil(this.unsubscribe$)
    ).subscribe(channels => this.dataSource.data = channels);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async openForm(item?: UserRoleResponse): Promise<void> {
    const result = await this.sidenav.open(AdminRoleFormComponent, {data: item})
      .beforeClosed().toPromise();
    if (!result) return;
    this.refresh$.next(true);
  }

  async remove(role: UserRoleResponse): Promise<void> {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to delete '${role.name}' role?`, DeleteConfirm);
    if (!isOk) return;
    await this.usersClient.removeUserRole(new RemoveUserRoleCommand({id: role.id})).toPromise();
    this.refresh$.next(true);
  }
}
