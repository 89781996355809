import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {
  ObservedDataSource,
  PagedResponse,
  GetWalks,
  Walk,
  TracksClient
} from 'shared';
import {NgrxBusy,} from 'ngrx-busy';

@Component({
  selector: 'app-walks',
  templateUrl: './walks.component.html',
  styleUrls: ['./walks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalksComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  private dataSource: WalksDataSource;
  walks$: Observable<Walk[]>;

  constructor(private tracksClient: TracksClient) {
    this.dataSource = new WalksDataSource(tracksClient);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = new MatSort();
    this.dataSource.sort.active = 'create_date';
    this.dataSource.sort.direction = 'desc'
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
    this.walks$ = this.dataSource.connect();
    this.dataSource.meta()
  }
}

class WalksDataSource extends ObservedDataSource<Walk, GetWalks> {
  constructor(private tracksClient: TracksClient) {
    super(walk => walk.id);
  }

  source(query: Partial<GetWalks>): Observable<PagedResponse<Walk>> {
    return this.tracksClient.getWalks(new GetWalks(query));
  }
}
