import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {Filter} from './filter';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-filter-refresh',
  templateUrl: './refresh.html',
  styleUrls: ['./refresh.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterRefresh implements OnDestroy {
  refreshSubscription: Subscription;

  @Input() mode: 'auto' | 'manual';

  constructor(public readonly filter: Filter) {
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  switchAutoRefresh(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    } else {
      this.refreshSubscription = interval(5000)
        .pipe(startWith(0))
        .subscribe(() => this.refresh());
    }
  }

  refresh(): void {
    this.filter.refresh();
  }
}
