import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {MatPaginator} from "@angular/material/paginator";
import {
  Customer,
  CustomersClient,
  GetCustomers,
  Filter,
  ObservedDataSource,
  PagedResponse,
  UserStatus
} from 'shared';
import {NgrxBusy} from 'ngrx-busy';
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersComponent implements OnInit {
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  UserStatus = UserStatus;
  dataSource: CustomersDataSource;
  customers$: Observable<Customer[]>;

  constructor(customersClient: CustomersClient) {
    this.dataSource = new CustomersDataSource(customersClient);
  }

  ngOnInit(): void {
    this.dataSource.filter = this.filter;
    this.dataSource.sort = new MatSort();
    this.dataSource.sort.active = 'create_date';
    this.dataSource.sort.direction = 'desc'
    this.dataSource.paginator = this.paginator;
    this.dataSource.busy = this.busy;
    this.customers$ = this.dataSource.connect();
    this.dataSource.meta()
  }
}

class CustomersDataSource extends ObservedDataSource<Customer, GetCustomers> {
  constructor(private customersClient: CustomersClient) {
    super(customer => customer.id);
  }

  source(query: Partial<GetCustomers>): Observable<PagedResponse<Customer>> {
    return this.customersClient.getCustomers(new GetCustomers(query));
  }
}
