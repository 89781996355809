import {ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {DecimalPipe} from "@angular/common";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {
  CancelSubscriptionCommand,
  GetInvoices,
  Subscription,
  SubscriptionInvoice,
  SubscriptionsClient,
  SystemDialog
} from 'shared';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionsComponent {

  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  format = '1.0-2';
  invoice_data: SubscriptionInvoice[] = [];
  displayedColumns: string[] = ['position', 'name', 'weight'];
  subscriptionInvoiceDataSource = new MatTableDataSource<SubscriptionInvoice>(this.invoice_data);
  selectedInvoice: SubscriptionInvoice | null;
  selectedSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public subscriptions: Subscription[],
              private subscriptionsClient: SubscriptionsClient,
              private systemDialog: SystemDialog,
              private decimalPipe: DecimalPipe
  ) {
  }

  async cancel_subscription(data: any) {
    const isOk = await this.systemDialog.confirm(`Are you sure you want to cancel subscription ?`, {
      confirm: {
        title: 'Confirm',
        color: 'warn'
      }
    });
    if (!isOk) return;
    await firstValueFrom(this.subscriptionsClient
      .cancelSubscription(new CancelSubscriptionCommand({'subscription_id': data.id}))
      .pipe(withBusy(() => this.busy)));
  }

  async subscription_selection_change(subscription: Subscription): Promise<void> {
    this.selectedSubscription = subscription;
    this.selectedInvoice = undefined;
    let inv = await firstValueFrom(this.subscriptionsClient
      .getInvoices(new GetInvoices({subscription: subscription.id}))
      .pipe(withBusy(() => this.busy)));
    this.subscriptionInvoiceDataSource = new MatTableDataSource<SubscriptionInvoice>(inv);
  }

  formatted(num: number) {
    num = num / 100;
    return this.decimalPipe.transform(num, this.format, 'en-en');
  }

  click_invoice(row: SubscriptionInvoice) {
    this.selectedInvoice = row;
  }
}
