export class EqSet<T> extends Set<T> {

  constructor(private readonly comparer: (x: T, y: T) => boolean) {
    super();
  }

  add(value: T): this {
    if (!this.has(value)) {
      super.add(value);
    }

    return this;
  }

  delete(value: T): boolean {
    const keys = this.keys();
    while (true) {
      const res = keys.next();
      if (res.done) { return false; }
      if (this.comparer(res.value, value)) { return super.delete(res.value);}
    }
  }

  has(value: T): boolean {
    const keys = this.keys();
    while (true) {
      const res = keys.next();
      if (res.done) return false;
      if (this.comparer(res.value, value)) return true;
    }
  }
}
// (<any>window).EqSet = EqSet;
