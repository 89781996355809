import {DataSource} from '@angular/cdk/table';
import {MatSort} from '@angular/material/sort';

import {asyncScheduler, BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  startWith,
  switchMap,
  tap,
  throttleTime
} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {NgrxBusy, withBusy} from 'ngrx-busy';

import {Filter} from './filter';
import {EqSet} from "../../extensions/set";

export declare interface PagedResponse<T> {
  items: T[];
  meta: {
    total_count?: number;
  }
}

export type DataSourceRequest<T> =
  Partial<T>
  & { page?: number; page_size?: number; sort_by?: string; sort_order?: number; }

export abstract class ObservedDataSource<TModel, TQuery> extends DataSource<TModel> {
  private subscription: Subscription;
  selected: SelectionModel<TModel>;
  private readonly _meta: DataSourceMeta<TModel>;

  get data(): TModel[] {
    return this.entities$.value;
  }

  get empty(): boolean {
    return this._empty;
  }

  paginator: MatPaginator | null;
  sort: MatSort | null;
  filter: Filter<TQuery> | null;
  busy: NgrxBusy | null;

  /**
   * @deprecated Define columns inside component
   */
  columns: string[] = [];
  readonly beforeLoaded: Observable<void>;
  readonly afterLoaded: Observable<void>;
  // readonly onDisconnected: Observable<boolean>;


  private readonly entities$ = new BehaviorSubject<TModel[]>([]);
  private readonly refresh$ = new Subject<void>();
  private readonly beforeLoaded$ = new Subject<void>();
  private readonly afterLoaded$ = new Subject<void>();
  private _empty: boolean = false;

  protected constructor(identity: (value: TModel) => any) {
    super();
    this.sort = new MatSort();
    this.sort.active = '';
    this.sort.direction = 'asc';
    this.beforeLoaded = this.beforeLoaded$.asObservable();
    this.afterLoaded = this.afterLoaded$.asObservable();
    // this.onDisconnected = this.unsubscribe$.asObservable();
    this.selected = new SelectionModel<TModel>((prev, cur) => identity(prev) == identity(cur));
    this._meta = new DataSourceMeta(this, this.selected);
  }

  abstract source(query: Partial<TQuery>): Observable<PagedResponse<TModel>>;

  connect(): Observable<TModel[]> {
    if (!this.subscription) {
      const page$ = this.paginator ? this.paginator.page.pipe(
        map(ev => ({pageIndex: ev.pageIndex, pageSize: ev.pageSize})),
        startWith({pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize})
      ) : of({pageIndex: 0, pageSize: 2147483647});
      const sort$ = this.sort.sortChange.pipe(startWith({active: this.sort.active, direction: this.sort.direction}));
      const filter$ = this.filter ? this.filter.filterChange.pipe(
        throttleTime(700, asyncScheduler, {leading: true, trailing: true}),
        concatMap((value, index) => index > 0
          ? of(value).pipe(tap(() => this.resetPaginator()))
          : of(value)
        )
      ) : of({});

      this.subscription = combineLatest([page$, sort$, filter$]).pipe(
        debounceTime(50),
        map(([, , filter]) => {
          const query = {...filter};
          this.buildQuery(query);
          return query;
        }),
        switchMap(query => this.refresh$.pipe(startWith(true), map(() => query))),
        tap(() => this.beforeLoaded$.next()),
        switchMap(query => this.source(query).pipe(catchError(() => of(<PagedResponse<TModel>>{items: [], meta: {total_count: 0}})))),
        tap(paged => {
          if (this.paginator) {
            this.paginator.length = paged.meta.total_count;
          }
          this._empty = paged.items.length === 0;
          this.afterLoaded$.next();
        }),
        map(paged => paged.items),
        withBusy(() => this.busy)
      ).subscribe(entities => this.entities$.next(entities));
    }

    return this.entities$.asObservable();
  }

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  refresh(): void {
    this.refresh$.next();
  }

  meta(item?: TModel): IDataSourceMeta {
    if (!item) return this._meta;

    return new ItemDataSourceMeta(item, this.selected);
  }

  private buildQuery(query: DataSourceRequest<TQuery>): void {
    if (this.paginator) {
      query.page = this.paginator.pageIndex + 1;
      query.page_size = this.paginator.pageSize;
    }
    query.sort_by = this.sort.active;
    query.sort_order = Number(this.sort.direction === 'desc');
  }

  private resetPaginator(): void {
    if (!this.paginator) {
      return;
    }
    if (!this.paginator.hasPreviousPage()) {
      return;
    }
    this.paginator.firstPage();
  }
}

export interface IDataSourceMeta {
  selected: boolean;
  indeterminate: boolean;
  toggle(): void;
  changed: Observable<any>;
}

class SelectionModel<T> extends EqSet<T> {
  changed = new Subject();

  add(value: T | T[]): this {
    if (Array.isArray(value)) {
      value.forEach(val => super.add(val));
    } else {
      super.add(value);
    }
    this.changed.next(Array.from(this));
    return this;
  }

  delete(value: T): boolean {
    const result = super.delete(value);
    this.changed.next(Array.from(this));
    return result;
  }

  clear(): void {
    super.clear();
    this.changed.next(Array.from(this));
  }
}

class DataSourceMeta<T> implements IDataSourceMeta {

  constructor(private readonly source: ObservedDataSource<T, any>,
              private readonly selection: SelectionModel<T>) {
  }

  changed = this.selection.changed;

  get selected(): boolean {
    return this.selection.size && this.allSelected;
  }

  get indeterminate(): boolean {
    return this.selection.size > 0 && !this.allSelected;
  }

  private get allSelected(): boolean {
    return this.selection.size === this.source.data.length;

    // return this.source.data
    //   .map(this.identity)
    //   .every(id => this.selection.has(id));
  }

  toggle(): void {
    this.allSelected
      ? this.selection.clear()
      : this.selection.add(this.source.data);

    // if (this.selected) {
    //   this.selection.clear();
    // } else {
    //   this.source.data
    //     .map(this.identity)
    //     .forEach(this.selection.add);
    // }
  }

  // item(el: T): IDataSourceMeta {
  //
  // }
}

class ItemDataSourceMeta<T> implements IDataSourceMeta {

  constructor(private readonly item: T,
              private readonly selection: EqSet<T>
  ) {
  }

  indeterminate: boolean = false;

  get selected(): boolean {
    return this.selection.has(this.item);
  }

  toggle(): void {
    this.selected
      ? this.selection.delete(this.item)
      : this.selection.add(this.item);
  }

  changed: Observable<any> = undefined;
}
